import React from 'react'
import axios from 'axios'
import { List } from 'immutable'
import { t } from 'i18n'
import { userNameWithRole } from './helpers/ConversationHelper'
import Tooltip from '../utilityComponents/Tooltip'

import Searcher from './Searcher'

class ConversationsList extends React.Component {
  state = {
    loading: false,
    conversations: new List,
    searchPhrase: '',
    includeArchived: false
  }

  async componentDidMount() {
    this.fetchConversations()
    this.subscribe()
  }

  componentWillReceiveProps(props) {
    this.markActiveConversationAsRead(props)
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  subscribe() {
    this.subscription = this.props.cableConsumer.subscriptions.create('UserMessagesChannel', {
      received: this.pushMessage.bind(this)
    })
  }

  fetchConversations = () => {
    const { includeArchived } = this.state

    this.setState({ loading: true })

    axios.get(`/messenger/conversations?include_archived=${includeArchived}`)
    .then(({ data }) => {
      this.setState(
        { loading: false, conversations: sortedConversations(new List(data)) },
        this.markActiveConversationAsRead.bind(this, this.props)
      )
    })
  }

  markActiveConversationAsRead({ activeConversationId }) {
    this.setConversationAttribute(activeConversationId, 'unread', false)
  }

  setConversationAttribute = (id, attribute, value) => {
    const conversationIndex = this.state.conversations.findIndex(c => c.id === id)

    if (conversationIndex < 0) return

    this.setState(state => {
      state.conversations = sortedConversations(
        state.conversations.updateIn([conversationIndex], conversation => {
          conversation[attribute] = value
          return conversation
        })
      )

      return state
    })
  }

  pushMessage(message) {
    // Skip if message received in a different user context
    if (!this.state.conversations.map(c => c.id).includes(message.conversation_id)) return

    this.setState(state => {
      const index    = state.conversations.indexOf(state.conversations.find(c => c.id === message.conversation_id))
      const unread   = message.conversation_id !== this.props.activeConversationId && message.sender_id !== this.props.userId
      const archived = false

      if (index === -1) {
        message.conversation.archived = archived
        message.conversation.unread = unread
        state.conversations = state.conversations.push(message.conversation)
      } else {
        state.conversations = state.conversations.updateIn([index], conversation => {
          return Object.assign(conversation, { updated_at: message.created_at, text: message.body, unread, archived })
        })
      }

      state.conversations = sortedConversations(state.conversations)

      return state
    })
  }

  openConversation = (conversationId, recipients, notary_id) => {
    this.setState({ searchPhrase: '' })
    this.props.openConversation(conversationId, recipients, notary_id)
  }

  searchPhraseChanged = ({ target }) => {
    this.setState({ searchPhrase: target.value })
  }

  toggleIncludeArchived = () => {
    this.setState({ includeArchived: !this.state.includeArchived }, this.fetchConversations)
  }

  updateArchivedStatus = (e, conversation) => {
    e.stopPropagation()
    const conversationId = conversation.id

    axios.put(`/messenger/conversations/${conversationId}/toggle_archived`)
    .then(({ data }) => {
      this.setConversationAttribute(conversationId, 'archived', data.archived)
    }).catch(() => {})
  }

  renderConversations() {
    return this.state.conversations.map(conversation => {
      if (!this.state.includeArchived && conversation.archived) return

      let classNames = 'conversation-card card card-body'
      if (conversation.unread) classNames += ' unread-message '
      if (conversation.archived) classNames += ' archived '
      if (this.props.activeConversationId == conversation.id) classNames += ' active-conversation'

      const archiveAction = conversation.archived ? 'unarchive' : 'archive'
      const archiveIcon = conversation.archived ? 'eye-slash' : 'eye'

      return(
        <div className={classNames} key={conversation.id} onClick={this.openConversation.bind(this, conversation.id, null)}>
          <div className="conversation-card-image-wrapper">
            <img className='img-fluid conversation-card-image' src={conversation.image_url} />
          </div>
          <div className="conversation-card-text-wrapper">
            <p className="conversation-card-name">{conversation.other_parties.map(user => userNameWithRole(user)).join(', ')}</p>
            <p className="conversation-card-text">{conversation.text}</p>
          </div>
          <div className="conversation-card-archive-wrapper">
            <Tooltip tooltipText={t(`messenger.${archiveAction}`)} position='left'>
              <a className="archive-button text-white" id={`archive_conversation_${conversation.id}`} href='#' onClick={(e) => this.updateArchivedStatus(e, conversation)}>
                <i className={`fa fa-${archiveIcon}`} />
              </a>
            </Tooltip>
          </div>
        </div>
      )
    })
  }

  render() {
    const { searchPhrase, loading } = this.state

    const searching = `${searchPhrase}`.length > 0

    return (
      <div className='messages-list-container py-3 py-md-4'>
        <h1 className='messages-list-title mb-3 mb-md-4'>
          {t('messenger.index')}
          <a className='toggle-archived-button pull-right' href='#' onClick={this.toggleIncludeArchived}>
            {t(`messenger.${this.state.includeArchived ? 'hide_archived' : 'show_archived'}`)}
          </a>
        </h1>
        <div className="messages-list-input-wrapper mb-3 mb-md-4">
          <input placeholder={t('messenger.conversations.search')} value={searchPhrase} onChange={this.searchPhraseChanged} name='search' />
          <i className="fa fa-search"></i>
        </div>
        {loading && <div className='loading-indicator'></div>}
        {searching && <Searcher searchPhrase={searchPhrase} openConversation={this.openConversation} />}
        {!loading && !searching && this.renderConversations()}
      </div>
    )
  }
}

const sortedConversations = (conversations) => {
  const unarchivedConversations = conversations.filter(c => !c.archived).sortBy(conversation => - new Date(conversation.updated_at))
  const archivedConversations = conversations.filter(c => c.archived).sortBy(conversation => - new Date(conversation.updated_at))

  return unarchivedConversations.concat(archivedConversations)
}

export default ConversationsList
