import React from 'react'
import Modal from 'react-modal'
import { t } from 'i18n'

const EparakstsSetupModal = ({ isOpen, onClose }) => {
  const translate = (key, variables = {}) => {
    return t(`components.project.sidebar_steps.eparaksts_setup_modal.${key}`, variables)
  }

  return (
    <Modal className='confirm-dialog lg' isOpen={isOpen} ariaHideApp={false}>
      <h4 className='mb-4'>{translate('title')}</h4>
      <ol className='pl-3'>
        <li>
          <p>
            {`${translate('install_program')} `}
            <a href='https://www.eparaksts.lv/lv/lejupielades' target='_blank' rel='noopener noreferrer'>eParakstītājs</a>
          </p>
        </li>
        <li>
          <p>{translate('enable_browser_extension')}:</p>
          <p>
            <i>Google Chrome</i>
            {' - '}
            {translate('tip_chrome')}
          </p>
          <p>
            <i>Mozilla Firefox</i>
            {' - '}
            {translate('tip_firefox')}
          </p>
        </li>
      </ol>
      <div className='buttons'>
        <button className='btn btn-primary confirm-button' onClick={onClose}>
          {translate('confirm')}
        </button>
      </div>
    </Modal>
  )
}

export default EparakstsSetupModal
