import React, { PureComponent, Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import classNames from 'classnames'
import hark from 'hark'
import includes from 'lodash/includes'
import Modal from 'react-modal'
import { t } from 'i18n'
import ReactTooltip from 'rc-tooltip'
import * as VeriffStatuses from './verificationStatuses'
import Icon from '../../utilityComponents/Icon'
import cableConsumer from '../../../files/cableConsumer'

class AudioIndicatorContainer extends PureComponent {
  state = {
    intensity: 0,
    speaking: false
  }

  componentDidMount() { this.startMonitor() }
  componentDidUpdate() { this.startMonitor() }
  componentWillUnmount() { this.monitor && this.monitor.stop() }

  startMonitor() {
    const { audio } = this.props

    if (this.monitor || !audio) {
      return
    }

    const { mediaStreamTrack } = audio
    const stream = new MediaStream([mediaStreamTrack])

    this.monitor = hark(stream, { interval: 250 })

    this.monitor.on('volume_change', this.volumeChanged)
  }

  volumeChanged = (db) => this.setState({ intensity: Math.max(0, (db + 60) * 2) })

  render() {
    const { className, onClick, participantId, children, isExpanded, component } = this.props
    const { intensity } = this.state

    let style = {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      boxShadow: `inset 0 0 ${intensity}px rgba(40,167,69,0.8)`,
      transition: 'box-shadow 300ms ease-in-out'
    }

    return (
      <div
        data-participant-id={participantId}
        className={className}
        onClick={onClick}
        ref={ref => isExpanded ? component.expandedVideoContainer = ref : null}
      >
        {children}
        <div className='audio-indicator-overlay' style={style} />
      </div>
    )
  }
}

class Audio extends PureComponent {
  componentDidMount() { this.mount() }
  componentDidUpdate() { this.mount() }
  componentWillUnmount() { this.unmount() }

  mount() { this.props.track.attach(this.el) }
  unmount() { this.props.track.detach(this.el) }

  render() {
    return <audio ref={el => this.el = el} autoPlay />
  }
}

class Video extends PureComponent {
  componentDidMount() { this.mount() }
  componentDidUpdate() { this.mount() }
  componentWillUnmount() { this.unmount() }

  mount() { this.props.track.attach(this.el) }
  unmount() { this.props.track.detach(this.el) }

  render() {
    return <video ref={el => this.el = el} autoPlay />
  }
}

const Media = ({ audio, video }) => {
  return <Fragment>
    {audio && <Audio track={audio} />}
    {video && <Video track={video} />}
  </Fragment>
}

const VideoBoxFooter = ({
  name, status, online, screenShare, isExpanded, inCall, cameraInaccessible, microphoneInaccessible
}) => {
  return (
    <div className='video-box-footer'>
      {screenShare &&
        <div className='video-box-footer-status screen-share'>
          <i className='fa fa-desktop' />
        </div>
      }
      {!screenShare && inCall &&
        <div className='video-box-footer-status in-call'>
          <i className='fa fa-phone'></i>
        </div>
      }
      {!screenShare && !inCall &&
        <div className={`video-box-footer-status online-status ${online ? 'online' : ''}`}>
          <i className='fa fa-circle' />
        </div>
      }
      <div className='video-box-footer-info'>
        <div className='participant-name'>
          <span>{name}</span>
          {isExpanded &&
            <span className='input-device-statuses'>
              <CameraInaccessibleIndicator show={cameraInaccessible} />
              <MicrophoneInaccessibleIndicator show={microphoneInaccessible} />
            </span>
          }
        </div>
        <div className='participant-status'>{status}</div>
      </div>
    </div>
  )
}

const MicrophoneInaccessibleIndicator = ({ show }) => {
  if (!show) return null

  return (
    <ReactTooltip
      placement='top'
      overlay={t('components.video_room.microphone_inaccessible')}
    >
      <i className='input-device-status-icon microphone-off-icon fa fa-microphone-slash' />
    </ReactTooltip>
  )
}

const CameraInaccessibleIndicator = ({ show }) => {
  if (!show) return null

  return (
    <ReactTooltip
      placement='top'
      overlay={t('components.video_room.camera_inaccessible')}
    >
      <div className='input-device-status-icon camera-off-icon-wrapper'>
        <Icon name='videocam-off' fill='#d9534f' className='camera-off-icon'/>
      </div>
    </ReactTooltip>
  )
}

const Participant = ({
  id, closestAppointmentId, expandVideo, tracks, fullName, formattedPersonalCode, isNotary, isExpanded,
  component, appointmentUser, showNotice, isCurrentUserNotary, subscription, openIdentityVerificationsModal,
  currentUserId
}) => {
  const usesTechnicalIdAsPersonalCode = appointmentUser && appointmentUser.usesTechnicalIdAsPersonalCode
  const manuallyChangedCode = (appointmentUser && appointmentUser.formattedPersonalCode) || ''

  const [personalCodeModalOpen, setPersonalCodeModalOpen] = useState(false)
  const [personalCode, setPersonalCode] = useState(manuallyChangedCode)

  const editPersonalCodeButtonClasses = classNames({
    'fa fa-exclamation-circle color-danger': usesTechnicalIdAsPersonalCode && !manuallyChangedCode,
    'fa fa-pencil': !(usesTechnicalIdAsPersonalCode && !manuallyChangedCode),
    'show-always': usesTechnicalIdAsPersonalCode || manuallyChangedCode || isExpanded
  })

  const name = isNotary ? `${fullName} (${t('components.video_room.notary_label')})` : fullName
  const { audio, video } = tracks || {}
  const [participantOnline, setParticipantOnline] = useState(false)
  let onlineStatusTimer

  useEffect(() => {
    setParticipantOnline(!!appointmentUser?.online)
    cableConsumer.subscriptions.create(
      {
        channel: 'UserOnlineStatusChannel',
        user_id: id
      },
      {
        received: handleReceiveOnlineStatus
      }
    )
    return () => {
      cableConsumer.disconnect()
    }
  }, [])

  const handleReceiveOnlineStatus = ({ online }) => {
    clearTimeout(onlineStatusTimer)

    const timeout = online ? 0 : 5000

    onlineStatusTimer = setTimeout(() => setParticipantOnline(online), timeout)
  }

  const updatePersonalCode = () => {
    updateAppointmentUser(
      { personal_code: personalCode },
      t('components.video_room.user_info_saved'),
      () => setPersonalCodeModalOpen(false)
    )
  }

  const updateAppointmentUser = (attrs, successMessage, callback = () => {}) => {
    axios.put(`/notary/appointments/${closestAppointmentId}/appointment_users/${appointmentUser.id}`, {
      appointment_user: attrs
    }).then(() => {
      subscription.send({})
      showNotice(successMessage)
      callback()
    })
  }

  const lastVerification = appointmentUser && appointmentUser.identityVerifications && appointmentUser.identityVerifications[0]

  const verificationInProgress = lastVerification && includes(VeriffStatuses.PENDING_STATUSES, lastVerification.status)
  const verificationFailed = lastVerification && includes(VeriffStatuses.FAILED_STATUSES, lastVerification.status)
  const verificationResubmission = lastVerification && lastVerification.status === VeriffStatuses.RESUBMISSION_REQUESTED
  const verificationApproved = lastVerification && lastVerification.status === VeriffStatuses.APPROVED

  const verificationButtonClasses = classNames(
    'show-always', 'verification-icon', 'fa fa-shield', {
      'color-white': verificationInProgress,
      'color-warning': verificationResubmission,
      'color-danger': verificationFailed,
      'color-success': verificationApproved
    }
  )

  const participantCameraInaccessible = () => {
    return appointmentUser?.cameraWorking === false
  }

  const participantMicrophoneInaccessible = () => {
    return appointmentUser?.microphoneWorking === false
  }

  return (
    <AudioIndicatorContainer
      participantId={id}
      className='participant-video'
      audio={audio}
      isExpanded={isExpanded}
      component={component}
    >
      {!isExpanded &&
        <CameraInaccessibleIndicator show={participantCameraInaccessible()}/>
      }
      {isCurrentUserNotary && id !== currentUserId &&
        <div className='participant-icons'>
          <ReactTooltip
            placement={isExpanded ? 'bottom' : 'top'}
            overlay={t('components.video_room.open_client_verifications')}
          >
            <i
              className={`participant-icons-item ${verificationButtonClasses}`}
              onClick={() => openIdentityVerificationsModal(appointmentUser.userId)}
            />
          </ReactTooltip>
          <ReactTooltip
            placement={isExpanded ? 'bottom' : 'top'}
            overlay={t('components.video_room.edit_client_personal_code')}
          >
            <i
              className={`participant-icons-item ${editPersonalCodeButtonClasses}`}
              data-action='edit-appointment-user'
              onClick={() => setPersonalCodeModalOpen(true)}
            />
          </ReactTooltip>
        </div>
      }
      <Media audio={audio} video={video} />
      <VideoBoxFooter
        name={name}
        status={manuallyChangedCode || formattedPersonalCode}
        online={participantOnline}
        isExpanded={isExpanded}
        cameraInaccessible={participantCameraInaccessible()}
        microphoneInaccessible={participantMicrophoneInaccessible()}
        inCall={!!tracks}
      />
      <Modal isOpen={personalCodeModalOpen} className='modal-small blue-modal'>
        <span className='modal-close' onClick={() => setPersonalCodeModalOpen(false)}>
          <i className="fa fa-close fa-lg"></i>
        </span>
        <h5>{t('components.video_room.personal_code_prompt')}</h5>
        <input
          type='text'
          className='form-control my-4'
          value={personalCode}
          name='personal_code'
          placeholder={t('components.video_room.personal_code_placeholder')}
          onChange={({ target: { value } }) => setPersonalCode(value)}
        />
        <div className='text-center'>
          <a href='#' className='btn blue-modal-btn' onClick={updatePersonalCode}>{t('save')}</a>
        </div>
      </Modal>

      <ReactTooltip
        placement={isExpanded ? 'bottom' : 'top'}
        overlay={t('components.video_room.expand_video')}
      >
        <div className='expand-video-button' onClick={expandVideo}>
          <i className='fa fa-expand' onClick={expandVideo}/>
        </div>
      </ReactTooltip>
      {!isExpanded &&
        <MicrophoneInaccessibleIndicator show={participantMicrophoneInaccessible()} />
      }
    </AudioIndicatorContainer>
  )
}

export default Participant
export { AudioIndicatorContainer, Media, Video, VideoBoxFooter }
