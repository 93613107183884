import React, { Component } from 'react'
import { t } from 'i18n'
import UserRow from './UserRow'
import axios from 'axios'
import Notice, { showNotice } from '../utilityComponents/Notice'

class UsersIndex extends Component {
  constructor(props) {
    super(props)
    let users = this.props.users
    users.map( u => u.selected = false)

    this.state = {
      users: users,
      checkAll: false,
      noticeText: null
    }

    this.handleCheck = this.handleCheck.bind(this)
    this.renderUsers = this.renderUsers.bind(this)
    this.onChildCheck = this.onChildCheck.bind(this)
    this.destroyUsers = this.destroyUsers.bind(this)
    this.showNotice = showNotice.bind(this)
  }

  handleCheck() {
    const { users, checkAll } = this.state
    users.forEach(u => u.selected = !checkAll)
    this.setState({ checkAll: !checkAll, users: users })
  }

  renderUsers() {
    const { users } = this.state
    let rows = users.map((user) =>
      <UserRow ref={user.id} key={user.id} user={user} onCheck={this.onChildCheck} />
    )
    return rows
  }

  destroyUsers() {
    const { users } = this.state
    if (confirm(t('users.index.delete_confirmation'))) {
      let selectedUsers = users.filter(user => user.selected)
      let userIds = selectedUsers.map(user => user.id)
      axios.delete('/admin/users/delete_multiple', {
        params: {
          user_ids: userIds
        }
      })
      .then(response => {
        let otherUsers = users.filter(user => !user.selected)
        if (response.status == 200) {
          this.setState({ users: otherUsers, checkAll: false })
          this.showNotice(response.data.notice)
        }
      })
    }
  }

  onChildCheck(userId, checked) {
    const { users } = this.state
    let index = users.findIndex((u) => u.id == userId)
    let user = users[index]
    user.selected = !user.selected
    users.splice(index, 1, user)
    this.setState({users: users})
  }

  render() {
    const { checkAll } = this.state
    let roles = this.props.roles.map((role) =>
      <option key={role[1]} value={role[1]}>{role[0]}</option>
    )
    return (
      <div>
        <div className='wrapper-container col-sm-12 col-md-12 well'>
          <h5>{t('users.index.title')}</h5>
          <form className='user_search' id='user_search' action='/admin/users' acceptCharset='UTF-8' method='get'>
            <div className='row'>
              <div className='col-2'>
                <select defaultValue={this.props.selectedRole} className='form-control search-input' name='q[role_eq]' id='q_role_eq'>
                  {roles}
                </select>
              </div>
              <div className='col-5'>
                <div className='input-group'>
                  <input placeholder={t('search')} className='form-control search-input'
                    type='search' name='q[full_name_cont]' id='q_full_name_cont'
                    defaultValue={this.props.nameInputValue}
                  />
                </div>
              </div>
              <div className='col-3'>
                <button name='button' type='submit' className='btn btn-primary'>{t('search')}</button>
                <a className='btn btn-secondary ml-2' href='/admin/users'>{t('cancel')}</a>
              </div>
              <div className='col-2 action-icons'>
                <i className='fa fa-trash-o' onClick={this.destroyUsers} data-action='delete' />
              </div>
            </div>
          </form>
          <h6 className='mt-4 mb-3'>{`${t('users.index.people_count', { count: this.props.usersCount })}:`}</h6>
        </div>
        <table className="table admin-table">
          <thead>
            <tr>
              <th><input className="check-all" type="checkbox" name="optradio" checked={checkAll} onChange={this.handleCheck} /></th>
              <th>{t('activerecord.attributes.user.name')}</th>
              <th>{t('activerecord.attributes.user.role')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.renderUsers()}
          </tbody>
        </table>
        <Notice text={this.state.noticeText} />
      </div>
    )
  }
}

export default UsersIndex
