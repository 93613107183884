import React, { Component } from 'react'
import find from 'lodash/find'
import ProjectItemTabs, { scrollTabIntoView } from './ProjectItemTabs'
import ProjectNewAppointmentButton from '../../components/project/NewAppointmentButton'
import ProjectFiles from './sidebar_steps/ProjectFiles'
import SigningBlock from './sidebar_steps/SigningBlock'
import PaymentBlock from './sidebar_steps/PaymentBlock'
import FinalDocumentBlock from './sidebar_steps/FinalDocumentBlock'

class ClientSidebar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedProjectItemIndex: props.project.projectItems.length - 1
    }
  }

  componentDidMount() {
    scrollTabIntoView(this.state.selectedProjectItemIndex)
  }

  componentDidUpdate(prevProps) {
    const currentItemCount = this.props.project.projectItems.length
    const prevItemCount = prevProps.project.projectItems.length

    if (currentItemCount < prevItemCount) {
      // Select last item
      const selectedProjectItemIndex = currentItemCount - 1
      scrollTabIntoView(selectedProjectItemIndex)
      this.setState({ selectedProjectItemIndex })
    }
  }

  onSelectProjectItem = (selectedProjectItemIndex) => {
    const projectItemCount = this.props.project.projectItems.length
    if (selectedProjectItemIndex < 0 || selectedProjectItemIndex > projectItemCount - 1) return

    scrollTabIntoView(selectedProjectItemIndex)
    this.setState({ selectedProjectItemIndex })
  }

  render() {
    const {
      project, projectUsers, currentUserId, placesOfPractice, subscription, cableConsumer
    } = this.props

    const selectedProjectItem = project.projectItems[this.state.selectedProjectItemIndex]

    return (
      <div className='video-room-sidebar-content-wrapper'>
        <ProjectItemTabs
          subscription={subscription}
          project={project}
          onSelect={this.onSelectProjectItem}
          selectedIndex={this.state.selectedProjectItemIndex}
          isNotary={false}
        />

        {selectedProjectItem &&
          <div className='video-room-sidebar-content' key={selectedProjectItem.id}>
            <ProjectFiles
              subscription={subscription}
              project={project}
              projectUsers={projectUsers}
              projectItem={selectedProjectItem}
              currentUserId={currentUserId}
              isNotary={false}
            />

            <SigningBlock
              subscription={subscription}
              cableConsumer={cableConsumer}
              project={project}
              projectItem={selectedProjectItem}
              projectUsers={projectUsers}
              currentUserId={currentUserId}
              isNotary={false}
            />

            <PaymentBlock
              subscription={subscription}
              project={project}
              projectItem={selectedProjectItem}
              currentUserId={currentUserId}
              isNotary={false}
            />

            <FinalDocumentBlock
              subscription={subscription}
              cableConsumer={cableConsumer}
              project={project}
              projectItem={selectedProjectItem}
              finalDocument={find(selectedProjectItem.signedDocuments, sd => sd.finalVersion)}
              isNotary={false}
            />

            <div className="video-room-sidebar-item-holder">
              <ProjectNewAppointmentButton
                className="btn video-room-sidebar-btn d-block w-100 mb-3"
                projectId={project.id}
                notaryId={project.notaryId}
                placesOfPractice={placesOfPractice}
                isNotary={false}
              />
            </div>
          </div>
        }
      </div>
    )
  }
}

export default ClientSidebar
