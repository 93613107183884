import React, { Component } from 'react'
import { t } from 'i18n'
import Select, { Creatable } from 'react-select'

class MultiSelect extends Component {
  constructor(props) {
    super(props)
    this.state = { multiValue: this.props.selected }

    this.handleOnChange = this.handleOnChange.bind(this)
    this.mapValues = this.mapValues.bind(this)
  }

  handleOnChange (multiValue) {
    this.setState({ multiValue })
  }

  mapValues () {
    const { multiValue } = this.state
    if (multiValue) {
      return multiValue.map(tag => tag.value).join(', ')
    }
  }

  render () {
    const { name, options, creatable } = this.props
    const SelectComponent = creatable ? Creatable : Select

    return (
      <div className="form-group">
        <label className="control-label">{this.props.input_label}</label>
        <SelectComponent
          classNamePrefix='react-select'
          className='react-select multi-select'
          style={{fontSize: 14}}
          isMulti={true}
          options={options}
          onChange={this.handleOnChange}
          value={this.state.multiValue}
          placeholder={t('search')}
        />
        <input type="hidden" name={name} value={this.mapValues()} />
      </div>
    )
  }
}

export default MultiSelect
