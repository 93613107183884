import React, { Component } from 'react'
import { t } from 'i18n'
import WarrantPaymentStep from './WarrantPaymentStep'
import PaymentProcessingStep from './PaymentProcessingStep'
import cableConsumer from '../../files/cableConsumer'

class WarrantCheckContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      warrant_check: props.warrant_check
    }

    this.subscription = cableConsumer.subscriptions.create({
      channel: 'WarrantCheckChannel',
      warrant_check_id: props.warrant_check.id
    }, {
      received: this.handleChannelReceive.bind(this)
    })
  }

  handleChannelReceive(data) {
    this.setState({ warrant_check: data })
  }

  renderCheckFailed() {
    return (
      <div className='warrant-results text-center py-5 mt-5'>
        <div>
          <i className='fa fa-times' />
          <h4 className='mt-3'>{t('main.services.service_not_available')}</h4>
          <p>{t('components.warrant_check_container.try_again_later')}</p>
          <a href='/warrant_checks/new' className='btn form-submit-button'>
            <i className='fa fa-angle-left mr-2' />
            {t('components.warrant_check_container.back_to_search')}
          </a>
        </div>
      </div>
    )
  }

  redirectToResults() {
    window.location = `${location}/results`
  }

  render() {
    const { warrant_check, warrant_check: { payment } } = this.state

    switch (warrant_check.status) {
      case 'failed': return this.renderCheckFailed()
      case 'finished': {
        if (warrant_check.show_results) this.redirectToResults()
        else if (payment.status === 'unpaid')  return <WarrantPaymentStep warrant_check={warrant_check} />
        else if (payment.status === 'pending') return <PaymentProcessingStep warrant_check={warrant_check} />
      }
      default: {
        return null
      }
    }
  }
}

export default WarrantCheckContainer
