import React, { useState } from 'react'
import Modal from 'react-modal'
import { AsyncPaginate } from 'react-select-async-paginate'
import axios from 'axios'
import map from 'lodash/map'
import { t } from 'i18n'
import ReactTooltip from 'rc-tooltip'
import InputWrapper from '../utilityComponents/InputWrapper'

const UsersForm = ({ initialAppointmentUsers, afterSave, closeModal, updateUrl }) => {
  const [errors, setErrors] = useState({})
  const [appointmentUsers, setAppointmentUsers] = useState(initialAppointmentUsers)

  const newAppointmentUser = {
    user: null,
    user_id: '',
    user_email: '',
    user_full_name: '',
    user_phone_number: '',
    personal_code: ''
  }

  const handleClientChange = (selectedUser, index) => {
    const updatedUser = selectedUser ? {
      user: selectedUser,
      user_id: selectedUser.value,
      user_email: selectedUser.email,
      user_full_name: selectedUser.full_name,
      user_phone_number: selectedUser.phone_number,
      personal_code: selectedUser.formatted_personal_code,
      label: selectedUser.label,
      id: appointmentUsers[index]?.id
    } : newAppointmentUser

    const users = appointmentUsers.map((user, i) => index === i ? updatedUser : user)

    setAppointmentUsers(users)
  }

  const handleClientInputChange = (e, appointmentUser, index) => {
    const { name, value } = e.target
    const updatedUser = { ...appointmentUser, [name]: value }
    const users = appointmentUsers.map((user, i) => index === i ? updatedUser : user)

    setAppointmentUsers(users)
  }

  const handleAddClient = () => {
    setAppointmentUsers([...appointmentUsers, { ...newAppointmentUser }])
  }

  const handleRemoveClient = (index) => {
    if (!appointmentUsers[index].id) {
      const users = [...appointmentUsers]
      users.splice(index, 1)
      setAppointmentUsers(users)
    } else {
      const users = appointmentUsers.map((user, i) =>
        index === i
          ? { ...user, _destroy: true }
          : user
      )
      setAppointmentUsers(users)
    }
  }

  const handleSave = () => {
    axios.put(`${updateUrl}/update_appointment_users`, {
      appointment: {
        appointment_users_attributes: appointmentUsers
      }
    }).then(({ data }) => {
      afterSave(data)
      closeModal()
    }).catch(({ response }) => setErrors(response.data))
  }

  return (
    <div>
      <div className='clients-section'>
        <h5 className='mb-3'>{t('components.project.new_appointment_modal.clients')}</h5>
        <UserInputs
          appointmentUsers={appointmentUsers}
          handleClientChange={handleClientChange}
          handleRemoveClient={handleRemoveClient}
          handleClientInputChange={handleClientInputChange}
          errors={errors}
        />
        <a href='#' onClick={handleAddClient} className='add-client-btn'>
          <i className='fa fa-plus-circle'/>
          <span>{t('components.project.new_appointment_modal.add_client')}</span>
        </a>
      </div>
      <div className='text-center mt-5'>
        <div>
          <a href='#'
            className='btn blue-modal-btn'
            onClick={handleSave}
          >
            {t('save')}
          </a>
        </div>
        <div className='mt-3'>
          <a href='#' className='blue-modal-cancel' onClick={closeModal}>
            <i className='fa fa-times mr-2' />
            {t('components.appointments_calendar.cancel_changes')}
          </a>
        </div>
      </div>
    </div>
  )
}

const renderClientOption = (option) => {
  return (
    <>
      {option.label
        ? <div>{option.label}</div>
        : <div className='semi-opaque'>
            {t('components.appointments_calendar.client_search_placeholder')}
          </div>
      }
      <div className='dropdown-only text-muted'>
        <small>{`${option.phone_number || '-'}, ${option.email || '-'}`}</small>
      </div>
    </>
  )
}

const UserInputs = ({
  appointmentUsers, handleClientChange, handleRemoveClient, handleClientInputChange, errors
}) => {
  const fetchUsers = async (input, currentOptions) => {
    const { data } = await axios.get('/notary/users.json', {
      params: { q: input, offset: currentOptions.length }
    })

    return {
      options: data.users,
      hasMore: data.has_more
    }
  }

  const userToSelectOption = (user) => {
    if (!user) return {}

    const label = `${user.full_name} (${user.formatted_personal_code})`
    return { label, value: user.id }
  }

  return (
    map(appointmentUsers, (appointmentUser, index) => {
      if (appointmentUser._destroy) return null

      return (
        <div className={`row appointment-user ${index}`} key={index}>
          <div className='col-xl-4'>
            <InputWrapper>
              <AsyncPaginate
                classNamePrefix='react-select'
                className='react-select user-id'
                loadOptions={fetchUsers}
                formatOptionLabel={renderClientOption}
                onChange={selectedUser => handleClientChange(selectedUser, index)}
                value={userToSelectOption(appointmentUser.user)}
                name='user_id'
                debounceTimeout={500}
              />
            </InputWrapper>
          </div>
          <div className='col-xl-3 user_email'>
            <InputWrapper
              className='input-group-sm'
              errorArray={errors[`appointment_users[${index}].user_email`]}
            >
              <input type='text'
                className='form-control'
                onChange={e => handleClientInputChange(e, appointmentUser, index)}
                value={appointmentUser.user_email}
                name='user_email'
                placeholder={t('activerecord.attributes.user.email')}
              />
            </InputWrapper>
          </div>
          <div className='col-xl-2 user_full_name'>
            <InputWrapper
              className='input-group-sm'
              errorArray={errors[`appointment_users[${index}].user_full_name`]}
            >
              <input type='text'
                className='form-control'
                onChange={e => handleClientInputChange(e, appointmentUser, index)}
                value={appointmentUser.user_full_name}
                name='user_full_name'
                placeholder={t('activerecord.attributes.user.full_name')}
              />
            </InputWrapper>
          </div>
          <div className='col-xl-2 user_phone_number'>
            <InputWrapper
              className='input-group-sm'
              errorArray={errors[`appointment_users[${index}].user_phone_number`]}
            >
              <input type='text'
                className='form-control'
                onChange={e => handleClientInputChange(e, appointmentUser, index)}
                value={appointmentUser.user_phone_number}
                name='user_phone_number'
                placeholder={t('activerecord.attributes.user.phone_number')}
              />
            </InputWrapper>
          </div>
          <div className='col-xl-1'>
            <ReactTooltip placement='top' overlay={t('delete')}>
              <a href='#'
                className='remove-client-btn'
                disabled={appointmentUsers.length == 1}
                onClick={() => handleRemoveClient(index)}
              >
                <i className='fa fa-times'/>
              </a>
            </ReactTooltip>
          </div>
        </div>
      )
    })
  )
}

const EditAppointmentClientsModal = ({
  initialAppointmentUsers, afterSave, closeModal, updateUrl
}) => {
  return (
    <Modal isOpen={true} className="modal-wide blue-modal">
      <span className="modal-close" onClick={closeModal}>
        <i className="fa fa-close fa-lg"/>
      </span>
      <div className='inputs-holder'>
        <UsersForm
          initialAppointmentUsers={initialAppointmentUsers}
          afterSave={afterSave}
          updateUrl={updateUrl}
          closeModal={closeModal}
        />
      </div>
    </Modal>
  )
}

export default EditAppointmentClientsModal
