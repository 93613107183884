import React, { Component } from 'react'
import Modal from 'react-modal'
import { t, l, locale } from 'i18n'
import DatePicker from 'react-datepicker'
import InputWrapper from '../utilityComponents/InputWrapper'
import Select from 'react-select'
import moment from 'moment'
import axios from 'axios'
import MediaQueries from '../utilityComponents/MediaQueries'
import { renderAddressBlock,
  handleTimeInputChange,
  handlePlaceOfPracticeChange,
  selectedWorkingTimeOptions,
  changedAppointment,
  timeOptions,
  fetchFutureUnavailabilityIntervals,
  isDateAvailable,
  workingWeekdays } from './AppointmentFunctions'

class ReschedulingButton extends Component {
  constructor(props) {
    super(props)

    const selectedPlaceOfPractice = props.placesOfPractice.find( (pop) => pop.address === props.appointment.address)
    this.state = {
      modalOpen: false,
      selectedPlaceOfPractice: selectedPlaceOfPractice,
      futureUnavailabilityIntervals: [],
      errors: {},
      appointment: {
        notary_id: props.notaryId,
        date: moment(props.appointment.date),
        raw_time: l('time.formats.short', props.appointment.time),
        address: props.address,
        place_of_practice_id: selectedPlaceOfPractice.id,
        subject: props.appointment.subject
      }
    }

    // MIXIN functions
    this.renderAddressBlock = renderAddressBlock.bind(this)
    this.handleTimeInputChange = handleTimeInputChange.bind(this)
    this.handlePlaceOfPracticeChange = handlePlaceOfPracticeChange.bind(this)
    this.selectedWorkingTimeOptions = selectedWorkingTimeOptions.bind(this)
    this.changedAppointment = changedAppointment.bind(this)
    this.timeOptions = timeOptions.bind(this)
    // Pass appointment id so that its current time is not considered as taken:
    this.fetchFutureUnavailabilityIntervals = fetchFutureUnavailabilityIntervals.bind(this, props.appointment.id)
    this.isDateAvailable = isDateAvailable.bind(this)
    this.workingWeekdays = workingWeekdays.bind(this)
  }

  componentDidMount() {
    this.fetchFutureUnavailabilityIntervals()
  }

  handleSubjectChange = (e) => {
    this.setState({
      appointment: this.changedAppointment({ subject: e.target.value })
    })
  }

  handleDateChange = (date) => {
    this.setState({
      appointment: this.changedAppointment({ date: date, raw_time: '' })
    })
  }

  renderDateErrors() {
    if (this.selectedWorkingTimeOptions().length < 1) {
      return [t('components.appointments.profile_appointment_form.notary_not_working')]
    } else {
      return this.state.errors.date
    }
  }

  updateAppointment = () => {
    const { appointment } = this.state
    axios({
      method: 'PUT',
      url: this.props.updateUrl,
      data: {
        appointment: {
          ...appointment,
          // Make sure that only date is passed in params, not Datetime
          date: appointment.date.format(t('moment_date.formats.default')),
          time: appointment.raw_time && moment(appointment.raw_time, t('moment_time.formats.short')).format('H:mm')
        }
      }
    })
    .then(() => {
      this.setState({modalOpen: false})
      location.reload()
    })
    .catch( req => this.setState({ errors: req.response.data }))
  }

  render() {
    const { errors, modalOpen, appointment, selectedPlaceOfPractice } = this.state

    const workingWeekdays = this.workingWeekdays(selectedPlaceOfPractice)

    return (
      <div>
        <a href='#' className={this.props.className} onClick={() => this.setState({modalOpen: true})}>{t('reschedule')}</a>
        <Modal isOpen={modalOpen} className="modal-small blue-modal">
          <span className="modal-close" onClick={() => this.setState({modalOpen: false})}>
            <i className="fa fa-close fa-lg"></i>
          </span>
          <h5 className="modal-title">{t('components.user_appointments.reschedule')}</h5>
          <div>
            {t('activerecord.models.notary') + ': ' + this.props.notaryName}
          </div>
          <div className="form-group">
            {this.renderAddressBlock()}
          </div>
          <div className='inputs-holder '>
            <label className='input-label'>{t('activerecord.attributes.appointment.date')}</label>
            <InputWrapper className='datepicker' errorArray={this.renderDateErrors()}>
              <DatePicker
                className='form-control'
                minDate={moment(selectedPlaceOfPractice.appointments_open_from_date)}
                maxDate={moment(selectedPlaceOfPractice.appointments_open_until_date)}
                filterDate={date => this.isDateAvailable(date, workingWeekdays)}
                selected={appointment.date}
                onChange={this.handleDateChange}
                id='date'
                name='date'
                dropdownMode='select'
                dateFormat={t('date.formats.moment')}
                todayButton={t('components.datepicker.today')}
                ref={el => { if (el && el.input) el.input.readOnly = true }}
                withPortal={this.props.isMobile}
                locale={locale}
              />
            </InputWrapper>
            <InputWrapper className='time-input' errorArray={errors.time}>
              <label className='input-label'>{t('activerecord.attributes.appointment.time')}</label>
              <Select
                classNamePrefix='react-select'
                className='react-select raw-time'
                onChange={this.handleTimeInputChange}
                value={{ value: appointment.raw_time, label: appointment.raw_time} }
                options={this.timeOptions()}/>
            </InputWrapper>
            <InputWrapper errorArray={errors.subject}>
              <label className='control-label' htmlFor='appointment_subject'>{t('activerecord.attributes.appointment.subject')}</label>
              <textarea className='form-control' id='appointment_subject' value={appointment.subject} onChange={this.handleSubjectChange}/>
            </InputWrapper>
            <div className="text-center">
              <a href='#' className='btn blue-modal-btn' onClick={this.updateAppointment}>
                {t('save')}
              </a>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default MediaQueries(ReschedulingButton)
