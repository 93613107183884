import React from 'react'
import { List, Map } from 'immutable'
import moment from 'moment'
import { t } from 'i18n'

const MESSAGES_GROUPING_INTERVAL = 2.5 * 60

const messageTimeLabel = (message, section) => {
  const createdAt      = moment(message.created_at)
  const currentIndex   = section.indexOf(message)
  const nextMessage    = currentIndex > 0 ? section.get(currentIndex - 1) : null
  let shouldRenderTime = true

  if (nextMessage && nextMessage.sender_id === message.sender_id) {
    const clone = createdAt.clone().add(MESSAGES_GROUPING_INTERVAL, 'seconds')
    shouldRenderTime = moment(nextMessage.created_at).isSameOrAfter(clone)
  }

  if (shouldRenderTime) {
    return <span>{createdAt.format('HH:mm')}</span>
  }
}

const immuteConversationMessages = (messages) => {
  return Object.keys(messages).map(date => {
    return new Map({ date: moment(date, 'YYYY-MM-DD'), messages: new List(messages[date]) })
  })
}

const concatDateMessages = (datesMessages, index, messages) => {
  const messagesList = List.isList(messages) ? messages : [messages]

  return datesMessages.updateIn([index], dm => {
    return dm.updateIn(['messages'], msgs => msgs.concat(messagesList).sortBy(m => - m.id))
  })
}

const userNameWithRole = (user) => {
  let name = `${user.name} ${user.surname}`

  if (user.role) {
    const translatedRole = t(`roles.${user.role}`)
    name += ` (${translatedRole})`
  }

  return name
}

export { messageTimeLabel, immuteConversationMessages, concatDateMessages, userNameWithRole }
