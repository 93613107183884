import React, { PureComponent, useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import find from 'lodash/find'
import map from 'lodash/map'
import each from 'lodash/each'
import omit from 'lodash/omit'
import values from 'lodash/values'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import includes from 'lodash/includes'
import Modal from 'react-modal'
import ReactTooltip from 'rc-tooltip'
import { t, locale } from 'i18n'
import {
  testAudioInputDevice, AudioInputTest, testVideoInputDevice, VideoInputTest
} from '@twilio/rtc-diagnostics'
import Participant, {
  AudioIndicatorContainer, Media, Video, VideoBoxFooter
} from './Participant'
import IdentityVerificationsModal from './IdentityVerificationsModal'
import Icon from '../../utilityComponents/Icon'
import Notice, { showNotice } from '../../utilityComponents/Notice'

// Fix errors on legacy browsers without WebRTC support
if (typeof RTCPeerConnection === 'undefined') {
  window.RTCPeerConnection = () => {}
}
if (typeof mozRTCSessionDescription === 'undefined') {
  window.mozRTCSessionDescription = () => {}
}

const { connect, createLocalTracks, LocalVideoTrack, runPreflight } = require('twilio-video')

const TRACK_KIND_VIDEO = 'video'
const TRACK_KIND_SCREEN = 'screen'

const TRACK_PRIORITY_LOW = 'low'
const TRACK_PRIORITY_STANDARD = 'standard'
const TRACK_PRIORITY_HIGH = 'high'

const Header = ({ callStartedAt, active }) => {
  const [durationText, setDurationText] = useState('')

  useEffect(() => {
    if (active && callStartedAt) {
      const tick = () => {
        const durationMs = moment().diff(callStartedAt)
        const durationText = moment(new Date()).startOf('day').milliseconds(durationMs).format('HH:mm:ss')
        setDurationText(durationText)
      }
      tick()
      const id = setInterval(tick, 1000)
      return () => clearInterval(id)
    }
  }, [callStartedAt, active])

  const iconClass = `fa fa-lg ${active ? 'fa-circle' : ''}`

  return <div className='col-12 video-room-conversation-header text-right py-1'>
    <i className={iconClass} />
    {active && <span className='ml-2 opaque'>{t('components.video_room.call_is_recorded')}</span>}
    {active && <span className='ml-2'>{durationText}</span>}
  </div>
}

const VideoDeviceSwitchButton = ({ videoDevices, onClick }) => {
  if (videoDevices.length < 2) {
    return null
  } else {
    return (
      <ReactTooltip placement='top' overlay={t('components.video_room.switch_video_device')}>
        <button onClick={onClick} className='btn action-bar-btn'>
          <Icon name='switch-camera' fill='white' />
        </button>
      </ReactTooltip>
    )
  }
}

const ShareScreenButton = ({ show, shareActive, onClick }) => {
  if (!show) return null
  const buttonTooltip = shareActive ? 'stop_screen_share' : 'start_screen_share'

  return (
    <ReactTooltip placement='top' overlay={t(`components.video_room.${buttonTooltip}`)}>
      <button
        onClick={onClick}
        className={`btn action-bar-btn ${shareActive ? 'screen-share-active' : ''}`}
      >
        <Icon name='screen-share' fill='white' />
      </button>
    </ReactTooltip>
  )
}

const ParticipantScreen = ({ className, name, track, expandVideo }) => {
  return (
    <div className={`participant-video ${className || ''}`}>
      <Video track={track} />
      <VideoBoxFooter screenShare={true} name={name}/>

      {expandVideo &&
        <div className='expand-video-button' onClick={expandVideo}>
          <i className='fa fa-expand' onClick={expandVideo}/>
        </div>
      }
    </div>
  )
}

const VideocallErrorModal = ({ videocallErrorType, onVideocallErrorDismiss }) => {
  if (!videocallErrorType) return null

  return (
    <Modal isOpen={true} className='modal-small blue-modal'>
      <span className='modal-close' onClick={onVideocallErrorDismiss}>
        <i className="fa fa-close fa-lg"></i>
      </span>
      <h5>
        <b>{t(`components.video_room.videocall_errors.${videocallErrorType}.title`)}</b>
      </h5>
      <p className='my-4'>
        {t(`components.video_room.videocall_errors.${videocallErrorType}.text`)}
      </p>
      <div className='text-center'>
        <a href='#' className='btn blue-modal-btn' onClick={onVideocallErrorDismiss}>
          {t('close')}
        </a>
      </div>
    </Modal>
  )
}

class VideoRoom extends PureComponent {
  constructor(props) {
    super(props)

    const isMultiParticipantChat = props.participants.length > 1

    this.screenShareAvailable = !!(navigator && navigator.mediaDevices.getDisplayMedia)
    this.isIos = !!(navigator && navigator.userAgent.match(/(iPad|iPhone)/i))

    this.state = {
      noticeText: null,
      noticeError: null,
      accessToken: null,
      loading: false,
      videocallErrorType: null,
      participants: props.participants,
      callActive: props.callActive,
      roomObject: undefined,
      callStartedAt: null,
      currentUserName: props.currentUserName,
      connecting: false,
      expandedVideoUserId: null,
      expandedVideoIsScreen: false,
      isMultiParticipantChat,
      tracks: {},
      microphoneEnabled: true,
      showVerificationsForUserId: null,
      videoDevices: [],
      videoDeviceIndex: 0,
      videoDimensions: this.isIos
        ? { width: { ideal: 1280 }, height: { ideal: 720 }}
        : { width: { ideal: 1920 }, height: { ideal: 1080 }}
    }

    this.participantStreams = {}

    this.subscription = props.cableConsumer.subscriptions.create(
      {
        channel: 'VideoCallsChannel',
        project_id: props.projectId
      },
      {
        received: this.handleChannelReceive
      }
    )

    this.showNotice = showNotice.bind(this)
    this.queryVideoDevices()
  }

  testAndSubmitInputDeviceStatuses = () => {
    if (this.props.isNotary) return

    const { mediaDevices } = navigator

    mediaDevices.enumerateDevices().then(devices => {
      const audioDevice = find(devices, device =>
        device.kind === 'audioinput' && device.deviceId.length > 0
      )

      const videoDevice = find(devices, device =>
        device.kind === 'videoinput' && device.deviceId.length > 0
      )

      this.testAudioInput(audioDevice)
      this.testVideoInput(videoDevice)
    })
  }

  testAudioInput = (device) => {
    if (!device) {
      this.updateInputDeviceStatus({ microphone_working: false })
      return
    }

    const audioInputDeviceTest = testAudioInputDevice({ deviceId: device.deviceId })

    audioInputDeviceTest.on(AudioInputTest.Events.End, (report) => {
      this.updateInputDeviceStatus({
        microphone_working: report.errors.length === 0
      })
    })

    setTimeout(() => {
      audioInputDeviceTest.stop()
    }, 5000)
  }

  testVideoInput = (device) => {
    if (!device) {
      this.updateInputDeviceStatus({ camera_working: false })
      return
    }

    const videoInputDeviceTest = testVideoInputDevice({ deviceId: device.deviceId })

    videoInputDeviceTest.on(VideoInputTest.Events.End, (report) => {
      this.updateInputDeviceStatus({
        camera_working: report.errors.length === 0
      })
    })

    setTimeout(() => {
      videoInputDeviceTest.stop()
    }, 5000)
  }

  updateInputDeviceStatus = (params) => {
    const { projectId } = this.props

    const url = `/projects/${projectId}/appointment_users/update_input_device_status`

    return axios.put(url, params)
  }

  resetInputDeviceStatuses = () => {
    if (this.props.isNotary) return

    this.updateInputDeviceStatus(
      { microphone_working: null, camera_working: null }
    )
  }

  markClientInputDevicesNotAccessible = () => {
    if (this.props.isNotary) return

    this.updateInputDeviceStatus(
      { microphone_working: false, camera_working: false }
    )
  }

  queryVideoDevices = () => {
    // get a list of all video inputs for input switching functionality
    try {
      navigator.mediaDevices && navigator.mediaDevices.enumerateDevices().then((devices) => {
        const videoDevices = filter(devices, { kind: 'videoinput' })
        this.setState({ videoDevices: map(videoDevices, 'deviceId') })
      }).catch(error => {
        console.error(error)
      })
    } catch (error) {
      console.error(error) // optional. errors just mean we don't show camera switcher
    }
  }

  stopTimer() {
    this.setState({ callStartedAt: null })
  }

  accessRoom = () => {
    this.setState({ connecting: true })

    axios.post(`${this.props.projectId}/video_call/ensure_room`).then(({ data }) => {
      this.setState({ accessToken: data.token, callStartedAt: new Date() })
      this.connectToRoom(data.room)
    }).finally(() => this.setState({ connecting: false }))
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.closeVideoCall)
    window.removeEventListener('beforeunload', this.resetInputDeviceStatuses)
  }

  componentDidMount() {
    this.enableSelfScreen()
    window.addEventListener('beforeunload', this.closeVideoCall)
    window.addEventListener('beforeunload', this.resetInputDeviceStatuses)

    if (this.props.joinVideoCall && this.state.callActive) {
      this.accessRoom()
    }
  }

  componentDidUpdate() {
    // Reinitialize tooltips when expanding client video
    $('[data-toggle="tooltip"]').tooltip()
  }

  closeVideoCall = async () => {
    this.stopTimer()
    this.stopScreenShare()

    if (this.props.isNotary) {
      await this.markVideoCallAsClosed()
      this.broadcastVideoCallStatus(false)
    }
  }

  toggleVideoCallStatus(status) {
    return axios.put(`${this.props.projectId}`, {
      project: { video_call_active: status }
    })
  }

  markVideoCallAsOpen() {
    this.toggleVideoCallStatus(true)
      .then(() => {
        this.broadcastVideoCallStatus(true)
        this.setState({ callActive: true })
      })
  }

  handleCallPossibleEnding() {
    this.stopScreenShare()

    const { callActive, roomObject } = this.state

    if (callActive == false && roomObject) {
      roomObject.disconnect()
      // Clear room Object so disconnect and notice is not called repeatedly
      this.setState({ roomObject: undefined })
    }
  }

  markVideoCallAsClosed() {
    return this.toggleVideoCallStatus(false)
  }

  delegateRoomEvents(room) {
    room.on('participantConnected', this.participantConnected)
    room.on('participantDisconnected', this.participantDisconnected)
    room.on('disconnected', this.onDisconnect)
  }

  detectTrackKind = (track) => {
    // screen / video / audio
    return (track.name === TRACK_KIND_SCREEN) ? track.name : track.kind
  }

  addTrack(id, track) {
    const kind = this.detectTrackKind(track)

    this.setState(({ tracks }) => {
      const participantWas = tracks[id] || {}

      let changes = {
        tracks: {
          ...tracks,
          [id]: {
            ...participantWas,
            [kind]: track
          }
        }
      }

      if (kind === 'video' && id !== 'self') {
        changes.loading = false
      }

      return changes
    }, () => {
      const { roomObject } = this.state

      if (id === 'self' && roomObject) {
        roomObject.localParticipant.publishTrack(track, { name: kind })
      }
    })
  }

  removeTrack(id, track) {
    track.stop && track.stop()

    const kind = this.detectTrackKind(track)

    this.setState(({ tracks, expandedVideoUserId, expandedVideoIsScreen }) => {
      const participantWas = tracks[id] || {}
      const newParticipant = omit(participantWas, [kind])

      const participantScreenWasExpanded = (id === expandedVideoUserId) && expandedVideoIsScreen
      const participantScreenExpandedStatus = participantScreenWasExpanded ? false : expandedVideoIsScreen

      if (values(newParticipant).length === 0) {
        return {
          tracks: omit(tracks, [id]),
          expandedVideoIsScreen: participantScreenExpandedStatus
        }
      } else {
        return {
          tracks: {
            ...tracks,
            [id]: newParticipant
          },
          expandedVideoIsScreen: participantScreenExpandedStatus
        }
      }
    }, () => {
      const { roomObject } = this.state

      if (id === 'self' && roomObject) {
        roomObject.localParticipant.unpublishTrack(track)
      }
    })
  }

  participantConnected = (participant) => {
    const identity      = JSON.parse(participant.identity)
    const participantId = identity.id

    this.setState({ loading: true })

    participant.on('trackSubscribed', track => {
      this.addTrack(participantId, track)
      this.decideAndSetTrackPriority(participantId, this.detectTrackKind(track), track)
    })

    participant.on('trackUnsubscribed', track => {
      const { expandedVideoUserId, expandedVideoIsScreen } = this.state
      if (expandedVideoUserId === participant.id && expandedVideoIsScreen) {
        this.expandParticipantVideo(null)
      }
      this.removeTrack(participantId, track)
    })

    if (!this.state.expandedVideoUserId) {
      const justConnected = find(this.state.participants, { id: participantId }) || {}
      const currentUser = find(this.state.participants, { id: this.props.currentUserId }) || {}

      if (justConnected.isNotary || currentUser.isNotary) {
        this.expandParticipantVideo(participantId)
      }
    }
  }

  participantDisconnected = (participant) => {
    const identity = JSON.parse(participant.identity)
    const participantId = identity.id
    participant.tracks.forEach(track => this.removeTrack(participantId, track))
  }

  onDisconnect = (room) => {
    // After disconnect twilio leaves video tags intact. need to remove them manually
    room.participants.forEach(p => this.participantDisconnected(p))
    this.closeVideoCall()

    let tracksInfo = {}
    room.localParticipant.tracks.forEach((track, trackId) => tracksInfo[track.kind] = trackId)

    this.subscription.send(tracksInfo)
  }

  broadcastVideoCallStatus(status) {
    this.subscription.perform('toggle_call_status_active', { call_status_active: status })
  }

  handleChannelReceive = (data) => {
    if(data.call_status_active !== undefined) {
      this.setState({ callActive: data.call_status_active }, this.handleCallPossibleEnding)
    }
  }

  connectToRoom = async (roomName) => {
    const { accessToken, tracks: { self } } = this.state

    if (!self) await this.enableSelfScreen()

    try {
      const { tracks: { self } } = this.state

      const room = await connect(accessToken, {
        name: roomName,
        tracks: values(self),
        bandwidthProfile: {
          video: {
            mode: 'collaboration',
            dominantSpeakerPriority: 'high',
            clientTrackSwitchOffControl: 'manual',
            contentPreferencesMode: 'manual'
          }
        }
      })

      this.setState({
        roomObject: room,
        videocallErrorType: null,
        connecting: false
      })

      this.delegateRoomEvents(room)

      room.participants.forEach(this.participantConnected)

      this.resetInputDeviceStatuses()
      this.testAndSubmitInputDeviceStatuses()
    } catch (error) {

      let videocallErrorType
      if (includes(['PermissionDeniedError', 'NotReadableError', 'NotFoundError'], error.name)) {
        videocallErrorType = 'invalid_browser_permissions'
      } else {
        videocallErrorType = 'connection_error'
      }

      this.setState({ videocallErrorType, connecting: false })
      this.markClientInputDevicesNotAccessible()
    }
  }

  refreshAllParticipantVideoPriorities = () => {
    const { tracks } = this.state

    forEach(tracks, (participantTracks, participantId) => {
      if (participantId === 'self') return

      forEach(participantTracks, (track, kind) => {
        this.decideAndSetTrackPriority(participantId, kind, track)
      })
    })
  }

  decideAndSetTrackPriority = (participantId, kind, track) => {
    if (participantId === 'self') return
    if (kind !== TRACK_KIND_SCREEN && kind !== TRACK_KIND_VIDEO) return

    const { expandedVideoUserId, expandedVideoIsScreen } = this.state

    const isExpandedParticipant = expandedVideoUserId === parseInt(participantId)
    const isExpandedScreen = (kind === TRACK_KIND_SCREEN && expandedVideoIsScreen)
    const isExpandedVideo = (kind === TRACK_KIND_VIDEO && !expandedVideoIsScreen)

    const isExpandedTrack = isExpandedParticipant && (isExpandedVideo || isExpandedScreen)

    const priority = isExpandedTrack ? TRACK_PRIORITY_HIGH : TRACK_PRIORITY_STANDARD

    track.setPriority && track.setPriority(priority)
  }

  toggleMicrophone = () => {
    const audio = get(this.state, ['tracks', 'self', 'audio'])
    if (!audio) return

    audio.isEnabled ? audio.disable() : audio.enable()
    this.setState({ microphoneEnabled: audio.isEnabled })
  }

  toggleScreenShare = (enable) => {
    enable ? this.startScreenShare() : this.stopScreenShare()
  }

  startScreenShare = () => {
    try {
      navigator.mediaDevices.getDisplayMedia().then(async stream => {
        const mediaStreamTrack = stream.getTracks()[0]

        // Stops stream when user clicks browser's "Stop sharing" button
        mediaStreamTrack.onended = this.stopScreenShare

        const track = new LocalVideoTrack(mediaStreamTrack, { name: TRACK_KIND_SCREEN })
        this.addTrack('self', track)
      }).catch(() => this.setState({ videocallErrorType: 'share_screen_missing_permissions' }) )
    } catch (e) {
      this.setState({ videocallErrorType: 'share_screen_unsupported' })
    }
  }

  stopScreenShare = () => {
    const screenTrack = this.selfScreenTrack()

    if (screenTrack) {
      this.removeTrack('self', screenTrack)
    }
  }

  toggleNextVideoDevice = () => {
    this.setState(({ videoDeviceIndex, videoDevices }) => {
      const total = videoDevices.length

      const next = (videoDeviceIndex + 1 ) % total

      return { videoDeviceIndex: next }
    }, this.enableSelfScreen)
  }

  performTwilioConnectionTest = () => {
    axios.post(`${this.props.projectId}/video_call/generate_test_access_token`).then(({ data: { token } }) => {
      const preflightTest = runPreflight(token, { duration: 1000 })

      preflightTest.on('failed', () => {
        this.setState({ videocallErrorType: 'connection_error' })
      })
    })
  }

  onVideocallErrorDismiss = () => {
    this.setState({ videocallErrorType: null })
  }

  enableSelfScreen = () => {
    const { videoDimensions } = this.state

    this.resetInputDeviceStatuses()

    return new Promise(async resolve => {
      try {
        const { tracks: { self }, videoDevices, videoDeviceIndex } = this.state

        each(self, track => this.removeTrack('self', track))

        let videoSettings = {
          resizeMode: 'none',
          aspectRatio: { ideal: 1.7777777778 },
          ...videoDimensions
        }
        const selectedVideoDevice = videoDevices[videoDeviceIndex]
        if (selectedVideoDevice) {
          videoSettings.deviceId = selectedVideoDevice
        } else {
          videoSettings.facingMode = { ideal: 'user' }
        }

        const tracks = await createLocalTracks({ video: videoSettings, audio: true })
        each(tracks, track => this.addTrack('self', track))

        if (this.props.performTwilioTest) {
          this.performTwilioConnectionTest()
        }

        resolve()
      } catch (e) {
        this.setState({ videocallErrorType: 'invalid_browser_permissions' })
        this.markClientInputDevicesNotAccessible()
      }
    })
  }

  renderToggleMicrophoneButton() {
    const { microphoneEnabled } = this.state
    const buttonTooltip = microphoneEnabled ? 'mute_microphone' : 'unmute_microphone'

    return (
      <ReactTooltip placement='top' overlay={t(`components.video_room.${buttonTooltip}`)}>
        <button onClick={this.toggleMicrophone} className='btn action-bar-btn'>
          <Icon name={microphoneEnabled ? 'mic' : 'mic-off'} fill='white' />
        </button>
      </ReactTooltip>
    )
  }

  startVideoCall = () => {
    this.markVideoCallAsOpen()
    this.accessRoom()
  }

  disconnectFromVideoCall = () => {
    this.stopTimer()
    this.state.roomObject.disconnect()
    this.stopScreenShare()
    // Clear room Object so disconnect and notice is not called repeatedly
    this.setState({
      roomObject: undefined,
    })
  }

  expandParticipantVideo = (expandedVideoUserId, expandedVideoIsScreen = false) => {
    this.setState({ expandedVideoUserId, expandedVideoIsScreen }, this.refreshAllParticipantVideoPriorities)
  }

  requestIdentityVerification = (appointmentUser) => {
    axios.post(`/projects/${this.props.projectId}/request_identity_verification`, { appointment_user_id: appointmentUser.id })
    .then(() => {
      this.props.subscription.send({})
      this.showNotice(t('components.video_room.verification_requested'))
    })
  }

  openIdentityVerificationsModal = (userId) => {
    this.setState({ showVerificationsForUserId: userId })
  }

  renderVideoCallButton() {
    const { roomObject, callActive, connecting } = this.state
    let onClickEvent, buttonTooltip, iconName

    if (this.props.isNotary) {
      if (roomObject) {
        onClickEvent = this.disconnectFromVideoCall
        buttonTooltip = t('components.video_room.end_video_call')
        iconName = 'call-end'
      } else {
        onClickEvent = this.startVideoCall
        buttonTooltip = t('components.video_room.start_video_call')
        iconName = 'call'
      }
    } else {
      if (roomObject) {
        onClickEvent = this.disconnectFromVideoCall
        buttonTooltip = t('components.video_room.leave_video_call')
        iconName = 'call-end'
      } else if (callActive) {
        onClickEvent = this.accessRoom
        buttonTooltip = t('components.video_room.join_video_call')
        iconName = 'call'
      } else {
        return
      }
    }

    return (
      <ReactTooltip placement='top' overlay={buttonTooltip}>
        <button
          className={`btn action-bar-btn action-bar-main-btn ${iconName}`}
          onClick={onClickEvent}
          disabled={connecting}
        >
          <Icon name={iconName} fill='white' />
        </button>
      </ReactTooltip>
    )
  }

  renderExpandedVideo() {
    const {
      participants, closestAppointmentId, projectId, appointmentUsers, isNotary,
      subscription, currentUserId
    } = this.props

    const { expandedVideoUserId, expandedVideoIsScreen, tracks } = this.state

    if (!expandedVideoUserId) {
      return null
    }

    const participantId = (expandedVideoUserId === 'self') ? currentUserId : expandedVideoUserId
    const participant = find(participants, { id: participantId }) || {}

    if (expandedVideoIsScreen) {
      return <ParticipantScreen
        name={`${participant.fullName} (${t('components.video_room.screen').toLowerCase()})`}
        track={tracks[expandedVideoUserId][TRACK_KIND_SCREEN]}
        key={`${expandedVideoUserId}-expanded-screen`}
      />
    } else {
      const appointmentUser = find(appointmentUsers, user =>
        user.userId === participant.id
      )

      return <Participant
        {...participant}
        closestAppointmentId={closestAppointmentId}
        projectId={projectId}
        appointmentUser={appointmentUser}
        tracks={tracks[expandedVideoUserId]}
        key={`${expandedVideoUserId}-expanded`}
        isExpanded={true}
        component={this}
        showNotice={this.showNotice}
        currentUserId={currentUserId}
        isCurrentUserNotary={isNotary}
        subscription={subscription}
        openIdentityVerificationsModal={this.openIdentityVerificationsModal}
      />
    }
  }

  getSidebarParticipantsWithSharedScreens = () => {
    const { participants } = this.props
    const { tracks, expandedVideoUserId, expandedVideoIsScreen } = this.state

    return filter(participants, participant => {
      // Dont duplicate expanded screen share in sidebar
      if (participant.id === expandedVideoUserId && expandedVideoIsScreen) return false

      const participantTracks = tracks[participant.id]
      return participantTracks && participantTracks[TRACK_KIND_SCREEN]
    })
  }

  getSidebarParticipants() {
    const { participants, currentUserId } = this.props
    const { expandedVideoUserId, expandedVideoIsScreen, tracks } = this.state

    return participants.reduce((filteredParticipants, participant) => {
      const { id, isNotary } = participant
      const participantTracks = tracks[id]

      const isCurrentUser = currentUserId === id
      const isExpanded = expandedVideoUserId === id && !expandedVideoIsScreen
      const isOfflineNotary = isNotary && !participantTracks

      if (!isCurrentUser && !isExpanded && !isOfflineNotary) {
        filteredParticipants.push(participant)
      }

      return filteredParticipants
    }, [])
  }

  identityVerificationRequired = () => {
      const currentAppointmentUser = find(this.props.appointmentUsers, appointmentUser =>
        appointmentUser.userId === this.props.currentUserId
      )

      return currentAppointmentUser && currentAppointmentUser.identityVerificationRequired
  }

  beginIdentityVerification = () => {
    // Create new window right after click event (before ajax), otherwise might get blocked by browser
    const veriffWindow = window.open('', '', `width=${screen.width},height=${screen.height}`)

    axios.post(`/projects/${this.props.projectId}/verify_identity`).then(({ data: { session_url } }) => {
      veriffWindow.location = session_url + `?lang=${locale}`
    })
  }

  selfScreenTrack = () => {
    return get(this.state, ['tracks', 'self', TRACK_KIND_SCREEN])
  }

  renderOtherPartyScreens = (participants) => {
    const { tracks } = this.state
    const screenLabel = t('components.video_room.screen').toLowerCase()

    return map(participants, participant => {
      const { screen } = tracks[participant.id]

      return (
        <ParticipantScreen
          name={`${participant.fullName} (${screenLabel})`}
          track={screen}
          expandVideo={() => this.expandParticipantVideo(participant.id, true)}
          key={`screen-${participant.id}`}
        />
      )
    })
  }

  renderOtherPartyVideos(otherParticipants) {
    const {
      closestAppointmentId, projectId, appointmentUsers, isNotary, subscription, currentUserId
    } = this.props

    return map(otherParticipants, participant => {
      const appointmentUser = find(appointmentUsers, user =>
        user.userId === participant.id
      )

      return <Participant
        {...participant}
        closestAppointmentId={closestAppointmentId}
        projectId={projectId}
        appointmentUser={appointmentUser}
        expandVideo={() => this.expandParticipantVideo(participant.id)}
        tracks={this.state.tracks[participant.id]}
        key={`participant-${participant.id}`}
        showNotice={this.showNotice}
        currentUserId={currentUserId}
        isCurrentUserNotary={isNotary}
        subscription={subscription}
        openIdentityVerificationsModal={this.openIdentityVerificationsModal}
      />
    })
  }

  renderSelfScreen() {
    const {
      currentUserName, tracks: { self }, roomObject, expandedVideoUserId, expandedVideoIsScreen
    } = this.state

    const { audio, video, screen } = self || {}
    if (!video && !screen) return

    const selfScreenExpanded = (expandedVideoUserId === 'self' && expandedVideoIsScreen)
    const selfVideoExpanded = (expandedVideoUserId === 'self' && !expandedVideoIsScreen)

    const showSelfScreen = screen && !selfScreenExpanded
    const showSelfVideo = video && !selfVideoExpanded

    if (!showSelfScreen && !showSelfVideo) return null

    return (
      <div className='self-screen-wrapper' ref={ref => this.selfScreenWrapper = ref}>
        <div className='self-screen-toggle' onClick={() => this.selfScreenWrapper.classList.toggle('collapsed')}>
          <i className='fa fa-angle-left' />
        </div>
        {showSelfScreen &&
          <ParticipantScreen
            expandVideo={() => this.expandParticipantVideo('self', true)}
            className='video-box self-video'
            name={t('components.video_room.your_screen')}
            track={screen}
          />
        }
        {showSelfVideo &&
          <AudioIndicatorContainer className='video-box self-video' audio={audio}>
            <div id='video-view' className='video-box-inner' >
              <Media audio={audio} video={video} />
            </div>
            <VideoBoxFooter
              name={`${currentUserName} (${t('components.video_room.formal_you')})`}
              online={true}
              inCall={roomObject}
            />
            <div className='expand-video-button' onClick={() => this.expandParticipantVideo('self')}>
              <i className='fa fa-expand' />
            </div>
          </AudioIndicatorContainer>
        }
      </div>
    )
  }

  render() {
    const {
      roomObject, callStartedAt, loading, callActive, isMultiParticipantChat, videoDevices,
      showVerificationsForUserId, videocallErrorType
    } = this.state

    const sidebarParticipantWithSharedScreens = this.getSidebarParticipantsWithSharedScreens()
    const sidebarParticipants = this.getSidebarParticipants()

    const anySidebarScreenShare = sidebarParticipantWithSharedScreens.length > 0
    const anySidebarParticipant = isMultiParticipantChat && sidebarParticipants && sidebarParticipants.length > 0
    const showParticipantSidebar = anySidebarScreenShare || anySidebarParticipant

    const videoVisible = this.expandedVideoContainer && this.expandedVideoContainer.querySelector('video')

    const screenShareActive = !!this.selfScreenTrack()

    return (
      <div className='video-room-conversation container-fluid py-3'>
        <Header callStartedAt={callStartedAt} active={!!roomObject} />
        <div className='video-with-controls'>
          <div className='video-box-wrapper'>
            <div className={`video-box large ${videoVisible ? 'vertical' : 'placeholder'}`} ref={ref => this.largeVideoBox = ref} >
              {(loading || callActive === undefined) && <span className='loading-indicator'/> }
              {this.renderExpandedVideo()}
              {showParticipantSidebar && <div className='other-party-video-wrapper' ref={node => this.otherPartyVideoWrapper = node}>
                <div className='other-party-video-toggle' onClick={() => this.otherPartyVideoWrapper.classList.toggle('collapsed')}>
                  <i className='fa fa-angle-right'/>
                </div>
                <div id='other-party-video'>
                  {this.renderOtherPartyScreens(sidebarParticipantWithSharedScreens)}
                  {this.renderOtherPartyVideos(sidebarParticipants)}
                </div>
              </div>}
            </div>
            {this.renderSelfScreen()}
            {this.identityVerificationRequired() &&
              <div className='verify-identity-prompt'>
                <p>
                  <b>{t('components.video_room.verify_identity_prompt')}</b>
                </p>
                <p className='mb-4'>
                {t('components.video_room.verify_identity_description')}
                </p>
                <div className='text-center'>
                  <a
                    href='#'
                    className='verify-identity-button'
                    onClick={this.beginIdentityVerification}
                  >
                    {t('components.video_room.verify_my_identity')}
                  </a>
                </div>
              </div>
            }
          </div>
          <div className='video-room-conversation-action-bar'>
            {this.renderVideoCallButton()}
            {this.renderToggleMicrophoneButton()}
            <ShareScreenButton
              show={this.screenShareAvailable && callActive && roomObject}
              shareActive={screenShareActive}
              onClick={() => this.toggleScreenShare(!screenShareActive)}
            />
            <VideoDeviceSwitchButton
              videoDevices={videoDevices}
              onClick={this.toggleNextVideoDevice}
            />
          </div>
        </div>

        <IdentityVerificationsModal
          participant={find(this.props.participants, p => p.id === showVerificationsForUserId)}
          appointmentUser={find(this.props.appointmentUsers, u => u.userId === showVerificationsForUserId)}
          projectId={this.props.projectId}
          requestIdentityVerification={this.requestIdentityVerification}
          subscription={this.props.subscription}
          showNotice={this.showNotice}
          onClose={() => this.setState({ showVerificationsForUserId: null })}
        />

        <VideocallErrorModal
          videocallErrorType={videocallErrorType}
          onVideocallErrorDismiss={this.onVideocallErrorDismiss}
        />
        <Notice text={this.state.noticeText} error={this.state.noticeError} />
      </div>
    )
  }
}

export default VideoRoom
