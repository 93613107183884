import React, { Component } from 'react'
import { t } from 'i18n'
import axios from 'axios'
import filter from 'lodash/filter'
import map from 'lodash/map'
import ProjectFileItem from './ProjectFileItem'
import { objectToFormData } from '../../../files/helpers'
import { renderBlockStatusIcon, renderError, canEdit, refreshTooltips, openCollapse } from './commonFunctions'

class ProjectFiles extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      uploadInProgress: false,
      collapseOpened: props.projectItem.filesBlockActive,
      tooltipHidden: false,
    }
    this.renderBlockStatusIcon = renderBlockStatusIcon.bind(this)
    this.renderError = renderError.bind(this)
    this.refreshTooltips = refreshTooltips.bind(this)
    this.openCollapse = openCollapse.bind(this)
    this.canEdit = canEdit.bind(this)
    this.activeFlag = 'filesBlockActive'
  }

  componentWillReceiveProps(nextProps) {
    this.refreshTooltips(nextProps)
    if (this.props.isNotary) this.openCollapse(nextProps)
  }

  saveFile(event) {
    const { projectItem, subscription } = this.props
    const input = event.target
    const file = input.files[0]
    this.setState({ error: null, uploadingInProgress: true })

    axios({
      url: `/projects/${projectItem.projectId}/project_items/${projectItem.id}/project_files`,
      method: 'POST',
      contentType: false,
      processData: false,
      data: objectToFormData({
        project_file: {
          document: file,
          name: file.name
        }
      })
    }).
    then(() => {
      subscription.send({})
      this.setState({ uploadingInProgress: false })
    }).
    catch(({ response }) => {
      this.setState({
        error: response.data && response.data[0],
        uploadingInProgress: false
      })
    })
  }

  toFileItemComponent(fileItem, hasAcceptOption) {
    const { projectItem, subscription, isNotary, currentUserId } = this.props

    return (
      <ProjectFileItem
        hasAcceptOption={hasAcceptOption}
        projectId={projectItem.projectId}
        projectItemId={projectItem.id}
        projectFile={fileItem}
        canEdit={this.canEdit()}
        subscription={subscription}
        key={fileItem.id}
        isNotary={isNotary}
        currentUserId={currentUserId}
      />
    )
  }

  renderUploadButton() {
    if(this.canEdit()) {
      if(this.state.uploadingInProgress) {
        return(
          <div className="text-center">
            <label className="btn video-room-sidebar-btn mb-2">
              <span className='loading-indicator small' />
            </label>
          </div>
        )
      } else {
        return (
          <div className="text-center">
            <label htmlFor='project-files' className="btn video-room-sidebar-btn mb-2">
              <i className="fa fa-upload mr-2"></i>
              {t('components.project.sidebar_steps.project_files.upload')}
            </label>
            <input id='project-files' className="video-room-sidebar-item-file-input" type='file' onChange={this.saveFile.bind(this)} />
          </div>
        )
      }
    }
  }

  render() {
    const { isNotary, projectItem: { files } } = this.props
    const { collapseOpened, error } = this.state

    const userFileList = map(
      filter(files, pf => this.props.projectUsers.map(u => u.value).includes(pf.authorId)),
      pf => this.toFileItemComponent(pf, false)
    )

    // User can accept notary files
    const notaryFileList = map(
      filter(files, pf => !this.props.projectUsers.map(u => u.value).includes(pf.authorId)),
      pf => this.toFileItemComponent(pf, true)
    )

    return(
      <div className="video-room-sidebar-item-holder" data-contains='files'>
        <div className="video-room-sidebar-item mb-3">
          <a data-toggle="collapse" href="#collapse1" className={`video-room-sidebar-item-toggle ${collapseOpened ? '' : 'collapsed'}`}>
            <span>{t('components.project.sidebar_steps.project_files.title')}</span>
            <i className="fa fa-chevron-right ml-auto"></i>
          </a>
          <div className={`video-room-sidebar-item-content collapse ${collapseOpened ? 'show' : ''}`} id="collapse1">
            {error && this.renderError()}
            <div className="video-room-sidebar-item-block">
              <h5 className="video-room-sidebar-item-block-title">{t('components.project.sidebar_steps.project_files.notary_documents')}</h5>
              {notaryFileList}
              {isNotary && this.renderUploadButton()}
            </div>
            <div className="video-room-sidebar-item-block">
              <h5 className="video-room-sidebar-item-block-title">{t('components.project.sidebar_steps.project_files.third_party_documents')}</h5>
              {userFileList}
              {!isNotary && this.renderUploadButton()}
            </div>
          </div>
        </div>
        {this.renderBlockStatusIcon()}
      </div>
    )
  }
}

export default ProjectFiles
