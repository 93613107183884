import React, { useState } from 'react'
import InputMask from 'react-input-mask'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import { t, locale } from 'i18n'
import SearchResult from './InheritanceCheckResult'
import MediaQueries from '../utilityComponents/MediaQueries'
import InputWrapper from '../utilityComponents/InputWrapper'

const scope = 'components.inheritance_check'

const PersonalCodeSearch = ({ searchForm, errors, setFieldValue }) => {
  return (
    <InputWrapper errorArray={errors.personal_code}>
      <label className='control-label required'>{t('personal_code', { scope })}</label>
      <InputMask
        type='text'
        className='form-control'
        name='personalCode'
        mask='999999-99999'
        onChange={(e) => setFieldValue(e.target.value, 'personalCode')}
        placeholder={t('personal_code', { scope })}
        value={searchForm.personalCode}
      />
    </InputWrapper>
  )
}

const NameSearch = ({ searchForm, errors, setFieldValue, isMobile }) => {
  return (
    <>
      <InputWrapper errorArray={errors.first_name}>
        <label className='control-label required'>{t('first_name', { scope })}</label>
        <input
          type='text'
          className='form-control'
          name='firstName'
          onChange={(e) => setFieldValue(e.target.value, 'firstName')}
          placeholder={t('first_name', { scope })}
          value={searchForm.firstName} />
      </InputWrapper>
      <InputWrapper errorArray={errors.last_name}>
        <label className='control-label required'>{t('last_name', { scope })}</label>
        <input
          type='text'
          className='form-control'
          name='lastName'
          onChange={(e) => setFieldValue(e.target.value, 'lastName')}
          placeholder={t('last_name', { scope })}
          value={searchForm.lastName} />
      </InputWrapper>
      <div className='row'>
        <div className='col-sm-6'>
          <InputWrapper className='datepicker' errorArray={errors.date_of_birth_from}>
            <label className='control-label required'>{t('date_of_birth_from', { scope })}</label>
            <DatePicker
              className='form-control'
              dateFormat={t('date.formats.moment')}
              placeholderText={t('date_of_birth_from', { scope })}
              selected={searchForm.dateOfBirthFrom}
              onChange={(e) => setFieldValue(e, 'dateOfBirthFrom')}
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              todayButton={t('components.datepicker.today')}
              customInput={<InputMask mask='99.99.9999.'/>}
              withPortal={isMobile}
              locale={locale}
              name='birthDateFrom'
            />
          </InputWrapper>
        </div>
        <div className='col-sm-6'>
          <InputWrapper className='datepicker' errorArray={errors.date_of_birth_to}>
            <label className='control-label required'>{t('date_of_birth_to', { scope })}</label>
            <DatePicker
              className='form-control'
              dateFormat={t('date.formats.moment')}
              placeholderText={t('date_of_birth_to', { scope })}
              selected={searchForm.dateOfBirthTo}
              onChange={(e) => setFieldValue(e, 'dateOfBirthTo')}
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              todayButton={t('components.datepicker.today')}
              customInput={<InputMask mask='99.99.9999.'/>}
              withPortal={isMobile}
              locale={locale}
              name='birthDateTo'
            />
          </InputWrapper>
        </div>
      </div>
    </>
  )
}

const InheritanceSearch = ({
  searchType, searchForm, errors, setFieldValue, isMobile, submitForm, loading
}) => {
  return (
    <>
      {searchType === 'personal_code'
        ? <PersonalCodeSearch
            searchForm={searchForm}
            errors={errors}
            setFieldValue={setFieldValue}
          />
        : <NameSearch
            searchForm={searchForm}
            errors={errors}
            setFieldValue={setFieldValue}
            isMobile={isMobile}
          />
      }
      <div className='text-center'>
        <button type='button'
          className='btn form-submit-button'
          onClick={submitForm}
        >
          <ButtonContent loading={loading} buttonText={t('search')}/>
        </button>
      </div>
    </>
  )
}

export const ButtonContent = ({ loading, buttonText }) => {
  if (loading) {
    return (
      <span>
        {t('search_in_progress')}
        <span className='loading-indicator small'></span>
      </span>
    )
  } else {
    return <>{buttonText}</>
  }
}

const initialSearch = {
  personalCode: '',
  firstName: '',
  lastName: '',
  dateOfBirthFrom: null,
  dateOfBirthTo: null
}

const InheritanceCheckForm = ({ isMobile }) => {
  const [searchType, setSearchType] = useState('personal_code')
  const [searchForm, setSearchForm] = useState(initialSearch)

  const [searchResult, setSearchResult] = useState(null)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)

  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value)
    clearSearch()
  }

  const clearSearch = () => {
    setSearchResult(null)
    setSearchForm(initialSearch)
  }

  const setFieldValue = (e, fieldName) => {
    setSearchForm({...searchForm, [fieldName]: e})
  }

  const submitForm = () => {
    setLoading(true)
    axios.post('/inheritance_checks/search', {
      inheritance_check: {
        search_type: searchType,
        personal_code: searchForm.personalCode,
        first_name: searchForm.firstName,
        last_name: searchForm.lastName,
        date_of_birth_from: searchForm.dateOfBirthFrom?.format('DD.MM.yyyy'),
        date_of_birth_to: searchForm.dateOfBirthTo?.format('DD.MM.yyyy'),
      }})
      .then(({ data }) => {
        setLoading(false)
        setSearchResult(data.matters)
        setErrors({})
      })
      .catch(({ response }) => {
        setLoading(false)
        setSearchResult(null)
        setErrors(response.data)
      })
  }

  const ErrorMessage = () => {
    if (!errors.error && typeof errors !== 'string') return null

    return (
      <div className='errors-section'>
        <i className='fa fa-exclamation-circle mr-2' />
        {errors.error || t('components.inheritance_check.service_unavailable')}
      </div>
    )
  }

  const NoResultsMessage = () => {
    if(searchResult === null || searchResult.length > 0) return null
    return (
      <div className='info-container bg-light mt-4'>
        <span className='info-field'>{t('no_results', { scope })}</span>
      </div>
    )
  }

  return (
    <div className='service-form inheritance-check-form col-sm-12 col-md-10 col-lg-8 col-xl-5 m-auto py-5'>
      <ErrorMessage/>
      <NoResultsMessage/>
      {searchResult?.length > 0 &&
        <SearchResult
          matterResults={searchResult}
          searchForm={searchForm}
          searchType={searchType}
        />
      }
      {!searchResult?.length &&
        <>
          <div className='inheritance-search-type mb-3'>
            <label className='mr-4'>
              <input type='radio'
                value='personal_code'
                id='search-personal-code'
                className='mr-2'
                onChange={handleSearchTypeChange}
                checked={searchType === 'personal_code'}
              />
              {t('search_personal_code', { scope })}
            </label>
            <label>
              <input type='radio'
                value='name'
                id='search-name'
                className='mr-2'
                onChange={handleSearchTypeChange}
                checked={searchType === 'name'}
              />
              {t('search_name', { scope })}
            </label>
          </div>
          <InheritanceSearch
            searchType={searchType}
            searchForm={searchForm}
            errors={errors}
            setFieldValue={setFieldValue}
            isMobile={isMobile}
            submitForm={submitForm}
            loading={loading}
          />
        </>
      }
    </div>
  )
}

export default MediaQueries(InheritanceCheckForm)
