import React from 'react'
import axios from 'axios'
import moment from 'moment'
import { t } from 'i18n'

class Container extends React.Component {
  openPaymentWindow() {
    let windowWidth = Math.max(screen.width * 0.7, 820)
    let windowHeight = Math.max(screen.height * 0.7, 720)

    this.paymentWindow = window.open(this.props.payment.vraaUrl, '', `width=${windowWidth},height=${windowHeight}`)
  }

  markPaymentAsPaid(id) {
    axios.put(`/project_items/${this.props.projectItemId}/payments/${id}/mark_as_paid`).then(() => {
      this.props.subscription.send({})
    })
  }

  destroyPayment(id) {
    axios.delete(`/project_items/${this.props.projectItemId}/payments/${id}`).then(() => {
      this.props.subscription.send({})
    })
  }

  paymentData() {
    const { status, amount, date } = this.props.payment

    const isPaid       = status === 'paid'
    const isUnpaid     = status === 'unpaid'
    const parsedAmount = Number.parseFloat(amount).toFixed(2)
    const statusText   = t(`activerecord.attributes.payment.statuses.${status}`)
    const formatedDate = moment(date).format('DD.MM.YYYY.')

    return { isPaid, isUnpaid, parsedAmount, statusText, formatedDate }
  }

  renderDocumentLink() {
    const payment = this.props.payment
    if (!payment.documentName) return

    return(
      <div className='mt-3 mb-3 text-truncate'>
        <a href={`/project_items/${this.props.projectItemId}/payments/${payment.id}/download`} className='link' target='_blank'>
          {payment.documentName}
        </a>
      </div>
    )
  }

  renderPaymentStatusInfo(isNotary, isPaid, isUnpaid, parsedAmount, statusText) {
    if (!isNotary && isUnpaid) {
      return(
        <div className='text-center mb-3 mt-4'>
          <em>{t('components.project.sidebar_steps.payment_block.amount_to_pay')}: {parsedAmount} €</em>
        </div>
      )
    } else {
      return(
        <div className='text-center mt-4 mb-3'>
          <i className={`fa mr-2 ${isPaid ? 'fa-check' : 'fa-clock-o'}`} />
          <em>{statusText}, {parsedAmount} €</em>
        </div>
      )
    }
  }

  renderLinkToPayment(vraaUrl, isUnpaid) {
    if (!isUnpaid || !vraaUrl || !this.props.isPaymentBlockActive) return

    return(
      <div className='text-center mt-3'>
        <a className='btn btn-sm video-room-sidebar-btn w-50' onClick={this.openPaymentWindow.bind(this)}>
          {t('components.project.sidebar_steps.payment_block.pay')}
        </a>
      </div>
    )
  }

  renderPaymentButtons(id, isPaid, isUnpaid) {
    if (isPaid || this.props.disabled) return

    return(
      <div className='d-flex mt-3'>
        <button className='btn btn-sm video-room-sidebar-btn d-block w-100 mr-2' onClick={this.markPaymentAsPaid.bind(this, id)}>
          {t('components.project.sidebar_steps.payment_block.mark_payment_as_paid')}
        </button>
        <button className='btn btn-sm video-room-sidebar-btn d-block w-100 ml-2' onClick={this.destroyPayment.bind(this, id)} disabled={!isUnpaid}>
          {t('components.project.sidebar_steps.payment_block.destroy_payment')}
        </button>
      </div>
    )
  }

  renderNotaryPaymentView() {
    const { id, number, clientName } = this.props.payment
    const { isPaid, isUnpaid, parsedAmount, statusText, formatedDate } = this.paymentData()

    return(
      <div className='video-room-sidebar-payment-item'>
        <div className='h6'>{t('components.project.sidebar_steps.payment_block.payment_title', { date: formatedDate, number: number })}</div>
        <div className='h6'>{t('components.project.sidebar_steps.payment_block.recipient')}: {clientName}</div>

        {this.renderDocumentLink()}
        {this.renderPaymentStatusInfo(true, isPaid, isUnpaid, parsedAmount, statusText)}
        {this.renderPaymentButtons(id, isPaid, isUnpaid)}
      </div>
    )
  }

  renderCustomerPaymentView() {
    const { number, vraaUrl, notaryName } = this.props.payment
    const { isPaid, isUnpaid, parsedAmount, statusText, formatedDate } = this.paymentData()

    return(
      <div className='video-room-sidebar-payment-item'>
        {this.renderDocumentLink()}
        <div className='opaque font-weight-bold'>{formatedDate} Nr. {number}, {notaryName}</div>
        {this.renderPaymentStatusInfo(false, isPaid, isUnpaid, parsedAmount, statusText)}
        {this.renderLinkToPayment(vraaUrl, isUnpaid)}
      </div>
    )
  }

  render() {
    if (this.props.isNotary) {
      return this.renderNotaryPaymentView()
    } else {
      return this.renderCustomerPaymentView()
    }
  }
}

export default Container
