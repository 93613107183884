import React from 'react'
import { t } from 'i18n'

const VideoCallNotification = ({ notifiable_id, payload, isFocused, minimizeNotification }) => {
  if (window.location.pathname === `/projects/${notifiable_id}`) return null

  return (
    <div className='video-call-notification-wrapper'>
      <div className={`notification video-call-notification ${isFocused ? '' : 'minimized'}`}>
        <i className='fa fa-times close-icon' onClick={minimizeNotification}/>
        <div className='notary-info-wrapper'>
          <div className='notary-image-wrapper d-inline-block'>
            <img className='notary-image' src={payload.notary_image_url}></img>
          </div>
          <div className='project-info-wrapper d-inline-block'>
            <p className='video-call-notification-title '>
              {t('components.video_call_notification.incoming_video_call_title')}
            </p>

            <div className='video-call-notification-info'>
              <p>{payload.notary_full_name}</p>
              <p>
                <i className='fa fa-calendar mr-1' />
                {payload.date} {payload.time_from} - {payload.time_until}
              </p>
            </div>
          </div>
        </div>
        <a href={`/projects/${notifiable_id}?join_video_call=true`} target='_blank' className='btn btn-primary join-call-btn'>
            <i className='fa fa-phone mr-2' />
            {t('components.video_call_notification.join_video_call')}
          </a>
      </div>
    </div>
  )
}

export default VideoCallNotification
