import React, { PureComponent } from 'react'
import axios from 'axios'
import { t } from 'i18n'
import ReactTooltip from 'rc-tooltip'
import useNotaryAsapAvailabilityProvider from '../NotaryAsapAvailabilityProvider'

class AsapAppointmentToggle extends PureComponent {
  toggleReadyForAsapAppointment = () => {
    axios.patch('/notary/notary/set_ready_for_asap_appointment', {
      ready_for_asap_appointment: !this.props.isReadyForAsapAppointment
    })
  }

  render() {
    const { isReadyForAsapAppointment, minutesRemaining } = this.props

    return (
      <div className='asap-appointment-toggle'>
        <ReactTooltip overlayClassName='rc-tooltip-no-arrow' placement='top' overlay={t(`components.asap_appointment_toggle.${isReadyForAsapAppointment ? 'disable_tip' : 'enable_tip'}`)}>
          <a href='#'
            className={`btn ${isReadyForAsapAppointment ? 'btn-primary' : 'btn-secondary'}`}
            onClick={this.toggleReadyForAsapAppointment}
          >
            {t(`components.asap_appointment_toggle.${isReadyForAsapAppointment ? 'enabled' : 'disabled'}`)}
          </a>
        </ReactTooltip>
        {isReadyForAsapAppointment &&
          <p className='minutes-remaining'>
            <i className='fa fa-clock-o mr-2' />
            <span>{`${minutesRemaining} min`}</span>
          </p>
        }
      </div>
    )
  }
}

export default useNotaryAsapAvailabilityProvider(AsapAppointmentToggle)
