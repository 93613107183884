import React from 'react'
import { t } from 'i18n'
import useNotaryAsapAvailabilityProvider from './NotaryAsapAvailabilityProvider'
import ReactTooltip from 'rc-tooltip'

const NotaryAvailabilityIndicator = ({ isReadyForAsapAppointment, onlineOperations, hideTooltipArrow = false }) => {
  if (!isReadyForAsapAppointment && !onlineOperations) return null

  const tooltipTranslation = isReadyForAsapAppointment
    ? 'available_for_asap_appointments'
    : 'available_for_video_appointments'

  return (
    <ReactTooltip
      placement='top'
      overlayClassName={hideTooltipArrow ? 'rc-tooltip-no-arrow' : ''}
      overlay={t(`components.notary_availability_indicator.${tooltipTranslation}`)}
    >
      <div className={`video-appointments-icon ${isReadyForAsapAppointment ? 'asap' : ''}`}></div>
    </ReactTooltip>
  )
}

export default useNotaryAsapAvailabilityProvider(NotaryAvailabilityIndicator)
