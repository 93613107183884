import React, { Component } from 'react'
import InputMask from 'react-input-mask'
import moment from 'moment'
import set from 'lodash/set'
import some from 'lodash/some'
import escapeRegExp from 'lodash/escapeRegExp'
import Select from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import Modal from 'react-modal'
import { t, locale } from 'i18n'
import InputWrapper from '../utilityComponents/InputWrapper'
import MediaQueries from '../utilityComponents/MediaQueries'
import { objectToFormData } from '../../files/helpers'
import cableConsumer from '../../files/cableConsumer'

const scope = 'components.approval_inquiry'

const Spinner = () => {
  return <span className='loading-indicator small'></span>
}

const ErrorMessage = ({ message }) => {
  if (!message) return null

  return (
    <div className='errors-section'>
      <i className='fa fa-exclamation-circle mr-2' />
      {message}
    </div>
  )
}

const PropertyResults = ({ properties, handleSelectProperty, backToPropertySearch }) => {
  return (
    <div className='property-selection-form'>
      <h5 className='text-center mb-4'>
        {t('components.approval_inquiry.found_property_count', { count: properties.length })}
      </h5>

      {properties.map(prop =>
        <div
          onClick={() => handleSelectProperty(prop)}
          key={prop.cadastre_number}
          className='property-item mb-4'
        >
          <p>
            <strong>{t('components.approval_inquiry.address')}: </strong>
            {prop.address}
          </p>
          <p>
            <strong>{t('components.approval_inquiry.cadastre_number')}: </strong>
            {prop.cadastre_number}
          </p>
        </div>
      )}

      <div className='text-center'>
        <a href='#'
          className='btn btn-default change-property-link'
          onClick={backToPropertySearch}
        >
          <i className='fa fa-angle-left mr-2' />
          {t('components.approval_inquiry.search_another_property')}
        </a>
      </div>
    </div>
  )
}

const PropertyInputs = ({
  errors, territories, approval_inquiry, loading, handleLandRegisterChange,
  handleInputChange, performPropertySearch
}) => {
  // Modify options filter to allow creating custom options
  const filterOptions = (options, filter, currentValues) =>
    !filter ? options : _(options)
      .filter(o => RegExp(escapeRegExp(filter), 'ig').test(o.label))
      .difference(currentValues)
      .concat(some(currentValues, { label: filter }) ? [] : [{ label: filter, value: filter }])
      .value()

  return (
    <div className='property-inputs-wrapper'>
      <h5 className='mb-4'>{t('components.approval_inquiry.property')}</h5>
      <InputWrapper errorArray={errors.land_register_name}>
        <label className='control-label required'>{t('components.approval_inquiry.land_register_name')}</label>
        <Select
          classNamePrefix='react-select'
          className='react-select territory-select'
          options={territories}
          filterOptions={filterOptions}
          name='land_register_name'
          value={approval_inquiry.selectedLandRegister}
          placeholder={t('components.approval_inquiry.land_register_placeholder')}
          onChange={handleLandRegisterChange}
        />
      </InputWrapper>
      <InputWrapper errorArray={errors.folio_number}>
        <label className='control-label required'>{t('components.approval_inquiry.folio_number')}</label>
        <InputMask
          type='text'
          className='form-control'
          name='folio_number'
          value={approval_inquiry.folio_number}
          placeholder={t('components.approval_inquiry.number_placeholder')}
          onChange={handleInputChange}
          mask={approval_inquiry.folio_number.length > 5 ? '9999 9999 9999 9999' : null}
          maskChar={null}
        />
      </InputWrapper>
      <InputWrapper errorArray={errors.sub_folio_number}>
        <label className='control-label'>{t('components.approval_inquiry.sub_folio_number')}</label>
        <input className='form-control'
          name='sub_folio_number'
          value={approval_inquiry.sub_folio_number}
          placeholder={t('components.approval_inquiry.number_placeholder')}
          onChange={handleInputChange}
          maxLength='10'
        />
      </InputWrapper>

      <div className='text-center'>
        <button className='btn form-submit-button text-white' disabled={!!loading} onClick={performPropertySearch}>
          {t('components.approval_inquiry.search_property')}
          {loading && <Spinner/>}
        </button>
      </div>
    </div>
  )
}

const ConfirmDialog = ({ isOpen, submitApprovalInquiry, toggleConfirmDialog }) => {
  return (
    <Modal className='confirm-dialog' isOpen={isOpen} ariaHideApp={false}>
      <h5
        className='text'
        dangerouslySetInnerHTML={{ __html: t('components.approval_inquiry.no_signed_document_confirm_html') }}
      ></h5>
      <div className='buttons'>
        <button className='btn btn-primary confirm-button' onClick={submitApprovalInquiry}>
          {t('yes')}
        </button>
        <button className='btn btn-light cancel-button' onClick={() => toggleConfirmDialog(false)}>
          {t('no')}
        </button>
      </div>
    </Modal>
  )
}

const FormInputs = ({
  isMobile, approval_inquiry, errors, loading, handleHasManyInputChange, toggleConfirmDialog,
  handleWarrantNotaryChange, handleWarrantDateChange, handleWarrantDateBlur, addWarrant, removeWarrant,
  handleClientRadioChange, addClient, removeClient, backToPropertySearch, handleInputChange, currentStep,
  handleMainDocumentSelection, handleMainDocumentRemoval, addAttachment, removeAttachment, submitApprovalInquiry
}) => {
  const buttonContent = () => {
    const text = currentStep
      ? t(`steps.${currentStep}`, { scope })
      : t('create_inquiry', { scope })

    return (
      <span>
        {text}
        {loading && <Spinner/>}
      </span>
    )
  }

  const submit = () => {
    if (approval_inquiry.signed_document) {
      submitApprovalInquiry()
    } else {
      toggleConfirmDialog(true)
    }
  }

  return (
    <div className='inputs-wrapper'>
      <div className='property-info'>
        <h5 className='mb-4'>{t('components.approval_inquiry.property')}</h5>
        <p>
          <strong>{t('components.approval_inquiry.address')}: </strong>
          {approval_inquiry.address}
        </p>
        <p>
          <strong>{t('components.approval_inquiry.cadastre_number')}: </strong>
          {approval_inquiry.cadastre_number}
        </p>
        <p>
          <a href='#' onClick={backToPropertySearch}>{t('components.approval_inquiry.change_address')}</a>
        </p>
      </div>

      <div className='warrants-wrapper'>
        <h5 className='mb-4 mt-5'>{t('components.approval_inquiry.warrants')}</h5>
        <WarrantInputs
          errors={errors}
          warrants_attributes={approval_inquiry.warrants_attributes}
          handleNotaryChange={handleWarrantNotaryChange}
          handleDateChange={handleWarrantDateChange}
          handleDateBlur={handleWarrantDateBlur}
          handleHasManyInputChange={handleHasManyInputChange}
          removeWarrant={removeWarrant}
          isMobile={isMobile}
        />
      <a href='#' onClick={addWarrant}>
          <i className='fa fa-plus mr-1' />
          <span>{t('add')}</span>
        </a>
      </div>

      <div className='clients-wrapper'>
        <h5 className='mb-4 mt-5'>{t('components.approval_inquiry.clients')}</h5>
        <ClientInputs
          approval_inquiry={approval_inquiry}
          errors={errors}
          handleRadioChange={handleClientRadioChange}
          removeClient={removeClient}
          handleHasManyInputChange={handleHasManyInputChange}
        />
        <a href='#' onClick={addClient}>
          <i className='fa fa-plus mr-1' />
          <span>{t('add')}</span>
        </a>
      </div>
      <div className='signed-document-wrapper'>
        <h5 className='mb-4 mt-5'>{t('components.approval_inquiry.signed_document')}</h5>
        <SignedDocumentInput
          signed_document={approval_inquiry.signed_document}
          errors={errors}
          onChange={handleMainDocumentSelection}
          onRemove={handleMainDocumentRemoval}
        />
      </div>
      <div className='attachments-wrapper'>
        <h5 className='mb-4 mt-5'>{t('components.approval_inquiry.attachments')}</h5>
        <Attachments
          attachments={approval_inquiry.attachments_attributes}
          onRemove={removeAttachment}
        />
        <label htmlFor='attachment_input' className='btn-link pointer'>
          <i className='fa fa-plus mr-1' />
          <span>{t('add')}</span>
        </label>
        <input id='attachment_input' className='d-none' type='file' onChange={addAttachment} />
      </div>
      <div>
        <h5 className='mb-4 mt-5'>{t('components.approval_inquiry.notes')}</h5>
        <textarea
          name='notes'
          value={approval_inquiry.notes}
          onChange={handleInputChange}
          placeholder={t('components.approval_inquiry.additional_information')}
          className='form-control'
        />
      </div>
      <div className='text-center'>
        <button type='button'
          disabled={!!loading}
          className='btn form-submit-button'
          onClick={submit}
        >
          {buttonContent()}
        </button>
      </div>
    </div>
  )
}

const WarrantInputs = ({
  errors, warrants_attributes, handleNotaryChange, handleDateChange, handleDateBlur,
  handleHasManyInputChange, removeWarrant, isMobile
}) => {
  const fetchNotaries = async (input, existingNotaries) => {
    const { data } = await axios.get('/notaries/search.json', {
      params: { q: input, offset: existingNotaries.length }
    })

    return {
      options: data.notaries,
      hasMore: data.has_more
    }
  }

  return (
    warrants_attributes.map((warrant, index) =>
      <div className='warrant' key={index}>
        <div className='row'>
          <div className='col-12 d-flex'>
            <InputWrapper className='w-100' errorArray={errors[`warrants[${index}].notary_id`]}>
              <label className='control-label required'>{t('components.approval_inquiry.sworn_notary')}</label>
              <AsyncPaginate
                classNamePrefix='react-select'
                className='react-select'
                name='notary_id'
                loadOptions={fetchNotaries}
                value={warrant.selectedNotary}
                placeholder={t('components.warrant_check.sworn_notary_placeholder')}
                onChange={(notary) => handleNotaryChange(notary, index)}
              />
            </InputWrapper>
            <a href='#' onClick={(e) => removeWarrant(e, index)}>
              <i className='fa fa-times remove-button pull-right' />
            </a>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <InputWrapper className='datepicker' errorArray={errors[`warrants[${index}].date`]}>
              <label className='control-label required'>{t('components.approval_inquiry.date')}</label>
              <DatePicker
                className='form-control'
                dateFormat={t('date.formats.moment')}
                placeholderText={t('components.approval_inquiry.date_placeholder')}
                selected={warrant.date && moment(warrant.date, 'DD.MM.YYYY.')}
                onChange={(e) => handleDateChange(e, index)}
                onBlur={(e) => handleDateBlur(e, index)}
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                todayButton={t('components.datepicker.today')}
                withPortal={isMobile}
                customInput={<InputMask mask='99.99.9999.' inputRef={el => { if (isMobile && el) el.readOnly = true }} />}
                locale={locale}
                name='date'
                autoComplete='off'
              />
            </InputWrapper>
          </div>
          <div className='col-sm-6'>
            <InputWrapper errorArray={errors[`warrants[${index}].number`]}>
              <label className='control-label required'>{t('components.approval_inquiry.number')}</label>
              <input
                type='text'
                name='number'
                className='form-control'
                value={warrant.number}
                placeholder={t('components.approval_inquiry.number_placeholder')}
                onChange={(e) => handleHasManyInputChange(e, `warrants_attributes[${index}].number`)}
                />
            </InputWrapper>
          </div>
        </div>
      </div>
    )
  )
}

const ClientInputs = ({
  approval_inquiry, errors, handleRadioChange, removeClient, handleHasManyInputChange
}) => {
  const { clients_attributes } = approval_inquiry

  return (
    clients_attributes.map((client, index) => {
      return (
        <div className='client' key={index}>
          <div className='d-inline-block mr-3 font-weight-bold'>{`${index + 1}.`}</div>
          <div className='d-inline-block mb-2'>
            <label className='mr-4'>
              <input type='radio'
                name={`person_type_${index}`}
                value='natural'
                id={`natural_person_${index}`}
                className='mr-2'
                onChange={(e) => handleRadioChange(e, client)}
                checked={client.person_type === 'natural'}
              />
              {t('components.approval_inquiry.natural_person')}
            </label>
            <label>
              <input type='radio'
                name={`person_type_${index}`}
                value='legal'
                id={`legal_person_${index}`}
                className='mr-2'
                onChange={(e) => handleRadioChange(e, client)}
                checked={client.person_type === 'legal'}
              />
              {t('components.approval_inquiry.legal_person')}
            </label>
          </div>
          {clients_attributes.length > 1 &&
            <a href='#' onClick={(e) => removeClient(e, index)}>
              <i className='fa fa-times remove-button pull-right' />
            </a>
          }
          {client.person_type === 'legal'
            ? <LegalPersonInputs
                client={client}
                index={index}
                errors={errors}
                handleHasManyInputChange={handleHasManyInputChange}
              />
            : <NaturalPersonInputs
                client={client}
                index={index}
                errors={errors}
                handleHasManyInputChange={handleHasManyInputChange}
              />
          }
        </div>
      )
    })
  )
}

const NaturalPersonInputs = ({ client, index, errors, handleHasManyInputChange }) => {
  return (
    <div className='person-inputs natural-person-inputs'>
      <InputWrapper errorArray={errors[`clients[${index}].encrypted_personal_code`]}>
        <label className='control-label required'>{t('components.approval_inquiry.personal_code')}</label>
        <InputMask
          type='text'
          className='form-control'
          name='personal_code'
          value={client.personal_code}
          placeholder={t('components.approval_inquiry.personal_code_placeholder')}
          onChange={(e) => handleHasManyInputChange(e, `clients_attributes[${index}].personal_code`)}
          mask='999999-99999'
        />
      </InputWrapper>
      <div className='row'>
        <div className='col-sm-6'>
          <InputWrapper errorArray={errors[`clients[${index}].first_name`]}>
            <label className='control-label required'>{t('components.approval_inquiry.first_name')}</label>
            <input type='text' className='form-control' name='first_name' placeholder={t('components.approval_inquiry.first_name_placeholder')}
              value={client.first_name} onChange={(e) => handleHasManyInputChange(e, `clients_attributes[${index}].first_name`)} />
          </InputWrapper>
        </div>
        <div className='col-sm-6'>
          <InputWrapper errorArray={errors[`clients[${index}].last_name`]}>
            <label className='control-label required'>{t('components.approval_inquiry.last_name')}</label>
            <input type='text' className='form-control' name='last_name' placeholder={t('components.approval_inquiry.last_name_placeholder')}
              value={client.last_name} onChange={(e) => handleHasManyInputChange(e, `clients_attributes[${index}].last_name`)} />
          </InputWrapper>
        </div>
      </div>
    </div>
  )
}

const LegalPersonInputs = ({ client, index, errors, handleHasManyInputChange }) => {
  return (
    <div className='person-inputs legal-person-inputs'>
      <InputWrapper errorArray={errors[`clients[${index}].registration_number`]}>
        <label className='control-label required'>{t('components.approval_inquiry.registration_number')}</label>
        <InputMask
          type='text'
          className='form-control'
          name='registration_number'
          value={client.registration_number}
          placeholder={t('components.approval_inquiry.registration_number_placeholder')}
          onChange={(e) => handleHasManyInputChange(e, `clients_attributes[${index}].registration_number`)}
          mask='99999999999'
        />
      </InputWrapper>
      <InputWrapper errorArray={errors[`clients[${index}].company_name`]}>
        <label className='control-label required'>{t('components.approval_inquiry.name')}</label>
        <input type='text' className='form-control' name='company_name' placeholder={t('components.approval_inquiry.name_placeholder')}
          value={client.company_name} onChange={(e) => handleHasManyInputChange(e, `clients_attributes[${index}].company_name`)} />
      </InputWrapper>
    </div>
  )
}

const SignedDocumentInput = ({ signed_document, errors, onChange, onRemove }) => {
  const text = signed_document ? signed_document.name : t('components.approval_inquiry.signed_document_placeholder')
  const textClass = signed_document ? '' : 'placeholder'

  return (
    <InputWrapper errorArray={errors.signed_document}>
      <input type='file' id='signed_document' className='d-none' files={[signed_document]} onChange={onChange} />
      <label htmlFor='signed_document' className='form-control signed-document-label'>
        <i className='fa fa-upload mr-2' />
        <span className={textClass}>
          {text}
          {signed_document && <i title={t('delete')} className='fa fa-times pull-right remove-document' onClick={onRemove} />}
        </span>
      </label>
    </InputWrapper>
  )
}

const Attachments = ({ attachments, onRemove }) => {
  return attachments.map((attachment, index) => {
    return (
      <p className='attachment' key={index}>
        <span className='mr-2'>{attachment.file.name}</span>
        <a href='#' onClick={(e) => onRemove(e, index)}><i className='fa fa-times remove-button' /></a>
      </p>
    )
  })
}

class ApprovalInquiryForm extends Component {
  constructor(props) {
    super(props)

    this.newWarrant = {
      number: '',
      date: null,
      notary_id: null
    }

    this.newClient = {
      person_type: 'natural',
      foreign_person: false,
      first_name: '',
      last_name: '',
      personal_code: '',
      registration_number: '',
      company_name: ''
    }

    this.state = {
      errors: {},
      loading: false,
      propertySelected: false,
      territories: [],
      showNoDocumentConfirm: false,
      searchedProperties: [],
      approval_inquiry: {
        selectedLandRegister: null,
        land_register_name: '',
        folio_number: '',
        sub_folio_number: '',
        address: '',
        cadastre_number: '',
        zemesgramata_lb_office_id: '',
        zemesgramata_folio_id: '',
        warrants_attributes: [{...this.newWarrant}],
        clients_attributes: [{...this.newClient}],
        signed_document: null,
        attachments_attributes: [],
        notes: ''
      }
    }
  }

  componentDidMount() {
    this.fetchTerritories()
  }

  fetchTerritories = () => {
    axios.get('/territories.json').then(({ data }) => {
      this.setState({ territories: data })
    })
  }

  changedApprovalInquiry = (changeset) => {
    return Object.assign({}, this.state.approval_inquiry, changeset)
  }

  handleLandRegisterChange = (option) => {
    this.setState({
      approval_inquiry: this.changedApprovalInquiry({
        selectedLandRegister: option,
        territory_name: option && option.value,
        land_register_name: option && option.label
      })
    })
  }

  handleWarrantNotaryChange = (notary, warrantIndex) => {
    let approvalInquiry = { ...this.state.approval_inquiry }

    set(approvalInquiry, `warrants_attributes[${warrantIndex}].selectedNotary`, notary)
    set(approvalInquiry, `warrants_attributes[${warrantIndex}].notary_id`, notary && notary.value)

    this.setState({ approval_inquiry: approvalInquiry })
  }

  handleWarrantDateChange = (date, warrantIndex) => {
    if (!date) return

    let approvalInquiry = { ...this.state.approval_inquiry }
    set(approvalInquiry, `warrants_attributes[${warrantIndex}].date`, date.format('DD.MM.YYYY.'))

    this.setState({ approval_inquiry: approvalInquiry })
  }

  handleWarrantDateBlur = (e, warrantIndex) => {
    const date = e.target.value
    const dateFormat = 'DD.MM.YYYY.'
    const parsedDate = moment(date, dateFormat)

    let approvalInquiry = { ...this.state.approval_inquiry }
    set(
      approvalInquiry,
      `warrants_attributes[${warrantIndex}].date`,
      parsedDate.isValid() ? parsedDate.format(dateFormat) : null
    )

    this.setState({ approval_inquiry: approvalInquiry })
  }

  handleInputChange = ({ target }) => {
    const value = target.validity.valid ? target.value : this.state.approval_inquiry[target.name]

    this.setState({
      approval_inquiry: this.changedApprovalInquiry({ [target.name]: value })
    })
  }

  handleHasManyInputChange = (e, path) => {
    let approvalInquiry = { ...this.state.approval_inquiry }
    set(approvalInquiry, path, e.target.value)
    this.setState({ approval_inquiry: approvalInquiry })
  }

  handleClientRadioChange = (e, client) => {
    const { target } = e
    client.person_type = target.value

    this.setState({ approval_inquiry: this.state.approval_inquiry })
  }

  addWarrant = (e) => {
    e.preventDefault()
    const { approval_inquiry } = this.state
    const warrants_attributes = [...approval_inquiry.warrants_attributes, {...this.newWarrant}]

    this.setState({
      approval_inquiry: this.changedApprovalInquiry({ warrants_attributes })
    })
  }

  removeWarrant = (e, index) => {
    e.preventDefault()
    let warrantsArray = [...this.state.approval_inquiry.warrants_attributes]

    warrantsArray.splice(index, 1)
    this.setState({
      approval_inquiry: this.changedApprovalInquiry({ warrants_attributes: warrantsArray })
    })
  }

  addClient = (e) => {
    e.preventDefault()
    const { approval_inquiry } = this.state
    const clients_attributes = [...approval_inquiry.clients_attributes, {...this.newClient}]

    this.setState({
      approval_inquiry: this.changedApprovalInquiry({ clients_attributes })
    })
  }

  removeClient = (e, index) => {
    e.preventDefault()
    let clientsArray = [...this.state.approval_inquiry.clients_attributes]

    clientsArray.splice(index, 1)
    this.setState({
      approval_inquiry: this.changedApprovalInquiry({ clients_attributes: clientsArray })
    })
  }

  addAttachment = (e) => {
    if (!e.target.files[0]) return

    const { approval_inquiry } = this.state
    const attachments_attributes = [
      ...approval_inquiry.attachments_attributes,
      { file: e.target.files[0] }
    ]

    this.setState({
      approval_inquiry: this.changedApprovalInquiry({ attachments_attributes })
    })
  }

  removeAttachment = (e, index) => {
    e.preventDefault()
    const attachmentsArray = [...this.state.approval_inquiry.attachments_attributes]

    attachmentsArray.splice(index, 1)
    this.setState({
      approval_inquiry: this.changedApprovalInquiry({ attachments_attributes: attachmentsArray })
    })
  }

  handleMainDocumentSelection = (e) => {
    if (!e.target.files[0]) return

    this.setState({
      approval_inquiry: this.changedApprovalInquiry({ signed_document: e.target.files[0] })
    })
  }

  handleMainDocumentRemoval = (e) => {
    e.preventDefault()

    this.setState({
      approval_inquiry: this.changedApprovalInquiry({ signed_document: null })
    })
  }

  backToPropertySearch = () => {
    this.setState({
      propertySelected: false,
      searchedProperties: [],
      approval_inquiry: this.changedApprovalInquiry({
        address: '',
        cadastre_number: '',
        zemesgramata_lb_office_id: '',
        zemesgramata_folio_id: ''
      })
    })
  }

  performPropertySearch = () => {
    const { approval_inquiry } = this.state

    this.setState({ loading: true, errors: {} })

    axios.post('/approval_inquiries/search_property', {
      territory_name: approval_inquiry.territory_name,
      land_register_name: approval_inquiry.land_register_name,
      folio_number: approval_inquiry.folio_number,
      sub_folio_number: approval_inquiry.sub_folio_number
    }).then(({ data }) => {
      if (data.length === 1) {
        this.handleSelectProperty(data[0])
      } else {
        this.setState({ searchedProperties: data, loading: false })
      }
    }).catch(({ response }) => {
      this.setState({ loading: false, errors: response.data })
    })
  }

  handleSelectProperty = (prop) => {
    this.setState({
      propertySelected: true,
      searchedProperties: [],
      loading: false,
      currentStep: null,
      errors: {},
      approval_inquiry: this.changedApprovalInquiry({
        address: prop.address,
        cadastre_number: prop.cadastre_number,
        land_register_name: prop.land_register_name,
        zemesgramata_lb_office_id: prop.lb_office_id,
        zemesgramata_folio_id: prop.folio_id
      })
    })
  }

  submitApprovalInquiry = () => {
    this.setState({ showNoDocumentConfirm: false, loading: true, errors: {} })

    axios({
      url: '/approval_inquiries',
      method: 'POST',
      contentType: false,
      processData: false,
      data: objectToFormData({
        approval_inquiry: this.state.approval_inquiry
      })
    }).then(({ data }) => {
      this.subscription = cableConsumer.subscriptions.create({
        channel: 'ApprovalInquiryChannel',
        approval_inquiry_id: data.id
      }, {
        received: this.handleChannelReceive
      })
    }).catch(({ response }) => {
      this.setState({ errors: response.data, loading: false })
    })
  }

  handleChannelReceive = ({ id, data: { state, message, errors } }) => {
    this.setState({
      currentStep: (state === 'processing') ? message : null
    })

    if (state === 'submitted') {
      location = `/approval_inquiries/${id}`
    } else if (state === 'failed') {
      this.subscription.unsubscribe()
      this.setState({ errors, loading: false, currentStep: null })
    }
  }

  toggleNoDocumentConfirm = (isEnabled) => {
    this.setState({ showNoDocumentConfirm: isEnabled })
  }

  render() {
    const {
      errors, approval_inquiry, territories, loading, showNoDocumentConfirm,
      propertySelected, searchedProperties, currentStep
    } = this.state

    return (
      <div className='service-form approval-inquiry-form col-sm-8 col-sm-6 col-lg-5 col-xl-4 m-auto py-5'>
        <ErrorMessage message={errors && errors.base} />

        {!propertySelected && searchedProperties.length === 0 &&
          <PropertyInputs
            approval_inquiry={approval_inquiry}
            territories={territories}
            errors={errors}
            loading={loading}
            handleLandRegisterChange={this.handleLandRegisterChange}
            handleInputChange={this.handleInputChange}
            performPropertySearch={this.performPropertySearch}
          />
        }

        {!propertySelected && searchedProperties.length > 1 &&
          <PropertyResults
            properties={searchedProperties}
            handleSelectProperty={this.handleSelectProperty}
            backToPropertySearch={this.backToPropertySearch}
          />
        }

        {propertySelected &&
          <FormInputs
            toggleConfirmDialog={this.toggleNoDocumentConfirm}
            isMobile={this.props.isMobile}
            approval_inquiry={approval_inquiry}
            errors={errors}
            loading={loading}
            handleInputChange={this.handleInputChange}
            handleHasManyInputChange={this.handleHasManyInputChange}
            handleWarrantNotaryChange={this.handleWarrantNotaryChange}
            handleWarrantDateChange={this.handleWarrantDateChange}
            handleWarrantDateBlur={this.handleWarrantDateBlur}
            addWarrant={this.addWarrant}
            removeWarrant={this.removeWarrant}
            handleClientRadioChange={this.handleClientRadioChange}
            addClient={this.addClient}
            removeClient={this.removeClient}
            handleMainDocumentSelection={this.handleMainDocumentSelection}
            handleMainDocumentRemoval={this.handleMainDocumentRemoval}
            addAttachment={this.addAttachment}
            removeAttachment={this.removeAttachment}
            submitApprovalInquiry={this.submitApprovalInquiry}
            backToPropertySearch={this.backToPropertySearch}
            currentStep={currentStep}
          />
        }

        <ConfirmDialog
          isOpen={showNoDocumentConfirm}
          submitApprovalInquiry={this.submitApprovalInquiry}
          toggleConfirmDialog={this.toggleNoDocumentConfirm}
        />
      </div>
    )
  }
}

export default MediaQueries(ApprovalInquiryForm)
