import React from 'react'
import axios from 'axios'
import Tooltip from '../../utilityComponents/Tooltip'
import { t, locale } from 'i18n'
import cableConsumer from '../../../files/cableConsumer'

const renderBlockStatusIcon = function() {
  const { projectItem, isNotary } = this.props

  const callback = () => {
    const { onToggleActive } = this.props

    if (isNotary && !projectItem.closedAt) {
      onToggleActive(this.activeFlag)
    }
  }

  let classNames = 'fa fa-lg '
  let localeEnding
  const localePrefix = 'components.project.sidebar_steps'

  if (projectItem[this.activeFlag]) {
    classNames += 'fa-circle '
    localeEnding = isNotary ? '.deactivate' : '.active'
  } else {
    localeEnding = isNotary ? '.activate' : '.inactive'
    classNames += 'fa-check '
  }

  if (projectItem.closedAt || projectItem[this.activeFlag] == null) {
    classNames += 'dimmed'
  } else if (isNotary) {
    classNames += 'pointer'
  }

  if (this.state && this.state.tooltipHidden) {
    return (
      <div className='tooltip-wrapper'>
        <i className={classNames} onClick={callback} data-action='toggle'></i>
      </div>
    )
  }

  return (
    <Tooltip tooltipText={t(localePrefix + localeEnding)}>
      <i className={classNames} onClick={callback} data-action='toggle'></i>
    </Tooltip>
  )
}

const renderError = function() {
  return (
    <div className='video-room-sidebar-item-note red d-flex justify-content-between align-items-center'>
      <span>{this.state.error}</span>
      <i className='fa fa-times pointer' onClick={() => this.setState({ error: null })} />
    </div>
  )
}

const refreshTooltips = function(nextProps) {
   this.setState({ tooltipHidden: true })

   setTimeout(() => this.setState({ tooltipHidden: false }), 400)
}

const openCollapse = function(nextProps) {
  const { projectItem } = this.props

  if (this.props && (projectItem[this.activeFlag] !== nextProps.projectItem[this.activeFlag])) {
    this.setState({ collapseOpened: nextProps.projectItem[this.activeFlag] })
  }
}

const canEdit = function() {
  const { projectItem } = this.props

  return projectItem[this.activeFlag] && !projectItem.closedAt
}

const signWithEparakstsEid = async function(latestDocument) {
  const handleEidCardError = (e) => {
    const error = t(`components.project.sidebar_steps.signing_block.eparaksts_statuses.${e.message}`, { default: e.message })
    this.setState({ eparakstsInProgress: false, error })
  }

  this.setState({ eparakstsInProgress: true, error: null })
  window.eParakstsTokenSigning() // Declare signing functions

  const getVersionTimer = new Promise((resolve, reject) => {
    setTimeout(reject, 1000 * 15) // 15 seconds
  })

  // getVersion promise never completes if plugin is missing
  const version = await Promise.race(
    [window.getVersion(), getVersionTimer]
  ).catch(() => {
    // Could not get version => display eParaksts setup instructions
    this.setState({ eparakstsInProgress: false, showEparakstsSetupModal: true })
  })

  if (!version) return

  const signCert = await window.getCertificate(
    { lang: locale, operation: 'sign' }
  ).catch(handleEidCardError)

  if (!signCert) return

  const { data: { digest_hex } } = await axios.post('/eparaksts/initialize_eid_signing', {
    signable_document_id: latestDocument.id,
    sign_cert_hex: signCert.hex
  }).catch(({ response }) =>
    this.setState({ eparakstsInProgress: false, error: response.data })
  )

  if (!digest_hex) return

  const signature = await window.sign(
    signCert,
    { type: 'SHA-256', hex: digest_hex },
    { lang: locale, operation: 'sign' }
  ).catch(handleEidCardError)

  if (!signature) return

  const authCert = await window.getCertificate(
    { lang: locale, operation: 'auth' }
  ).catch(handleEidCardError)

  if (!authCert) return

  axios.post('/eparaksts/finalize_eid_signing', {
    project_item_id: this.props.projectItem.id,
    signature_hex: signature.hex,
    auth_cert_hex: authCert.hex
  }).then(({ data }) => {
    this.setState({ eparakstsInProgress: false })
  }).catch(({ response }) => {
    this.setState({ eparakstsInProgress: false, error: response.data })
  })
}

const signWithEparakstsMobile = function(latestDocument) {
  this.setState({ error: null, eparakstsInProgress: true })

  const { cableConsumer, projectItem } = this.props
  this.signingStatusSubscription = cableConsumer.subscriptions.create(
    {
      channel: 'EparakstsSignerResponseChannel',
      signable_document_id: latestDocument.id
    },
    {
      received: ({ success, message }) => {
        this.setState({ eparakstsInProgress: false })

        if (!success) {
          this.setState({ error: message })
        }
      }
    }
  )

  window.open(
    `/eparaksts/start_mobile_signing?project_item_id=${projectItem.id}&signable_document_id=${latestDocument.id}`,
    '',
    `width=${screen.width},height=${screen.height}`
  )
}

const signWithSmartId = function(latestDocument) {
  this.setState({ error: null })

  this.smartIdSignerResponseSubscription = cableConsumer.subscriptions.create({
    channel: 'SmartIdSignerResponseChannel',
    signable_document_id: latestDocument.id
  }, {
    received: this.handleSmartIdResponse
  })
}

const handleSmartIdResponse = function(response) {
  if (response.code_received) {
    this.setState({ smartIdCode: response.code })
  } else {
    if (response.document_signed) {
      this.setState({ smartIdCode: null, error: null })
    } else {
      this.setState({
        smartIdCode: null,
        error: t(`components.project.sidebar_steps.signing_block.smartid_statuses.${response.error}`, { default: response.error })
      })
    }

    this.smartIdSignerResponseSubscription.unsubscribe()
  }
}

export {
  renderBlockStatusIcon, renderError, canEdit, refreshTooltips, openCollapse,
  signWithEparakstsEid, signWithEparakstsMobile, signWithSmartId, handleSmartIdResponse
}
