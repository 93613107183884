import React, { Component } from 'react'
import Modal from 'react-modal'
import { t, locale } from 'i18n'
import DatePicker from 'react-datepicker'
import TimePicker from 'rc-time-picker'
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import Select from 'react-select'
import moment from 'moment'
import axios from 'axios'
import InputWrapper from '../utilityComponents/InputWrapper'
import { renderAddressBlock,
  handleTimeInputChange,
  selectedWorkingTimeOptions,
  changedAppointment,
  fetchFutureUnavailabilityIntervals,
  isDateAvailable,
  workingWeekdays,
  timeOptions } from '../appointments/AppointmentFunctions'

class NewAppointmentModal extends Component {
  constructor(props) {
    super(props)
    const activePlaceOfPractice = find(props.placesOfPractice, pop => pop.active)
    this.minDate = props.isNotary ? moment(new Date()) : moment(activePlaceOfPractice.appointments_open_from_date)
    this.maxDate = props.isNotary ? null : moment(activePlaceOfPractice.appointments_open_until_date)

    this.state = {
      selectedPlaceOfPractice: activePlaceOfPractice,
      futureUnavailabilityIntervals: [],
      errors: {},
      loading: false,
      selectedUser: null,
      appointment: {
        notary_id: props.notaryId,
        date: this.minDate,
        address: activePlaceOfPractice.address,
        place_of_practice_id: activePlaceOfPractice.id,
        time: null,
        raw_time: '',
        subject: ''
      }
    }

    this.handleInputChange = this.handleInputChange.bind(this)

    // For Async need to pass a variable that has run debounce exactly once
    this.debouncedUserFetch = debounce((input, callback) => { this.fetchUsers(input, callback) }, 500)

    // MIXIN functions
    this.renderAddressBlock = renderAddressBlock.bind(this)
    this.handleTimeInputChange = handleTimeInputChange.bind(this)
    this.selectedWorkingTimeOptions = selectedWorkingTimeOptions.bind(this)
    this.changedAppointment = changedAppointment.bind(this)
    this.fetchFutureUnavailabilityIntervals = fetchFutureUnavailabilityIntervals.bind(this)
    this.isDateAvailable = isDateAvailable.bind(this)
    this.timeOptions = timeOptions.bind(this)
    this.workingWeekdays = workingWeekdays.bind(this)
  }

  componentDidMount() {
    this.fetchFutureUnavailabilityIntervals()
  }

  handleDateChange = (date) => {
    this.setState({
      appointment: this.changedAppointment({ date: date, time: null, raw_time: '' })
    })
  }

  handleInputChange(event) {
    const { value, name } = event.target

    this.setState({
      appointment: this.changedAppointment({ [name]: value })
    })
  }

  handleAppointmentTimeChange = (time) => {
    this.setState({
      appointment: this.changedAppointment({ time })
    })
  }

  saveAppointment() {
    const { appointment, loading } = this.state
    if (loading) return
    this.setState({ loading: true })

    const time = appointment.time || moment(appointment.raw_time, t('moment_time.formats.short'))

    axios.post(`/projects/${this.props.projectId}/create_appointment`, {
      appointment: {
        ...appointment,
        // Make sure that only date is passed in params, not Datetime
        date: appointment.date.format(t('moment_date.formats.default')),
        time: time && time.format('H:mm')
      }
    }).
      then(() => {
        this.props.onSave()
      }).
      catch(({ response }) => {
        this.setState({ loading: false })
        this.setState({ errors: response.data })
      })
  }

  render() {
    const { isNotary, closeModal, isMobile } = this.props
    const { appointment, errors, selectedPlaceOfPractice } = this.state

    const workingWeekdays = this.workingWeekdays(selectedPlaceOfPractice)

    return (
      <div>
        <Modal isOpen={true} className="modal-small blue-modal">
          <span className="modal-close" onClick={closeModal}>
            <i className="fa fa-close fa-lg"></i>
          </span>
          <div className='inputs-holder'>
            {this.renderAddressBlock()}
            <InputWrapper className='datepicker' errorArray={errors.date}>
              <DatePicker
                className='form-control'
                minDate={this.minDate}
                maxDate={this.maxDate}
                filterDate={date => isNotary ? true : this.isDateAvailable(date, workingWeekdays)}
                selected={appointment.date}
                onChange={this.handleDateChange}
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                id='date'
                name='date'
                dateFormat={t('date.formats.moment')}
                todayButton={t('components.datepicker.today')}
                ref={el => { if (el && el.input && isMobile) el.input.readOnly = true }}
                withPortal={isMobile}
                locale={locale}
              />
            </InputWrapper>

            <InputWrapper className='time-input' errorArray={errors.time}>
              <label className='input-label'>{t('activerecord.attributes.appointment.time')}</label>
              {isNotary
                ? <TimePicker
                    value={appointment.time}
                    onChange={this.handleAppointmentTimeChange}
                    className='appointment-time time-picker-light'
                    showSecond={false}
                    allowEmpty={false}
                    format='H.mm'
                  />
                : <Select
                    classNamePrefix='react-select'
                    className='react-select raw-time'
                    onChange={this.handleTimeInputChange.bind(this)}
                    value={{ value: appointment.raw_time, label: appointment.raw_time} }
                    options={this.timeOptions()}
                  />
              }
            </InputWrapper>
            <InputWrapper errorArray={errors.subject}>
              <label className='input-label'>{t('activerecord.attributes.appointment.subject')}</label>
              <textarea name='subject' id='subject' onChange={this.handleInputChange} className='form-control'></textarea>
            </InputWrapper>
          </div>
          <div className='text-center'>
            <a href='#' className='btn blue-modal-btn' onClick={this.saveAppointment.bind(this)}>
              {t('save')}
            </a>
          </div>
        </Modal>
      </div>
    )
  }
}

export default NewAppointmentModal
