import React, { Component } from 'react'
import axios from 'axios'
import map from 'lodash/map'
import ReschedulingButton from '../appointments/ReschedulingButton'
import { t, l } from 'i18n'
import {
  appointmentStatusClass,
  appointmentStatus,
  appointmentCancelationWarning
} from './AppointmentFunctions'

class UserAppointments extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appointments: this.props.appointments
    }
  }

  componentDidMount() {
    const { page } = this.props

    // Poll only latest appointments
    if (!page || page == 1) {
      this.pollAppointments()
    }
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip()
  }

  pollAppointments = () => {
    axios.get('/user/appointments.json', { params: { page: this.props.page } })
      .then(({ data }) => this.setState({ appointments: data }) )
    setTimeout(() => this.pollAppointments(), 10000)
  }

  renderActiveAppointmentLinks(appointment) {
    if (appointment.archived) return

    return (
      <div>
        {appointment.project_id &&
          <a href={`/projects/${appointment.project_id}`} className='dropdown-item'>
            {t('components.user_appointments.open_project')}
          </a>
        }
        <a href={`/appointments/${appointment.id}/download_ical`} className='dropdown-item'>
          {t('components.user_appointments.download_ics')}
        </a>
        <ReschedulingButton
          appointment={appointment}
          placesOfPractice={appointment.places_of_practice}
          updateUrl={`/appointments/${appointment.id}`}
          className='dropdown-item'
          notaryId={appointment.notary.id}
          notaryName={appointment.notary.full_name}
        />
        <a href={`/appointments/${appointment.id}/cancel`} data-method='put' data-confirm={appointmentCancelationWarning(appointment)} className='dropdown-item'>
          {t('cancel')}
        </a>
      </div>
    )
  }

  renderExpandAppointmentSubject(appointment) {
    if (appointment.subject && appointment.subject.length > 120) {
      return (
        <span>
          <span className='collapse' id={`${appointment.id}`}>{appointment.subject.substr(120)}</span>
          <a href={`#${appointment.id}`} className='read-more' data-on={t('components.user_appointments.less')} data-off={t('components.user_appointments.more')} data-toggle='collapse' aria-expanded='false'></a>
        </span>
      )
    }
  }

  renderAppointment(appointment) {
    const statusClass = appointmentStatusClass(appointment)
    const status = appointmentStatus(appointment)

    return (
      <div className={`user-appointments-item py-3 ${appointment.project_id ? 'with-link' : ''}`} data-object-id={appointment.id} key={appointment.id}>
        {appointment.passed && <div className='appointment-overlay'></div>}
        {appointment.project_id && <a href={`/projects/${appointment.project_id}`} className='appointment-link'></a>}
        <div className={`appointment-status d-none d-md-flex ${statusClass}`} data-toggle='tooltip' data-placement='bottom' data-original-title={status}></div>
        <div className='appointment-info'>
          <div className='appointment-subject mb-3 mb-md-1'>
            <div className={`appointment-status d-md-none ${statusClass}`} data-toggle='tooltip' data-placement='bottom' data-original-title={status}></div>
            {appointment.subject ? appointment.subject.substr(0, 120) : '-'}
            <i className={`fa fa-chevron-right ml-auto d-md-none pe-none ${appointment.project_id ? '' : 'fa-blank'}`} />
            {this.renderExpandAppointmentSubject(appointment)}
          </div>
          <div className='appointment-meta'>
            <div className='appointment-address'>{appointment.address}</div>
            <div className='appointment-date mx-md-3 my-1 my-md-0'>{l('time.formats.extended', appointment.datetime)}</div>
            <div className='appointment-notary'>{appointment.notary.full_name}</div>
          </div>
        </div>
        <div className='appointment-actions pl-md-4 ml-md-auto mt-3 mt-md-0 dropdown dropdown-hover'>
          <button className='btn btn-default btn-white' type='button'>{t('components.user_appointments.actions')}</button>
          <div className='dropdown-menu dropdown-menu-right mt-0'>
            {this.renderActiveAppointmentLinks(appointment)}
            <a href={`/messenger?notary_id=${appointment.notary.id}`} className='dropdown-item'>
              {t('components.user_appointments.contact_notary')}
            </a>
          </div>
        </div>
        <i className={`fa fa-chevron-right ml-5 d-none d-md-block pe-none ${appointment.project_id ? '' : 'fa-blank'}`} />
      </div>
    )
  }

  render() {
    const { appointments } = this.state

    if (appointments.length > 0) {
      return (
        <div>
          <div className='user-appointments-header'>
            <h1 className='user-appointments-title'>
              {t('components.user_appointments.make_appointment')}
            </h1>
            <a href='/notaries_map' className='btn btn-default btn-white px-4'>
              <i className='fa fa-plus mr-3' />
              {t('components.user_appointments.make_appointment')}
            </a>
          </div>
          <div className='user-appointments-list mt-4 mb-3'>
            {map(appointments, appointment => this.renderAppointment(appointment))}
          </div>
        </div>
      )
    } else {
      return (
        <div className='text-center py-4 py-md-5 no-appointments'>
          <h2 className='mt-3 mt-md-5'>
            {t('components.user_appointments.no_appointments')}
          </h2>
          <a href='/notaries_map' className='btn btn-default btn-white mt-3 px-4'>
            {t('components.user_appointments.make_appointment')}
          </a>
        </div>
      )
    }
  }
}

export default UserAppointments
