import React, { Fragment } from 'react'
import axios from 'axios'
import Modal from 'react-modal'
import includes from 'lodash/includes'
import map from 'lodash/map'
import { t, l } from 'i18n'
import ReactTooltip from 'rc-tooltip'
import * as VeriffStatuses from './verificationStatuses'

const VerificationReason = ({ verification }) => {
  const showReason = includes([
    VeriffStatuses.RESUBMISSION_REQUESTED,
    VeriffStatuses.DECLINED,
  ], verification.status)

  if (!showReason) return null

  let humanReason
  if (verification.humanReason) {
    humanReason = t(`components.video_room.verification_responses.${verification.status}.${verification.humanReason}`)
  } else if (verification.reason) {
    humanReason = verification.reason
  } else {
    return null
  }

  return <div className='status-wrapper reason-wrapper'>{humanReason}</div>
}

const StepperItem = ({ number, name, className }) => {
  return (
    <div className={`stepper-item ${className}`}>
      <div className='step-counter'>{number}</div>
      <div className='step-name'>{t(`components.video_room.verification_steps.${name}`)}</div>
    </div>
  )
}

const Verification = ({ verification, appointmentUser, subscription, showNotice, projectId }) => {
  const assignPersonalCode = () => {
    const { id, appointmentId } = appointmentUser

    axios.put(`/notary/appointments/${appointmentId}/appointment_users/${id}/assign_veriff_id_number?verification_id=${verification.id}`)
      .then(() => {
        subscription.send({})
        showNotice(t('components.video_room.verification_id_number_assigned_success'))
      })
      .catch(({ response }) => {
        showNotice(response.data.personal_code.join(', '), false)
      })
  }

  const cancelVerificationRequest = () => {
    confirm(t('components.video_room.cancel_verification_request_confirm'))
    axios.put(`/projects/${projectId}/cancel_verification_request`, { verification_request_id: verification.id })
    .then(() => {
      subscription.send({})
      showNotice(t('components.video_room.verification_canceled'))
    })
    .catch(() => {
      showNotice(t('components.video_room.verification_cancelation_failed'), false)
    })
  }

  const isFinished = includes(VeriffStatuses.FINISHED_STATUSES, verification.status)
  const isCanceled = !!verification.canceledAt

  const showResult = includes([
    VeriffStatuses.RESUBMISSION_REQUESTED,
    VeriffStatuses.DECLINED,
    VeriffStatuses.APPROVED,
  ], verification.status)

  const activeStepByStatus = {
    [VeriffStatuses.NOT_STARTED]: 1,
    [VeriffStatuses.STARTED]: 2,
    [VeriffStatuses.SUBMITTED]: 3,
    [VeriffStatuses.RESUBMISSION_REQUESTED]: 4,
    [VeriffStatuses.EXPIRED]: 4,
    [VeriffStatuses.DECLINED]: 4,
    [VeriffStatuses.ABANDONED]: 4,
    [VeriffStatuses.APPROVED]: 4,
  }

  const stepClassName = (step, currentStatus) => {
    const activeStep = activeStepByStatus[currentStatus]

    if (activeStep === step) return 'active'
    if (activeStep > step) return 'completed'
    return ''
  }

  const trStatus = (status) => {
    return t(`components.video_room.verification_steps.${status}`)
  }

  const trVerificationField = (field) => {
    return t(`components.video_room.verification_fields.${field}`)
  }

  const idNumberVerified = verification.idNumber && (verification.status === VeriffStatuses.APPROVED)
  const idNumberAlreadyMatching = verification.idNumber === appointmentUser.personalCode

  return (
    <Fragment>
      {!isFinished &&
        <Fragment>
          <div className='status-wrapper stepper-wrapper'>
            {map(['not_started', 'started', 'submitted', 'finished'], (stepName, index) =>
              <StepperItem
                number={index + 1}
                name={stepName}
                className={stepClassName(index + 1, verification.status)}
              />
            )}
          </div>
          {!isCanceled &&
            <div className='verification-body'>
              <p className='text-center mb-0'>{t('components.video_room.waiting_for_verification_result')}</p>
              <div className='buttons text-center mt-3 mb-2'>
                <button className='btn btn-primary' onClick={cancelVerificationRequest}>
                  {t('components.video_room.cancel_verification_request')}
                </button>
              </div>
            </div>
          }
        </Fragment>
      }
      {isFinished && <VerificationReason verification={verification} />}
      {isFinished && showResult &&
        <div className='verification-body'>
          <dl className='row mb-0'>
            <dt className='col-6'>{trVerificationField('first_name')}</dt>
            <dd className='col-6'>{verification.firstName || '-'}</dd>

            <dt className='col-6'>{trVerificationField('last_name')}</dt>
            <dd className='col-6'>{verification.lastName || '-'}</dd>

            <dt className='col-6'>{trVerificationField('document_type')}</dt>
            <dd className='col-6'>
              {verification.documentType
                ? t(`components.video_room.verification_document_types.${verification.documentType.toLowerCase()}`)
                : '-'
              }
            </dd>

            <dt className='col-6'>{trVerificationField('id_number')}</dt>
            <dd className='col-6'>
              <span>{verification.idNumber || '-'}</span>
              {idNumberVerified && !idNumberAlreadyMatching &&
                <ReactTooltip
                  placement='top'
                  overlay={t('components.video_room.apply_id_number_to_client')}
                >
                  <a href='#'
                    className='badge badge-light replace-pcode-btn ml-3'
                    onClick={assignPersonalCode}
                  >
                    {t('components.video_room.apply')}
                  </a>
                </ReactTooltip>
              }
            </dd>

            <dt className='col-6'>{trVerificationField('document_number')}</dt>
            <dd className='col-6'>{verification.documentNumber || '-'}</dd>

            <dt className='col-6'>{trVerificationField('document_country')}</dt>
            <dd className='col-6'>{verification.documentCountry || '-'}</dd>

            <dt className='col-6'>{trVerificationField('document_valid_from')}</dt>
            <dd className='col-6'>{verification.documentValidFrom || '-'}</dd>

            <dt className='col-6'>{trVerificationField('document_valid_until')}</dt>
            <dd className='col-6'>{verification.documentValidUntil || '-'}</dd>
          </dl>
        </div>
      }
    </Fragment>
  )
}

const IdentityVerificationsModal = ({
  appointmentUser, participant, projectId, requestIdentityVerification, subscription, showNotice, onClose
}) => {
  if (!appointmentUser || !appointmentUser.userId) return null

  const verificationStatusClass = (verification) => {
    if (!!verification.canceledAt) return 'danger'

    return {
      [VeriffStatuses.NOT_STARTED]: 'light',
      [VeriffStatuses.STARTED]: 'light',
      [VeriffStatuses.SUBMITTED]: 'light',
      [VeriffStatuses.RESUBMISSION_REQUESTED]: 'warning',
      [VeriffStatuses.EXPIRED]: 'danger',
      [VeriffStatuses.DECLINED]: 'danger',
      [VeriffStatuses.ABANDONED]: 'danger',
      [VeriffStatuses.APPROVED]: 'success',
    }[verification.status]
  }

  const hasVerifications = appointmentUser.identityVerifications.length > 0
  const lastVerification = appointmentUser.identityVerifications[0] // descending

  const lastVerificationPending = lastVerification && includes(VeriffStatuses.PENDING_STATUSES, lastVerification.status)
  const lastVerificationCanceled = lastVerification && !!lastVerification.canceledAt
  const canRequestVerification = !lastVerification || !lastVerificationPending || lastVerificationCanceled

  const formattedPersonalCode = appointmentUser.formattedPersonalCode || participant.formattedPersonalCode

  let modalContent
  if (!hasVerifications) {
    modalContent = (
      <div className='text-center'>
        <i className='fa fa-question fa-5x text-white' />
        <h5 className='my-4'>{t('components.video_room.client_not_verified')}</h5>
        <p className='client-info'>
          <span className='client-name'>{appointmentUser.userFullName}</span><br/>
          <span className='client-id-number'>{formattedPersonalCode}</span>
        </p>
        <div className='text-center mt-4'>
          <a href='#' className='btn blue-modal-btn' onClick={() => requestIdentityVerification(appointmentUser)}>
            {t('components.video_room.request_verification')}
          </a>
        </div>
      </div>
    )
  } else {
    modalContent = (
      <div>
        <p className='client-info'>
          <span className='client-name mb-0'>{appointmentUser.userFullName}</span><br/>
          <span className='client-id-number mb-4'>{formattedPersonalCode}</span>
        </p>
        {map(appointmentUser.identityVerifications, (verification, index) =>
          <div className={`verification-wrapper ${index > 0 ? 'old' : ''}`} key={verification.id}>
            <a className='verification-header' data-toggle='collapse' href={`#verification_${verification.id}`} role='button'>
              <div className='verification-title'>
                <i className='fa fa-chevron-down mr-2' />
                <span>
                  {l('time.formats.default', verification.createdAt)}
                </span>

                <ReactTooltip
                  placement='top'
                  overlay={
                    t('components.video_room.verification_updated_at', {
                      updated_at: l('time.formats.default', verification.updatedAt)
                    })
                  }
                >
                  <span className={`badge badge-${verificationStatusClass(verification)} status-badge pull-right`}>
                    {!!verification.canceledAt ? t('components.video_room.verification_statuses.canceled')
                    : t(`components.video_room.verification_statuses.${verification.status}`)}
                  </span>
                </ReactTooltip>
              </div>
            </a>
            <div className={`collapse ${index === 0 ? 'show' : ''}`} id={`verification_${verification.id}`}>
              <Verification
                verification={verification}
                appointmentUser={appointmentUser}
                subscription={subscription}
                showNotice={showNotice}
                projectId={projectId}
              />
            </div>
          </div>
        )}
        {canRequestVerification &&
          <div className='text-center mt-4'>
            <a href='#' className='btn blue-modal-btn' onClick={() => requestIdentityVerification(appointmentUser)}>
              {t('components.video_room.request_verification_again')}
            </a>
          </div>
        }
      </div>
    )
  }

  return (
    <Modal className='blue-modal modal-small verifications-modal' isOpen={true}>
      <span className='modal-close' onClick={onClose}>
        <i className="fa fa-close fa-lg"></i>
      </span>
      {modalContent}
    </Modal>
  )
}

export default IdentityVerificationsModal
