import React, { Component } from 'react'
import moment from 'moment'
import find from 'lodash/find'
import { t } from 'i18n'
import { Marker } from 'react-google-maps'
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox'
import { stringifiedTimeInterval } from '../files/helpers'

export default class NotaryLocation extends Component {
  state = { popupVisible: (this.props.id == this.props.openedPopupId) }

  constructor(props) {
    super(props)

    this.currentWeekday = moment(new Date).isoWeekday()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.openedPopupId !== nextProps.openedPopupId) {
      this.setState({ popupVisible: this.props.id === nextProps.openedPopupId })
    }
  }

  onClick() {
    this.props.onClick(this.props)
  }

  onMouseEnter() {
    this.props.onMouseEnter(this.props)
  }

  onMouseLeave() {
    this.props.onMouseLeave(this.props)
  }

  renderNotary() {
    if (!this.state.popupVisible) {
      return
    }

    const { placeOfPractice, notary } = this.props
    const { image_url, notary_link, full_name, known_languages, distance } = notary

    return (
      <InfoBox options={{ alignBottom: true, pixelOffset: { width: -125, height: -42 }, closeBoxURL: '', enableEventPropagation: true }}>
        <div>
          <div id='place-modal' className='modal-notary'>
            <i className='fa fa-times popup-close-icon' onClick={this.props.closePopup}/>
            <div className='info-header'>
              <div className='notary-image-icon'>
                <img src={image_url} />
              </div>
              <h6 className='text-white'>{full_name}</h6>
              <p className='county-court mb-0'>{notary.county_court}</p>
              {this.renderWorkingTime(placeOfPractice)}
              {this.notaryAbsence()}
              {placeOfPractice.address}
              <a href={notary_link} className='btn profile-link-mobile-btn'>{t('notaries.show.view_profile')}</a>
              <div className='distance-container'>
                <i className='fa fa-location-arrow fa-2x' />
                <div id='distance'>{distance}</div>
              </div>
            </div>
          </div>
          <div className='modal-notary-pointer' />
        </div>
      </InfoBox>
    )
  }

  renderWorkingTime(placeOfPractice) {
    const { active, closest_absence } = this.props.notary

    if (!active) {
      return (
        <div className='working-times'>
          {t('notaries_map.index.not_practicing')}
        </div>
      )
    }

    if (closest_absence) return

    const placeWorkingTimeToday = find(placeOfPractice.working_times, wt =>
      wt.weekday === this.currentWeekday && wt.open
    )

    if (placeWorkingTimeToday) {
      return (
        <div className='working-times'>
          {t('notaries_map.index.today_working') + ': '}
          {stringifiedTimeInterval(placeWorkingTimeToday.time_from, placeWorkingTimeToday.time_until)}
        </div>
      )
    }

    return <div className='working-times'>{t('notaries.show.today_not_working')}</div>
  }

  notaryAbsence() {
    const { absence_interval, absence_replacement_link } = this.props.notary

    if (absence_interval) {
      return (
        <div className='absence-container'>
          <div className='absence'>
            {absence_interval}
          </div>
          <div className='replacement' dangerouslySetInnerHTML={{__html: absence_replacement_link}}>
          </div>
        </div>
      )
    }
  }

  render() {
    const { lat, lng, markerImage } = this.props

    return (
      <Marker position={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
              onMouseOver={this.onMouseEnter.bind(this)}
              onMouseOut={this.onMouseLeave.bind(this)}
              onClick={this.onClick.bind(this)}
              icon={{ url: markerImage, scaledSize: { width: 30, height: 41 } }}>
        {this.renderNotary()}
      </Marker>
    )
  }
}
