import React from 'react'
import moment from 'moment'
import Select from 'react-select'
import axios from 'axios'
import filter from 'lodash/filter'
import find from 'lodash/find'
import includes from 'lodash/includes'
import map from 'lodash/map'
import some from 'lodash/some'
import { t, l } from 'i18n'
import InputWrapper from '../utilityComponents/InputWrapper'
import { toRigaTimezone } from '../../files/helpers'

const DEFAULT_DURATION_MINUTES = 30

const renderAddressBlock = function() {
  const placesOfPractice = this.props.placesOfPractice.filter((pop) => pop.active)
  if (placesOfPractice.length > 1) {
    const addressOptions = placesOfPractice.map((pop) => { return { value: pop.id, label: pop.address } })
    const selected = this.state.selectedPlaceOfPractice || {}
    return(
      <InputWrapper className='address-input' errorArray={this.state.errors.address}>
        <label className='input-label mb-1'>{t('activerecord.attributes.appointment.address')}</label>
        <Select
          classNamePrefix='react-select'
          className='react-select'
          name='selected_place_of_practice_id'
          options={addressOptions}
          clearable={false}
          value={{ value: selected.id, label: selected.address }}
          onChange={this.handlePlaceOfPracticeChange}/>
        </InputWrapper>
    )
  } else {
    return (<p className='mr-1'>{t('activerecord.attributes.appointment.address')}: {placesOfPractice[0].address}</p>)
  }
}

const handleTimeInputChange = function(obj) {
  let value = obj ? obj.value : ''
  this.setState({
    appointment: this.changedAppointment({ raw_time: value })
  })
}

const handlePlaceOfPracticeChange = function({ value }) {
  const newPlaceOfPractice = this.props.placesOfPractice.find((pop) => pop.id == value)

  this.setState({
    selectedPlaceOfPractice: newPlaceOfPractice,
    appointment: this.changedAppointment({ address: newPlaceOfPractice.address })
  })
}

const selectedWorkingTimeOptions = function() {
  const { selectedPlaceOfPractice, appointment } = this.state
  if (!selectedPlaceOfPractice || !appointment.date) {
    return []
  }

  const selectedWorkingTime = selectedPlaceOfPractice.working_times.find(wt => wt.weekday == appointment.date.isoWeekday())
  if (selectedWorkingTime) {
    return [...selectedWorkingTime.time_options]
  } else {
    return []
  }
}

const timeOptions = function() {
  const { futureUnavailabilityIntervals = [], appointment: { date } } = this.state
  if (!date) return []

  let options = this.selectedWorkingTimeOptions()

  options.unshift('')

  return options.map(timeOption => {
    const [hour, minute] = timeOption.split('.')
    const timeSlotFrom = toRigaTimezone(moment(date)).set({ hour, minute })
    const timeSlotUntil = moment(timeSlotFrom).add(DEFAULT_DURATION_MINUTES * 60 - 1, 'seconds')

    const timeSlotOverlappedByUnavailability = some(futureUnavailabilityIntervals, ({ time_from, time_until }) =>
      timeSlotFrom <= time_until && timeSlotUntil >= time_from
    )

    return {
      value: timeOption,
      label: timeOption,
      isDisabled: timeSlotOverlappedByUnavailability
    }
  })
}

const changedAppointment = function(changeset) {
  return Object.assign({}, this.state.appointment, changeset)
}

const handleDateChange = function(date) {
  this.setState({
    appointment: this.changedAppointment({ date: moment(date, 'MM-DD-YYYY'), raw_time: '' })
  })
}

const handleDateBlur = function({ target: { name, value } }) {
  const dateFormat = t('date.formats.moment')
  const parsedDate = moment(value, dateFormat)
  const date = parsedDate.isValid() ? parsedDate : null

  this.setState({
    appointment: this.changedAppointment({ [name]: date })
  })
}

const appointmentStatusClass = function(appointment) {
  if (appointment.canceled)
    return 'status-red'
  else if (appointment.passed)
    return 'status-blue'
  else if (appointment.confirmed)
    return 'status-green'
  else if (!appointment.confirmed)
    return 'status-yellow'
}

const appointmentStatus = function(appointment) {
  if (appointment.canceled)
    return t('components.user_appointments.canceled')
  else if (appointment.passed)
    return t('components.user_appointments.passed')
  else if (appointment.confirmed)
    return t('components.user_appointments.confirmed')
  else if (!appointment.confirmed)
    return t('components.user_appointments.not_confirmed')
}

const appointmentCancelationWarning = function(appointment) {
  return t('components.user_appointments.cancelation_confirmation', {
    time: l('time.formats.extended', appointment.datetime),
    notary: t(`text_helpers.notary_gen_${appointment.notary.gender || 'male'}`),
    notary_name: appointment.notary.full_name_gen
  })
}

const isDateAvailable = function(date, workingWeekdays) {
  const { futureUnavailabilityIntervals, selectedPlaceOfPractice } = this.state
  const weekday = date.isoWeekday()

  const isWorkingDay = includes(workingWeekdays, weekday)
  if (!isWorkingDay) return false

  const workingDayMidnight = date.startOf('day')
  const workingTime = find(selectedPlaceOfPractice.working_times, wt => wt.weekday === weekday)
  const workingTimeFrom = workingDayMidnight.clone().add(workingTime.time_from_minutes, 'minutes')
  const workingTimeUntil = workingDayMidnight.clone().add(workingTime.time_until_minutes, 'minutes')

  const wholeWorkingTimeUnavailable = some(futureUnavailabilityIntervals, ({ time_from, time_until }) =>
    time_from <= workingTimeFrom && workingTimeUntil <= time_until
  )

  return !wholeWorkingTimeUnavailable
}

const fetchFutureUnavailabilityIntervals = function(excludeAppointmentId = null) {
  const { selectedPlaceOfPractice } = this.state
  let url = `/notaries/${this.state.appointment.notary_id}/future_unavailability_intervals`

  const params = {
    exclude_appointment_id: excludeAppointmentId,
    place_of_practice_id: selectedPlaceOfPractice.id
  }

  axios.get(url, { params }).then(({ data }) => {
    const futureUnavailabilityIntervals = map(data, interval => {
      const time_from = moment(interval.time_from)
      const time_until = moment(interval.time_until || '9999-12-31')

      return { time_from, time_until }
    })

    this.setState({ futureUnavailabilityIntervals })
  })
}

const workingWeekdays = function(placeOfPractice) {
  if (!placeOfPractice) return []

  return filter(placeOfPractice.working_times, wt =>
    wt.time_from_minutes !== null && wt.time_until_minutes !== null
  ).map(wt => wt.weekday)
}

export { renderAddressBlock, handleTimeInputChange, handlePlaceOfPracticeChange,
  selectedWorkingTimeOptions, changedAppointment, handleDateChange, handleDateBlur,
  timeOptions, appointmentStatusClass, appointmentStatus, appointmentCancelationWarning,
  isDateAvailable, workingWeekdays, fetchFutureUnavailabilityIntervals }
