import React, { Component } from 'react'
import { t } from 'i18n'
import NotaryAvailabilityIndicator from './NotaryAvailabilityIndicator'
import { stringifiedTimeInterval } from '../files/helpers'

class NotaryCardsItem extends Component {
  renderWorkingTime() {
    const { working_time_today, absence_interval, absence_replacement_link } = this.props.notary

    if(absence_interval) {
      return (
        <div className='working-time'>
          {absence_interval}
          <div className='replacement' dangerouslySetInnerHTML={{__html: absence_replacement_link}}>
          </div>
        </div>
      )
    }

    if(working_time_today) {
      return (
        <div className='working-time'>
          {t('notaries_map.index.today_working') + ': '}
          {stringifiedTimeInterval(working_time_today.time_from, working_time_today.time_until)}
        </div>
      )
    }

    return <div className='working-time'>{t('notaries.show.today_not_working')}</div>
  }

  render() {
    const {
      full_name, image_url, places_of_practice, id, notary_link, online_operations
    } = this.props.notary

    const placeOfPractice = places_of_practice[0] ? places_of_practice[0].address : ""

    return (
      <a id={"notary_" + id} className='col-lg-4 col-md-6 col-sm-12 col-12' href={notary_link}>
        <div className='notary-card'>
          <div className='notary-image-icon'>
            <img className='notary-profile-image' src={image_url} />
            <NotaryAvailabilityIndicator notaryId={id} onlineOperations={online_operations} />
          </div>
          <div className='notary-content'>
            <div className='name'>{full_name}</div>
            {this.renderWorkingTime()}
            <div className='address'>
              <i className='fa fa-location-arrow' />
              {placeOfPractice}
            </div>
          </div>
        </div>
      </a>
    )
  }
}

export default NotaryCardsItem
