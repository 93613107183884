import UsersIndex from 'components/admin/UsersIndex'
import FooterItemFieldset from 'components/FooterItemFieldset'
import ArticleCardsBlock from 'components/ArticleCardsBlock'
import VideoCardsBlock from 'components/VideoCardsBlock'
import NotaryMap from 'components/NotaryMap'
import MultiSelect from 'components/MultiSelect'
import ResizableTextArea from 'components/ResizableTextArea'
import ProfileAppointmentForm from 'components/appointments/ProfileAppointmentForm'
import ReschedulingButton from 'components/appointments/ReschedulingButton'
import MoreServicesButton from 'components/services/MoreServicesButton'
import NotaryCardsBlock from 'components/NotaryCardsBlock'
import FileUploaderContainer from 'components/cms/FileUploaderContainer'
import IconSelect from 'components/admin/IconSelect'
import AppointmentsCalendar from 'components/notary/AppointmentsCalendar'
import AsapAppointmentToggle from 'components/notary/AsapAppointmentToggle'
import Tooltip from 'components/utilityComponents/Tooltip'
import Messenger from 'components/messenger/Messenger'
import ProjectContainer from 'components/project/Container'
import { StaticNotice } from 'components/utilityComponents/Notice'
import ActivitiesTable from 'components/activities/Table'
import ProjectNewAppointmentButton from 'components/project/NewAppointmentButton'
import ProjectFilesStep from 'components/project/sidebar_steps/ProjectFiles'
import UnreadMessagesBubble from 'components/UnreadMessagesBubble'
import WarrantCheckForm from 'components/warrantCheck/Form'
import WarrantCheckContainer from 'components/warrantCheck/Container'
import WarrantChecksTable from 'components/warrantCheck/Table'
import ApprovalInquiryForm from 'components/approvalInquiry/Form'
import UserAppointments from 'components/appointments/UserAppointments'
import TextMaskInput from 'components/utilityComponents/TextMaskInput'
import FileInput from 'components/utilityComponents/FileInput'
import BrowserDatetime from 'components/utilityComponents/BrowserDatetime'
import NotaryAvailabilityIndicator from 'components/NotaryAvailabilityIndicator'
import NotaryAsapAvailabilityReminder from 'components/NotaryAsapAvailabilityReminder'
import Notifications from 'components/notifications'
import WebpackerReact from 'webpacker-react'
import InheritanceCheckForm from 'components/inheritanceCheck/InheritanceCheckForm'
import InheritanceHistoryTable from 'components/inheritanceCheck/InheritanceHistoryTable'
import InheritanceMatterShow from 'components/inheritanceCheck/InheritanceMatterShow'

WebpackerReact.setup({
  ProjectNewAppointmentButton,
  FooterItemFieldset,
  ArticleCardsBlock,
  VideoCardsBlock,
  NotaryMap,
  MultiSelect,
  ResizableTextArea,
  ProfileAppointmentForm,
  ReschedulingButton,
  MoreServicesButton,
  NotaryCardsBlock,
  UsersIndex,
  FileUploaderContainer,
  AppointmentsCalendar,
  AsapAppointmentToggle,
  IconSelect,
  Tooltip,
  Messenger,
  ProjectContainer,
  Notice: StaticNotice,
  ActivitiesTable,
  ProjectFilesStep,
  UnreadMessagesBubble,
  WarrantCheckForm,
  WarrantCheckContainer,
  WarrantChecksTable,
  ApprovalInquiryForm,
  UserAppointments,
  TextMaskInput,
  FileInput,
  BrowserDatetime,
  NotaryAvailabilityIndicator,
  NotaryAsapAvailabilityReminder,
  Notifications,
  InheritanceCheckForm,
  InheritanceHistoryTable,
  InheritanceMatterShow
})
