import React, { Component } from 'react'
import axios from 'axios'
import chunk from 'lodash/chunk'
import map from 'lodash/map'
import shuffle from 'lodash/shuffle'
import slice from 'lodash/slice'
import NotaryCardsItem from './NotaryCardsItem'

const NOTARIES_PER_PAGE = 6

class NotaryCardsBlock extends Component {
  constructor(props) {
    super(props)
    this.state = {
      notaries: [],
      currentPage: 0,
      lastPage: null,
      isLoading: false
    }
  }

  componentDidMount() {
    this.fetchNotaries()
  }

  getCurrentNotaries = () => {
    const { notaries, currentPage } = this.state
    if (notaries.length === 0) return []

    const startIndex = NOTARIES_PER_PAGE * currentPage
    return slice(notaries, startIndex, startIndex + NOTARIES_PER_PAGE)
  }

  fetchNotaries() {
    this.setState({ isLoading: true })

    axios.get(`/notaries.json`)
      .then(response => {
        this.setState({
          notaries: shuffle(response.data).filter(n => !n.is_test_notary),
          isLoading: false,
          lastPage: parseInt(response.data.length / NOTARIES_PER_PAGE)
        })
      })
      .catch(() => {}) //Add empty catch, so we don't have to do `if` for response status
  }

  handleClickForward() {
    const { lastPage, currentPage } = this.state
    //if last page has not been reached, this varaible will be null
    let nextPage = lastPage && lastPage == currentPage ? 0 : currentPage + 1

    this.setState({currentPage: nextPage})
  }

  handleClickBackward() {
    const { lastPage, currentPage } = this.state

    // Arrow back leads to last page from first page (if there is a last page)
    let previousPage = currentPage == 0 && lastPage != null ? lastPage : currentPage - 1

    this.setState({currentPage: previousPage})
  }

  renderNotaries() {
    const currentNotaries = this.getCurrentNotaries()
    const rows = map(currentNotaries, notary => <NotaryCardsItem notary={notary} key={notary.id} />)

    return (
      <div className='carousel-item active'>
        <div className='container'>
          <div className='row'>
            {rows}
          </div>
        </div>
      </div>
    )
  }

  renderNextButton() {
    const { isLoading } = this.state
    if(isLoading) {
      return (
        <div className='row'>
          <div className='text-center col-12'>
            <span className='loading-indicator'></span>
          </div>
        </div>
      )
    } else {
      return (
        <a id="next" className='carousel-control-next' onClick={this.handleClickForward.bind(this)} data-slide="next" href="#notaries-cards" role="button">
          <i className='fa fa-angle-right fa-2x black' aria-hidden="true"/>
          <span className='sr-only' value='Next'/>
        </a>
      )
    }
  }

  renderPreviousButton() {
    const { currentPage, lastPage } = this.state
    // don't show if only the first batch of notaries is loaded
    if(currentPage > 0 || lastPage != null) {
      return (
        <a id="prev" className='carousel-control-prev' data-slide="prev" href="#notaries-cards" role="button" onClick={this.handleClickBackward.bind(this)}>
          <i className='fa fa-angle-left fa-2x black' aria-hidden="true" />
          <span className='sr-only' value='Previous' />
        </a>
      )
    }
  }

  render() {
    return (
      <div className="carousel" id="notaries-cards" data-interval="false">
        <div className="carousel-inner" role="listbox">
          {this.renderNotaries()}
        </div>
        {this.renderPreviousButton()}
        {this.renderNextButton()}
      </div>
    )
  }
}

export default NotaryCardsBlock
