import { t } from 'i18n'
import React from 'react'
import ReactTooltip from 'rc-tooltip'
import moment from 'moment'

const valueOrDash = (value) => {
  return value || <>&mdash;</>
}

const scope = 'components.inheritance_check.table'

const Item = ({ name, value }) => {
  return (
    <dl className='row'>
      <dt className="col-sm-12 col-lg-4">{t(name, { scope })}</dt>
      <dd className="col-sm-12 col-lg-8">{valueOrDash(value)}</dd>
    </dl>
  )
}

const InheritanceMatterShow = ({ inheritanceCheck }) => {
  return (
    <div className='container'>
      <div className='inheritance-show-header'>
        <ReactTooltip
          placement='bottom'
          overlay={t('components.inheritance_check.go_to_history')}
        >
          <a href='/inheritance_checks'>
            <i className="fa fa-arrow-left fa-2x mr-3"/>
          </a>
        </ReactTooltip>
        <h5 className='inheritance-header mt-5'>{t('components.inheritance_check.go_to_history')}</h5>
        <a
          className='btn form-submit-button download-pdf'
          href={`/inheritance_checks/${inheritanceCheck.id}/view_document?format=pdf`}
          target="_blank"
        >
          <i className='fa fa-download mr-2' />
          {t('components.inheritance_check.download_pdf')}
        </a>
      </div>
      <div className="search-data  mb-2">
        <h5 className='inheritance-header'>
          {`${t('request_data', { scope })}: `}
          {inheritanceCheck.searcherName}, {moment(inheritanceCheck.createdAt).format(t('moment_time.formats.default'))}
        </h5>
      </div>
      {inheritanceCheck.inheritanceMatters.map(matter =>
        <div key={`matter-${matter.id}`}>
         <h5 className='inheritance-header'>
            {t('inheritance_check', { scope })} {t('number', { scope })} {matter.matterNo}
          </h5>
          <div className='inheritance-table-container'>
            <table className='table inheritance-checks-table services-table bg-light-blue mb-5'>
              <tbody>
                <tr className='bg-light-blue'>
                  <th>{t('inheritance_check', { scope })}</th>
                  <td>
                    <div className="row">
                      <div className="col-xs-12 col-md-6">
                        <Item name='notary' value={`${matter.notaryName}, ${matter.notaryAddress}`}/>
                        <Item name='registration_number' value={matter.matterNo} />
                        <Item name='registration_date' value={moment(matter.registered).format(t('moment_time.formats.default'))} />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{t('testators', { scope })}</th>
                  <td>
                    {matter.testators.map((testator) =>
                    <div className="testator-row-container" key={`testator-${testator.id}`}>
                      <div className="row">
                        <div className="col-xs-12 col-md-6">
                          <Item name='personal_code' value={testator.formattedPersonId} />
                          <Item name='name' value={testator.firstName} />
                          <Item name='last_name' value={testator.surname} />
                          <Item name='birth_date' value={testator.birthDate} />
                          <Item name='death_date' value={testator.deathDate} />
                        </div>
                        <div className="col-xs-12 col-md-6">
                          <Item name='alt_name' value={testator.altFirstName} />
                          <Item name='alt_last_name' value={testator.altSurname} />
                          <Item name='other_names' value={testator.otherNames} />
                        </div>
                      </div>
                    </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>{t('proclamation', { scope })}</th>
                  <td>
                    <div className="row">
                      <div className="col-xs-12 col-md-6">
                        <Item name='announced' value={matter.announceType} />
                        <Item name='issue_number' value={matter.issue} />
                        <Item name='announcement_date' value={matter.announced} />
                        <Item name='application_deadline' value={matter.invitationTerm == 0 ? <>&mdash;</> : matter.invitationTerm} />
                        <Item name='announcement' value={matter.annoucement} />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{t('notes', { scope })}</th>
                  <td>
                    <div className="row">
                      <div className="col-xs-12 col-md-6">
                        <dl className='row'>
                          <dd className="col-lg-12 mb-0">{valueOrDash(matter.notes)}</dd>
                        </dl>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        )}
    </div>
  )
}

export default InheritanceMatterShow
