import React, { Component } from 'react'
import InputMask from 'react-input-mask'
import moment from 'moment'
import InputWrapper from '../utilityComponents/InputWrapper'
import HintIcon from '../utilityComponents/HintIcon'
import { AsyncPaginate } from 'react-select-async-paginate'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import { pick } from 'lodash'
import { t, locale } from 'i18n'
import MediaQueries from '../utilityComponents/MediaQueries'
import cableConsumer from '../../files/cableConsumer'

// Starting from this date, warrants have to be searched by their number instead of the trustor's information
const WARRANT_MIN_DATE = moment('01.07.2018.', t('date.formats.moment'))

const ErrorMessage = ({ message }) => {
  if (!message) return null

  return (
    <div className='errors-section'>
      <i className='fa fa-exclamation-circle mr-2' />
      {message}
    </div>
  )
}

class WarrantCheckForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      loading: false,
      warrant_check: {
        warrant_date: null,
        warrant_number: '',
        selected_notary: '',
        notary_id: '',
        is_legal_person: false,
        is_foreign_citizen: false,
        person_first_name: '',
        person_last_name: '',
        person_personal_code: '',
        person_birth_date: null,
        company_name: '',
        company_registration_number: ''
      }
    }

    this.defaultPersonAttrs = pick(this.state.warrant_check, [
      'person_first_name', 'person_last_name', 'person_personal_code', 'person_birth_date',
      'company_name', 'company_registration_number'
    ])

    this.changedWarrantCheck = this.changedWarrantCheck.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleRadioChange = this.handleRadioChange.bind(this)
    this.renderInputs = this.renderInputs.bind(this)
    this.renderMinimalInputs = this.renderMinimalInputs.bind(this)
    this.renderFullInputs = this.renderFullInputs.bind(this)
    this.renderNaturalPersonInputs = this.renderNaturalPersonInputs.bind(this)
    this.renderLegalPersonInputs = this.renderLegalPersonInputs.bind(this)
    this.renderForeignCheckbox = this.renderForeignCheckbox.bind(this)
  }

  changedWarrantCheck(changeset) {
    return Object.assign({}, this.state.warrant_check, changeset)
  }

  handleDateChange(fieldName, date) {
    this.setState({
      warrant_check: this.changedWarrantCheck({ [fieldName]: date })
    })
  }

  handleDateBlur(fieldName, { target: { value } }) {
    const dateFormat = t('date.formats.moment')
    const parsedDate = moment(value, dateFormat)
    const date = parsedDate.isValid() ? parsedDate : null

    this.setState({
      warrant_check: this.changedWarrantCheck({ [fieldName]: date })
    })
  }

  handleInputChange({ target }) {
    const value = target.validity.valid ? target.value : this.state.warrant_check[target.name]

    this.setState({
      warrant_check: this.changedWarrantCheck({ [target.name]: value })
    })
  }

  handleRadioChange(e) {
    const { target } = e

    this.setState({
      warrant_check: this.changedWarrantCheck({
        ...this.defaultPersonAttrs,
        [target.name]: target.value === 'true' ? true : false
      })
    })
  }

  handleNotaryInputChange(obj) {
    this.setState({
      warrant_check: this.changedWarrantCheck({ selected_notary: obj, notary_id: obj && obj.value })
    })
  }

  handleForeignCheckbox(e) {
    const { target } = e

    this.setState({
      warrant_check: this.changedWarrantCheck({
        person_personal_code: '',
        company_registration_number: '',
        is_foreign_citizen: target.checked
      })
    })
  }

  async fetchNotaries(input, existingNotaries) {
    const { data } = await axios.get('/notaries/search.json', { params: { q: input, offset: existingNotaries.length }})
    return {
      options: data.notaries,
      hasMore: data.has_more
    }
  }

  submitWarrantCheck() {
    const { warrant_check } = this.state
    const birth_date = warrant_check.person_birth_date

    axios.post('/warrant_checks', {
      warrant_check: {
        ...warrant_check,
        warrant_date: warrant_check.warrant_date.format(t('moment_date.formats.default')),
        person_birth_date: birth_date && birth_date.format(t('moment_date.formats.default'))
      }
    }).then(({ data }) => {
      this.setState({ errors: {}, loading: true })

      this.warrant_check_url = data.warrant_check_url

      this.subscription = cableConsumer.subscriptions.create({
        channel: 'WarrantCheckChannel',
        warrant_check_id: data.warrant_check.id
      }, {
        received: this.handleChannelReceive.bind(this)
      })
    }).catch(({ response }) => {
      this.setState({ errors: response.data })
    })
  }

  handleChannelReceive(data) {
    if ((data.payment && data.payment.vraa_url) || data.status === 'failed' || data.show_results) { // Warrant check performed
      location = this.warrant_check_url
    }
  }

  mobileReadOnly = (el) => {
    if (!this.props.isMobile || !el) return
    el.readOnly = true
  }

  renderInputs() {
    const { warrant_date } = this.state.warrant_check
    let buttonContent
    if (!warrant_date) return

    if (this.state.loading) {
      buttonContent = (
        <span>
          {t('search_in_progress')}
          {this.renderSpinner()}
        </span>
      )
    } else {
      buttonContent = t('search')
    }

    return (
      <div>
        {warrant_date >= WARRANT_MIN_DATE ? this.renderMinimalInputs() : this.renderFullInputs()}
        <div className='text-center'>
          <button type='button'
            disabled={!!this.state.loading}
            className='btn form-submit-button'
            onClick={this.submitWarrantCheck.bind(this)}
          >
            {buttonContent}
          </button>
        </div>
      </div>
    )
  }

  renderSpinner() {
    return (
      <span className='loading-indicator small'></span>
    )
  }

  renderMinimalInputs() {
    const { errors } = this.state
    const { warrant_number, selected_notary, notary_id } = this.state.warrant_check

    return (
      <div className='minimal-form'>
        <InputWrapper errorArray={errors.warrant_number}>
          <label className='control-label required'>{t('components.warrant_check.warrant_number')}</label>
          <input
            type='text'
            pattern='[0-9]*'
            className='form-control'
            name='warrant_number'
            onChange={this.handleInputChange}
            placeholder={t('components.warrant_check.warrant_number_placeholder')}
            value={warrant_number} />
          <HintIcon className='input-hint-right' faIcon='info-circle'>
            <p>{t('components.warrant_check.hints.warrant_number_desc')}</p>
          </HintIcon>
        </InputWrapper>
        <InputWrapper errorArray={errors.notary_id}>
          <label className='control-label required'>{t('components.warrant_check.sworn_notary')}</label>
          <AsyncPaginate
            classNamePrefix='react-select'
            className='react-select'
            name='notary_id'
            loadOptions={this.fetchNotaries}
            value={selected_notary}
            placeholder={t('components.warrant_check.sworn_notary_placeholder')}
            onChange={this.handleNotaryInputChange.bind(this)}
          />
          <HintIcon className='input-hint-right' faIcon='info-circle'>
            <p>{t('components.warrant_check.hints.sworn_notary_desc')}</p>
          </HintIcon>
        </InputWrapper>
      </div>
    )
  }

  renderFullInputs() {
    const { is_legal_person } = this.state.warrant_check

    return (
      <div className='full-form'>
        <InputWrapper>
          <div className='d-inline-block'>
            <label className='mr-5'>{t('components.warrant_check.principal')}</label>
          </div>
          <div className='d-inline-block'>
            <label className='mr-4'>
              <input type='radio' name='is_legal_person' value='false' id='natural-person' className='mr-2' onChange={this.handleRadioChange} checked={!is_legal_person} />
              {t('components.warrant_check.natural_person')}
            </label>
            <label>
              <input type='radio' name='is_legal_person' value='true' id='legal-person' className='mr-2' onChange={this.handleRadioChange} checked={is_legal_person} />
              {t('components.warrant_check.legal_person')}
            </label>
          </div>
        </InputWrapper>
        {is_legal_person ? this.renderLegalPersonInputs() : this.renderNaturalPersonInputs()}
      </div>
    )
  }

  renderDatePickerInput() {
    const { isMobile } = this.props
    const inputProps = isMobile ? { inputRef: this.mobileReadOnly } : {}

    return <InputMask mask='99.99.9999.' {...inputProps} />
  }

  renderNaturalPersonInputs() {
    const { warrant_check, errors } = this.state
    const { is_foreign_citizen } = warrant_check

    const personIdRequiredClass  = !!warrant_check.person_birth_date ? '' : 'required'
    const birthDateRequiredClass = !!warrant_check.person_personal_code ? '' : 'required'

    return (
      <div className='natural-person-inputs'>
        <div className='row'>
          <div className='col-sm-6'>
            <InputWrapper errorArray={errors.person_personal_code}>
              <label className={`control-label ${personIdRequiredClass}`}>{t('components.warrant_check.personal_code')}</label>
              <InputMask
                type='text'
                className='form-control mb-3'
                name='person_personal_code'
                value={warrant_check.person_personal_code}
                placeholder={t('components.warrant_check.personal_code_placeholder')}
                onChange={this.handleInputChange}
                mask={is_foreign_citizen ? false : '999999-99999'}
              />
            </InputWrapper>
            {this.renderForeignCheckbox()}
          </div>
          <div className='col-sm-6'>
            <InputWrapper className='datepicker' errorArray={errors.person_birth_date}>
              <label className={`control-label ${birthDateRequiredClass}`}>{t('components.warrant_check.birth_date')}</label>
              <DatePicker
                className='form-control'
                placeholderText={t('components.warrant_check.birth_date_placeholder')}
                dateFormat={t('date.formats.moment')}
                selected={warrant_check.person_birth_date}
                onChange={this.handleDateChange.bind(this, 'person_birth_date')}
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                todayButton={t('components.datepicker.today')}
                customInput={this.renderDatePickerInput()}
                withPortal={this.props.isMobile}
                locale={locale}
                isClearable
              />
            </InputWrapper>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <InputWrapper errorArray={errors.person_first_name}>
              <label className='control-label required'>{t('components.warrant_check.first_name')}</label>
              <input type='text' className='form-control' name='person_first_name' placeholder={t('components.warrant_check.first_name_placeholder')}
                value={warrant_check.person_first_name} onChange={this.handleInputChange} />
            </InputWrapper>
          </div>
          <div className='col-sm-6'>
            <InputWrapper errorArray={errors.person_last_name}>
              <label className='control-label required'>{t('components.warrant_check.last_name')}</label>
              <input type='text' className='form-control' name='person_last_name' placeholder={t('components.warrant_check.last_name_placeholder')}
                value={warrant_check.person_last_name} onChange={this.handleInputChange} />
            </InputWrapper>
          </div>
        </div>
      </div>
    )
  }

  renderLegalPersonInputs() {
    const { warrant_check, errors } = this.state
    const { is_foreign_citizen } = warrant_check

    return (
      <div className='legal-person-inputs'>
        <InputWrapper errorArray={errors.company_registration_number}>
          <label className='control-label required'>{t('components.warrant_check.registration_number')}</label>
          <InputMask
            type='text'
            className='form-control mb-3'
            name='company_registration_number'
            value={warrant_check.company_registration_number}
            placeholder={t('components.warrant_check.registration_number_placeholder')}
            onChange={this.handleInputChange}
            mask={is_foreign_citizen ? false : '99999999999'}
          />
          {this.renderForeignCheckbox()}
        </InputWrapper>
        <InputWrapper errorArray={errors.company_name}>
          <label className='control-label required'>{t('components.warrant_check.name')}</label>
          <input type='text' className='form-control' name='company_name' placeholder={t('components.warrant_check.name_placeholder')}
            value={warrant_check.company_name} onChange={this.handleInputChange} />
        </InputWrapper>
      </div>
    )
  }

  renderForeignCheckbox() {
    const { is_foreign_citizen } = this.state.warrant_check

    return (
      <div className='foreign-checkbox mb-4'>
        <input type='checkbox' id='is_foreign_citizen' name='is_foreign_citizen' checked={is_foreign_citizen} onChange={this.handleForeignCheckbox.bind(this)} />
        <label htmlFor='is_foreign_citizen' className='ml-1'>{t('components.warrant_check.foreign')}</label>
      </div>
    )
  }

  render() {
    const { errors } = this.state

    return (
      <div className='service-form warrant-check-form col-sm-8 col-md-6 col-lg-5 col-xl-4 m-auto py-5'>
        <ErrorMessage message={errors && errors.base} />

        <InputWrapper className='datepicker'>
          <label className='control-label required'>{t('components.warrant_check.warrant_date')}</label>
          <DatePicker
            className='form-control'
            dateFormat={t('date.formats.moment')}
            placeholderText={t('components.warrant_check.warrant_date_placeholder')}
            selected={this.state.warrant_check.warrant_date}
            onChange={this.handleDateChange.bind(this, 'warrant_date')}
            onBlur={this.handleDateBlur.bind(this, 'warrant_date')}
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            todayButton={t('components.datepicker.today')}
            customInput={this.renderDatePickerInput()}
            withPortal={this.props.isMobile}
            locale={locale}
            name='date'
          />
          <HintIcon className='input-hint-right' faIcon='info-circle'>
            <p className='font-weight-bold'>{t('components.warrant_check.hints.warrant_date_title')}</p>
            <p>{t('components.warrant_check.hints.warrant_date_desc')}</p>
          </HintIcon>
        </InputWrapper>

        {this.renderInputs()}
      </div>
    )
  }
}

export default MediaQueries(WarrantCheckForm)
