import React, { Component } from 'react'
import I18n from '../i18n/index.js'

class ResizeableTextArea extends Component{
  constructor(props) {
    super(props)
  }

  resizeArea(area) {
    area.style.height = '100px'
    area.style.height = (25 + area.scrollHeight) + 'px'
  }

  onFocusExpand(e) {
    e.style.height = (25 + e.scrollHeight) + 'px'
  }

  render() {
    this.resizeArea = this.resizeArea.bind(this)
    this.onFocusExpand = this.onFocusExpand.bind(this)
    return(
      <div className="form-group">
        <label className="control-label string">{this.props.label}</label>
        <textarea
          id="cms_faq_answer"
          className="form-control text text-box"
          required="required"
          name={this.props.name}
          onKeyUp={ (e) => this.resizeArea(e.target) }
          defaultValue={this.props.value || ""}
          onFocus={ (e) => this.onFocusExpand(e.target) }
        />
      </div>
    )
  }
}

export default ResizeableTextArea;
