import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import { t } from 'i18n'
import axios from 'axios'
import map from 'lodash/map'
import { ActivityRow } from './ActivityRow'

class ActivitiesTable extends Component {
  state = {
    pageNumber: 1,
    activities: [],
    recordCount: 0,
    loading: true
  }

  componentWillMount() {
    this.fetchMoreActivities()
  }

  fetchMoreActivities() {
    const { activities, pageNumber } = this.state

    this.setState({ loading: true })

    axios.get('/user/activities.json', { params: { page_number: pageNumber } })
      .then(response => {
        const data = response.data

        if (data.activities.length > 0) {
          this.setState({
            activities: activities.concat(data.activities),
            pageNumber: pageNumber + 1,
            recordCount: data.record_count
          })
        }

        this.setState({ loading: false })
      })
  }

  renderLoadMoreButton() {
    const { loading, activities, recordCount } = this.state

    if (loading) {
      return <div className='loading-indicator'></div>
    }

    if (recordCount > activities.length) {
      return (
        <div className='btn main-page-button' onClick={this.fetchMoreActivities.bind(this)}>
          {t('user.activities.index.load_more')}
        </div>
      )
    }
  }

  renderActivitiesTable() {
    const { activities } = this.state

    return(
      <div className='table-responsive'>
        <table className='table table-hover admin-table appointments-table mb-5'>
          <thead>
            <tr>
              <th>{t('user.activities.index.date')}</th>
              <th>{t('user.activities.index.number')}</th>
              <th>{t('user.activities.index.notary_name')}</th>
              <th className='w-50'>{t('user.activities.index.act_description')}</th>
              <th>{t('user.activities.index.documents')}</th>
            </tr>
          </thead>
          <tbody>
            {map(activities, activity =>
              <ActivityRow
                key={activity.nis_uid}
                activity={activity}
              />
            )}
          </tbody>
        </table>
      </div>
    )
  }

  renderPlaceholderText() {
    const mapLink = ReactDOMServer.renderToStaticMarkup(<a href='/notaries_map'>{t('user.activities.index.map_link')}</a>)
    return(
      <div className='activities-placeholder-text'>
        <span dangerouslySetInnerHTML={{__html: t('user.activities.index.no_activities_placeholder_html', { personal_code: this.props.personalCode, map_link: mapLink })}}></span>
      </div>
    )
  }

  render() {
    const { loading, activities } = this.state
    return(
      <div>
      {!loading && activities.length == 0 ? this.renderPlaceholderText() : this.renderActivitiesTable()}
      <div className='row bottom-margin'>
        <div className='text-center col-12'>
          {this.renderLoadMoreButton()}
        </div>
      </div>
      </div>
    )
  }
}

export default ActivitiesTable
