import React, { Component } from 'react'
import NotaryAvailabilityIndicator from '../NotaryAvailabilityIndicator'
import { stringifiedTimeInterval } from '../../files/helpers'
import { t } from 'i18n'

export default class NotaryListItem extends Component {
  handleListItemClick = () => {
    this.props.onClick(this.props.notary)
  }

  renderWorkingTime() {
    const { active, working_time_today, closest_absence } = this.props.notary

    if(!active) {
      return(
        <div className='working-times'>
          {t('notaries_map.index.not_practicing')}
        </div>
      )
    }

    if(closest_absence) { return }

    if(working_time_today) {
      return (
        <div className='working-times'>
          {t('notaries_map.index.today_working') + ': '}
          {stringifiedTimeInterval(working_time_today.time_from, working_time_today.time_until)}
        </div>
      )
    }

    return <div className='working-times'>{t('notaries.show.today_not_working')}</div>
  }

  notaryAbsence() {
    const { absence_interval, absence_replacement_link } = this.props.notary
    if(absence_interval) {
      return (
        <div className='absence-container'>
          <div className='absence'>
            {absence_interval}
          </div>
          <div className='replacement' dangerouslySetInnerHTML={{__html: absence_replacement_link}}>
          </div>
        </div>
      )
    }
  }

  renderNotaryAddressAndActions() {
    const { notary } = this.props
    if(notary.active){
      const placeOfPractice = notary.places_of_practice.find((pop) => pop.primary)
      const address = placeOfPractice ? placeOfPractice.address : ""
      return(
        <div>
          <div className='address'>
            <i className='fa fa-location-arrow' />
            {address}
          </div>
          <div className='absence-side-container'>
            {this.notaryAbsence()}
          </div>
          <div className='notary-icons contacts d-print-none'>
            <i className='fa fa-mobile' />
            <i className='fa fa-at' />
          </div>
          <div className='d-none d-print-block'>
            {notary.office_number &&
              <div className='text-nowrap'>
                <i className='fa fa-fw fa-mobile mr-2' />
                <span>{notary.office_number}</span>
              </div>
            }
            {notary.extra_number &&
              <div className='text-nowrap'>
                <i className='fa fa-fw fa-mobile mr-2' />
                <span>{notary.extra_number}</span>
              </div>
            }
            <div className='text-nowrap'>
              <i className='fa fa-fw fa-at mr-2' />
              <span>{notary.email}</span>
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    const { notary } = this.props

    return (
      <li id={"notary_" + notary.id} key={notary.id} className='notary-container pointer' onClick={this.handleListItemClick}>
        <div className='notary-image-icon'>
          <img className='notary-profile-image' src={notary.image_url} />
          <NotaryAvailabilityIndicator notaryId={notary.id} onlineOperations={notary.online_operations} hideTooltipArrow />
        </div>
        <div className='notary-info'>
          <div className='container'>
            <h6>{notary.full_name}</h6>
            <p className='county-court mb-0'>{notary.county_court}</p>
            {this.renderWorkingTime()}
            {this.renderNotaryAddressAndActions()}
          </div>
        </div>
        <div className='notary-profile-link'>
          <a href='#' id={'side-notary-' + notary.id}>
            <i data-action="open_notary" className='fa fa-chevron-right open-notary' />
          </a>
        </div>
      </li>
    )
  }
}
