import React, { Component } from 'react'
import ReactDOM from 'react-dom'

const NOTICE_TIMEOUT = 5000

class Notice extends Component {
  render() {
    const { error, text } = this.props
    if (!text) return null

    let noticeClass = error ? 'alert-danger' : 'alert-success'

    return ReactDOM.createPortal(
      <div className={'alert alert-dismissable ' + noticeClass}>
        <a aria-label='close' className='close' data-dismiss='alert' href='#' onClick={(e) => e.preventDefault()} />
        {text}
      </div>,
      document.getElementById('notification')
    )
  }
}

const showNotice = function(text, success = true) {
  this.setState({ noticeText: text, noticeError: !success }, () => {
    setTimeout(() => this.setState({ noticeText: null, noticeError: null }), NOTICE_TIMEOUT)
  })
}

class StaticNotice extends Component {
  state = { noticeText: null }

  constructor(props) {
    super(props)
    this.showNotice = showNotice.bind(this)
  }

  componentDidMount() {
    this.showNotice(this.props.text)
  }

  render() {
    return <Notice {...this.props} text={this.state.noticeText} />
  }
}

export default Notice
export { showNotice, StaticNotice }
