import React from 'react'
import map from 'lodash/map'
import axios from 'axios'
import { t } from 'i18n'

const ProjectItemTab = ({ projectItem, onClick, isSelected, canDestroy, destroyProjectItem }) => {
  const auarNumber = projectItem.auarNumber

  const label = auarNumber && auarNumber.length > 0
    ? t('components.project.sidebar_steps.auar_no', { auar_number: projectItem.auarNumber })
    : t('components.project.sidebar_steps.new_act')

  return (
    <div className={`project-item-tab ${isSelected ? 'active' : ''}`} onClick={onClick}>
      {label}
      {isSelected && canDestroy &&
        <i className='fa fa-times destroy-project-item' onClick={() => destroyProjectItem(projectItem)} />
      }
    </div>
  )
}

const ProjectItemTabs = ({ project, onSelect, selectedIndex, isNotary, subscription }) => {
  const createNewProjectItem = () => {
    if (!confirm(t('components.project.sidebar_steps.create_project_item_prompt'))) return

    axios.post(`/projects/${project.id}/project_items`)
      .then(() => subscription.send({}))
      .catch()
  }

  const destroyProjectItem = ({ id, projectId }) => {
    if (!confirm(t('components.project.sidebar_steps.destroy_project_item_prompt'))) return

    axios.delete(`/projects/${projectId}/project_items/${id}`)
      .then(() => subscription.send({}))
      .catch()
  }

  const projectItemCount = project.projectItems.length
  const canSelectPrev = selectedIndex > 0
  const canSelectNext = selectedIndex < projectItemCount - 1

  return (
    <div className='project-item-tabs-wrapper'>
      <div
        className={`tab-btn previous-tab-btn ${canSelectPrev ? '' : 'disabled'} ${projectItemCount < 2 ? 'hidden' : ''}`}
        onClick={() => onSelect(selectedIndex - 1)}
      >
        <i className='fa fa-chevron-left' />
      </div>

      <div className='project-item-tabs-list'>
        {map(project.projectItems, (item, index) =>
          <ProjectItemTab
            key={item.id}
            projectItem={item}
            isSelected={selectedIndex == index}
            onClick={() => onSelect(index)}
            isNotary={isNotary}
            canDestroy={isNotary && projectItemCount > 1}
            destroyProjectItem={destroyProjectItem}
          />
        )}
      </div>
      <div className='flex-1'>
        {isNotary &&
          <div
            className='tab-btn new-tab-btn d-inline-block pointer'
            onClick={createNewProjectItem}
            title={t('components.project.sidebar_steps.create_project_item')}
            data-action='create-project-item'
          >
            <i className='fa fa-plus' />
          </div>
        }
      </div>
      <div
        className={`tab-btn next-tab-btn ${canSelectNext ? '' : 'disabled'} ${projectItemCount < 2 ? 'hidden' : ''}`}
        onClick={() => onSelect(selectedIndex + 1)}
      >
        <i className='fa fa-chevron-right' />
      </div>
    </div>
  )
}

export const scrollTabIntoView = (index) => {
  document.querySelector(`.project-item-tabs-list .project-item-tab:nth-child(${index + 1})`)
    .scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
}

export default ProjectItemTabs
