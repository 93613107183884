import React from 'react'
import axios from 'axios'
import { t } from 'i18n'

const AsapAppointmentNotification = ({ notifiable_id, payload }) => {
  const confirm = () => {
    axios.put(`/notary/appointments/${notifiable_id}/confirm`, {
      appointment: { duration_minutes: 30 }
    }).then(({ data }) => {
      window.location = `/projects/${data.project_id}`
    })
  }

  const cancel = () => {
    axios.put(`/appointments/${notifiable_id}/cancel.json`)
  }

  return (
    <div className='notification asap-appointment-notification'>
      <div className='notification-content'>
        <div className='d-inline-block'>
          <img className='d-block notification-image mr-3' src={payload.client_image_url}/>
        </div>
        <div className='d-inline-block'>
          <p className='mb-2 font-weight-bold'>{t('components.asap_appointment_notification.title')}</p>
          <p className='mb-0 semi-opaque'>{payload.client_full_name}</p>
          <p className='mb-0 semi-opaque'>{payload.client_personal_code}</p>
        </div>
      </div>
      <div className='buttons text-center mt-3'>
        <button className='btn btn-primary' onClick={confirm}>
          {t('components.asap_appointment_notification.accept')}
        </button>
        <button className='btn btn-secondary' onClick={cancel}>
          {t('components.asap_appointment_notification.cancel')}
        </button>
      </div>
    </div>
  )
}

export default AsapAppointmentNotification
