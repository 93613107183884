import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'

class HintIcon extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  render() {
    const { faIcon, className, placement, children, label } = this.props
    const template = '<div class="tooltip hint-icon" role="tooltip">' +
                       '<div class="arrow"></div>' +
                       '<div class="tooltip-inner"></div>' +
                     '</div>'

    return (
      <span
        className={className}
        data-toggle='tooltip'
        data-html='true'
        data-placement={placement || 'bottom'}
        data-template={template}
        title={ReactDOMServer.renderToStaticMarkup(children)}
      >
        <i className={`fa fa-${faIcon} mr-0`} />
        {label && <span className='ml-2'>{label}</span>}
      </span>
    )
  }
}

export default HintIcon
