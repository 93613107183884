export const NOT_STARTED = 'not_started'
export const STARTED = 'started'
export const SUBMITTED = 'submitted'
export const RESUBMISSION_REQUESTED = 'resubmission_requested'
export const EXPIRED = 'expired'
export const DECLINED = 'declined'
export const ABANDONED = 'abandoned'
export const APPROVED = 'approved'

export const PENDING_STATUSES = [
  NOT_STARTED,
  STARTED,
  SUBMITTED,
]

export const FAILED_STATUSES = [
  EXPIRED,
  DECLINED,
  ABANDONED,
]

export const FINISHED_STATUSES = [
  RESUBMISSION_REQUESTED,
  EXPIRED,
  DECLINED,
  ABANDONED,
  APPROVED,
]
