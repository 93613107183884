import React, { Component } from 'react'
import Select from 'react-select'
import InputWrapper from '../utilityComponents/InputWrapper'

class IconSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value || ''
    }
    this.handleOnChange = this.handleOnChange.bind(this)
  }

  options() {
    return this.props.icons.map(o => { return { label: o, value: o }})
  }

  handleOnChange(obj) {
    const value = obj ? obj.value : ''
    this.setState({ value })
  }

  optionTemplate(a) {
    return(
      <div>
        <i className={'fa ' + a.value}></i>
        {' ' + a.value}
      </div>
    )
  }

  render () {
    const { label, name, errors } = this.props
    const { value } = this.state
    return (
      <InputWrapper errorArray={errors}>
        <label className="control-label">{label}</label>
        <Select
          className='icon-select'
          options={this.options()}
          onChange={this.handleOnChange}
          value={value}
          optionRenderer={this.optionTemplate}
        />
        <input type="hidden" name={name} value={value} />
      </InputWrapper>
    )
  }
}

export default IconSelect
