import React, { Component, Fragment } from 'react'
import { t } from 'i18n'
import InputWrapper from './InputWrapper'

class FileInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      file: null,
      errors: props.errors
    }
  }

  handleDocumentChange = (e, input) => {
    const { max_size, max_size_message } = this.props
    const file = e.target.files[0]

    if (file.size <= max_size) {
      this.setState({ file, errors: [] })
    } else {
      input.value = null
      this.setState({ file: null, errors: [max_size_message] })
    }
  }

  handleDocumentRemoval = (e, input) => {
    e.preventDefault()
    input.value = null

    this.setState({
      file: null
    })
  }

  render() {
    const { file, errors } = this.state
    let { id, name, placeholder, accept, locale } = this.props

    id          = id || 'file'
    name        = name || 'file'
    placeholder = placeholder || t('file_placeholder', { locale: locale })

    const text = file ? file.name : placeholder
    const textClass = file ? 'value' : 'value placeholder'

    return (
      <InputWrapper errorArray={errors}>
        <input
          type='file'
          id={id}
          name={name}
          className='d-none'
          accept={accept}
          onChange={(e) => this.handleDocumentChange(e, this.input)}
          ref={input => this.input = input}
        />

        <label htmlFor={id} className='form-control signed-document-label'>
          <i className='fa fa-upload mr-2' />

          <span className={textClass}>
            {text}
            {file && <i title={t('delete')} className='fa fa-times pull-right remove-document' onClick={(e) => this.handleDocumentRemoval(e, this.input)} />}
          </span>

        </label>
      </InputWrapper>
    )
  }
}

export default FileInput
