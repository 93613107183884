import React, { Component } from 'react'
import Modal from 'react-modal'
import DatePicker from 'react-datepicker'
import TimePicker from 'rc-time-picker'
import { AsyncPaginate } from 'react-select-async-paginate'
import moment from 'moment'
import axios from 'axios'
import { t, l, locale } from 'i18n'
import ReactTooltip from 'rc-tooltip'
import EditAppointmentClientsModal from './EditAppointmentClientsModal'
import InputWrapper from '../utilityComponents/InputWrapper'
import { renderAddressBlock,
  handleTimeInputChange,
  handlePlaceOfPracticeChange,
  selectedWorkingTimeOptions,
  changedAppointment,
  handleDateChange
} from '../appointments/AppointmentFunctions'

const RESCHEDULE_FORM = 'reschedule'
const TRANSFER_FORM = 'transfer'

class AppointmentModal extends Component {
  constructor(props) {
    super(props)

    this.newAppointmentUser = {
      user_obj: '',
      user_id: '',
      user_email: '',
      user_full_name: '',
      user_phone_number: ''
    }

    this.state = {
      newAppointmentUser: {...this.newAppointmentUser},
      selectedPlaceOfPractice: props.placesOfPractice.find( (pop) => pop.address == props.appointment.address),
      durationMinutes: props.appointment.duration_minutes,
      errors: {},
      appointment: {
        id: props.appointment.id,
        date: moment(props.appointment.date),
        time: moment(props.appointment.time),
        duration_minutes: props.appointment.duration_minutes,
        address: props.appointment.address,
        appointment_users_attributes: props.appointment.appointment_users
      },
      subjectOpened: false,
      openedForm: RESCHEDULE_FORM,
      transferNotary: null,
      editingClients: false
    }

    // MIXIN functions
    this.renderAddressBlock = renderAddressBlock.bind(this)
    this.handleTimeInputChange = handleTimeInputChange.bind(this)
    this.handlePlaceOfPracticeChange = handlePlaceOfPracticeChange.bind(this)
    this.selectedWorkingTimeOptions = selectedWorkingTimeOptions.bind(this)
    this.changedAppointment = changedAppointment.bind(this)
    this.handleDateChange = handleDateChange.bind(this)
  }

  async fetchUsers(input, currentOptions) {
    const { data } = await axios.get('/notary/users.json', {
      params: { q: input, offset: currentOptions.length }
    })

    return {
      options: data.users,
      hasMore: data.has_more
    }
  }

  async fetchNotaries(input, existingNotaries) {
    const { data } = await axios.get('/notaries/search.json', {
      params: { q: input, offset: existingNotaries.length, active: true }
    })

    return {
      options: data.notaries,
      hasMore: data.has_more
    }
  }

  handleInputChange = ({ target: { value, name } }) => {
    this.setState({ [name]: value })
  }

  handleAppointmentInputChange = ({ target: { value, name } }) => {
    this.setState({
      appointment: this.changedAppointment({ [name]: value })
    })
  }

  renderDateErrors() {
    if (this.selectedWorkingTimeOptions().length < 1) {
      return [t('components.appointments.profile_appointment_form.notary_not_working')]
    } else {
      return this.state.errors.date
    }
  }

  updateAppointment = () => {
    const { appointment } = this.state

    axios.put(this.props.updateUrl, {
      appointment: {
        ...appointment,
        // Make sure that only date is passed in params, not Datetime
        date: appointment.date.format(t('moment_date.formats.default')),
        time: appointment.time && appointment.time.format('H:mm')
      }
    })
    .then(({ data }) => this.props.afterSave(data))
    .catch(({ response }) => this.setState({ errors: response.data }))
  }

  transferAppointment = () => {
    const { transferNotary } = this.state

    axios.put(`${this.props.updateUrl}/transfer`, {
      appointment: { notary_id: transferNotary.value }
    })
    .then(() => {
      this.props.removeSelectedAppointment()
      this.props.showNotice(
        t('components.appointments_calendar.appointment_transfered', {
          notary_name: transferNotary.label
        })
      )
    })
    .catch(({ response }) => this.setState({ errors: response.data }))
  }

  handleAppointmentTimeChange = (time) => {
    this.setState({
      appointment: this.changedAppointment({ time })
    })
  }

  cancelAppointment = (e) => {
    e.preventDefault()
    const { appointment } = this.props
    if(confirm(t('components.appointment_modal.cancel_confirmation'))) {
      axios({
        method: 'PUT',
        url: `/appointments/${appointment.id}/cancel.json`
      })
      .then(() => this.props.afterCancel(appointment) )
    }
  }

  confirmAppointment = (e) => {
    e.preventDefault()
    const { updateUrl } = this.props
    axios({
      method: 'PUT',
      url: updateUrl + '/confirm',
      data: {
        appointment: {
          duration_minutes: this.state.durationMinutes
        }
      }
    })
    .then(({ data }) => this.props.afterConfirm(data) )
  }

  toggleSubject() {
    this.setState({
      subjectOpened: !this.state.subjectOpened
    })
  }

  renderSubject() {
    const { subject } = this.props.appointment
    const { subjectOpened } = this.state

    if (subject && subject.length > 120) {
      const subjectText = subjectOpened ? subject : subject.slice(0,120) + "..."
      const subjectToggle = subjectOpened ? t('components.appointment_modal.less') : t('components.appointment_modal.more')
      return (
        <div className='info-content'>
          <span>{subjectText}</span>
          <span className='read-more' onClick={this.toggleSubject.bind(this)}>{subjectToggle}</span>
        </div>
      )
    } else {
      return (<div className='info-content'>{subject}</div>)
    }
  }

  renderProjectTableButton() {
    const { appointment } = this.props

    if (appointment.project_url) {
      return (
        <div className='text-center mt-3'>
          <a className='btn blue-modal-btn' href={appointment.project_url}>
            {t('components.appointment_modal.project_table')}
          </a>
        </div>
      )
    }
  }

  renderMessengerButton() {
    const { appointment } = this.props

    if (appointment.client_id) {
      return (
        <a className='btn blue-modal-btn mt-3' href={'/messenger?user_id=' + appointment.client_id}>
          {t('components.appointment_modal.contact_client')}
        </a>
      )
    }
  }

  clientHasUserAssigned = (appointmentUser) => {
    return !!appointmentUser.user_id
  }

  renderAppointmentUser(appointmentUser) {
    return (
      <div key={appointmentUser.id} className='mb-2'>
        <span>{appointmentUser.user_full_name}</span>
        <span className='contact-info'>
          {`(${appointmentUser.user_email}, ${appointmentUser.user_phone_number})`}
        </span>
        {!this.clientHasUserAssigned(appointmentUser) &&
          <ReactTooltip
            placement='top'
            overlay={t('components.appointment_modal.client_not_registered')}
          >
            <i className='fa fa-exclamation-circle'/>
          </ReactTooltip>
        }
      </div>
    )
  }

  renderAppointmentInfo() {
    const { appointment } = this.props
    const canManageUsers = !appointment.canceled

    return (
      <div className='appointment-info'>
        <div className='info-item'>
          <div className='info-label'>
            {t('activerecord.attributes.appointment.datetime') + ':'}
          </div>
          <div className='info-content'>
            {l('time.formats.long', appointment.time_from)}
          </div>
        </div>
        <div className='info-item'>
          <div className='info-label'>
            {t('activerecord.attributes.appointment.duration_minutes') + ':'}
          </div>
          <div className='info-content'>
            {this.renderAppointmentDuration()}
          </div>
        </div>
        <div className='info-item'>
          <div className='info-label'>
            {t('activerecord.attributes.appointment.subject') + ':'}
          </div>
          {this.renderSubject()}
        </div>
        <div className='info-item mb-2'>
          <div className='info-label'>
            {t('activerecord.attributes.appointment.clients') + ':'}
            {canManageUsers &&
              <ReactTooltip
                placement='top'
                overlay={t('components.appointment_modal.edit_clients')}
              >
                <i
                  className="fa fa-pencil-square-o edit-clients"
                  aria-hidden="true"
                  onClick={() => this.setState({ editingClients: true })}
                />
              </ReactTooltip>
            }
          </div>
          <div className='info-content'>
            {appointment.appointment_users.map(user =>
              this.renderAppointmentUser(user)
            )}
          </div>
        </div>
        <div className='text-center'>
          {this.renderConfirmAppointmentButton()}
        </div>
        <div className='text-center'>
          {this.renderMessengerButton()}
        </div>
        {this.renderProjectTableButton()}
      </div>
    )
  }

  renderAppointmentDuration() {
    const { appointment } = this.props

    if (!appointment.confirmed && !appointment.canceled && !appointment.passed) {
      return (
        <input type='number'
          name='durationMinutes'
          value={this.state.durationMinutes}
          onChange={this.handleInputChange.bind(this)}
          className='underline'
        />
      )
    } else {
      return appointment.duration_minutes
    }
  }

  renderConfirmAppointmentButton() {
    const { appointment: { confirmed, canceled, passed } } = this.props
    if (!confirmed && !canceled && !passed) {
      return (
        <a href='#' className='btn blue-modal-btn blue-modal-btn-inverse' onClick={this.confirmAppointment}>
          <i className="fa fa-check mr-2"></i>
          {t('components.appointment_modal.confirm_appointment')}
        </a>
      )
    }
  }

  renderCancelAppointmentButton() {
    if(!this.props.appointment.canceled) {
      return (
        <div className='text-center mt-3'>
          <a href='#' className='blue-modal-cancel' onClick={this.cancelAppointment}>
            <i className="fa fa-times mr-2"></i>
            {t('components.appointment_modal.cancel_appointment')}
          </a>
        </div>
      )
    }
  }

  renderAppointmentReschedulingForm() {
    const { appointment, errors } = this.state

    return (
      <div>
        <div className="form-group">
          {this.renderAddressBlock()}
        </div>
        <div className='inputs-holder'>
          <label className='input-label'>{t('activerecord.attributes.appointment.date')}</label>
          <InputWrapper className='datepicker' errorArray={this.renderDateErrors()}>
            <DatePicker
              className='form-control'
              selected={appointment.date}
              onChange={this.handleDateChange}
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              dateFormat={t('date.formats.moment')}
              todayButton={t('components.datepicker.today')}
              locale={locale}
            />
          </InputWrapper>
          <InputWrapper className='time-input' errorArray={errors.time}>
            <label className='input-label'>{t('activerecord.attributes.appointment.time')}</label>
            <TimePicker
              value={appointment.time}
              onChange={this.handleAppointmentTimeChange}
              className='appointment-time time-picker-light'
              showSecond={false}
              allowEmpty={false}
              format='H.mm'
            />
          </InputWrapper>
          <InputWrapper className='input-group-sm' errorArray={errors.duration}>
            <label className='input-label' htmlFor='duration_minutes'>
              {t('activerecord.attributes.appointment.duration_minutes')}
            </label>
            <input
              type='number'
              name='duration_minutes'
              value={appointment.duration_minutes}
              onChange={this.handleAppointmentInputChange}
              className='form-control input-sm'
            />
          </InputWrapper>
          <div className='text-center'>
            <a href='#' className='btn blue-modal-btn' onClick={this.updateAppointment}>
              {t('save')}
            </a>
          </div>
        </div>
      </div>
    )
  }

  handleAccordionClick(formName) {
    this.setState({
      openedForm: this.state.openedForm !== formName ? formName : null // close if already open
    })
  }

  renderRescheduleSection = () => {
    const reschedulingFormOpened = this.state.openedForm === RESCHEDULE_FORM

    return (
      <div className="card">
        <div className="card-header" id="reschedule-heading">
          <h2 className="mb-0">
            <button
              className="btn btn-block text-left accordion-button reschedule"
              type="button" data-toggle="collapse"
              data-target="#reschedule"
              aria-expanded="true"
              aria-controls="reschedule"
              onClick={() => this.handleAccordionClick(RESCHEDULE_FORM)}
            >
              <i className={`fa fa-fw fa-chevron-${reschedulingFormOpened ? 'down' : 'right'}`} />
              {t('components.user_appointments.reschedule')}
            </button>
          </h2>
        </div>
        <div
          id="reschedule"
          className="collapse accordion-body show"
          aria-labelledby="reschedule-heading"
          data-parent="#accordion"
        >
          <div className="card-body">
            {this.renderAppointmentReschedulingForm()}
          </div>
        </div>
      </div>
    )
  }

  renderNotaryTransferSection = () => {
    const { openedForm, transferNotary } = this.state
    const transferingFormOpened = openedForm === TRANSFER_FORM

    return (
      <div className="card">
        <div className="card-header" id="transfer-heading">
          <h2 className="mb-0">
            <button
              className="btn btn-block text-left accordion-button transfer"
              type="button"
              data-toggle="collapse"
              data-target="#transfer"
              aria-expanded="true"
              aria-controls="transfer"
              onClick={() => this.handleAccordionClick(TRANSFER_FORM)}
            >
              <i className={`fa fa-fw fa-chevron-${transferingFormOpened ? 'down' : 'right'}`} />
              {t('components.user_appointments.transfer')}
            </button>
          </h2>
        </div>

        <div
          id="transfer"
          className="collapse accordion-body"
          aria-labelledby="transferHeading"
          data-parent="#accordion"
        >
          <div className="card-body">
            <AsyncPaginate
              classNamePrefix='react-select transfer-select'
              className='react-select'
              name='notary_id'
              loadOptions={this.fetchNotaries}
              value={transferNotary}
              placeholder={t('components.warrant_check.sworn_notary_placeholder')}
              onChange={notary => this.setState({ transferNotary: notary })}
            />
            <br/>
            <div className='text-center'>
              <a href='#'
                className='btn blue-modal-btn'
                onClick={this.transferAppointment}
              >
                {t('save')}
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderAppointmentAccordion() {
    if (this.props.appointment.canceled) return

    return (
      <div className="accordion appointment-accordion show" id="accordion">
        {this.renderRescheduleSection()}
        {this.renderNotaryTransferSection()}
      </div>
    )
  }

  render() {
    const { appointment, afterUsersSave, updateUrl, closeModal } = this.props
    const { editingClients } = this.state

    return (
      <div>
        <Modal isOpen={!editingClients} className="modal-md blue-modal appointment-modal">
          <span className="modal-close" onClick={closeModal}>
            <i className="fa fa-close fa-lg" />
          </span>
          {this.renderAppointmentInfo()}
          {this.renderCancelAppointmentButton()}
          {this.renderAppointmentAccordion()}
        </Modal>
        {editingClients &&
          <EditAppointmentClientsModal
            appointmentId={appointment.id}
            initialAppointmentUsers={appointment.appointment_users}
            afterSave={afterUsersSave}
            closeModal={() => { this.setState({ editingClients: false }) }}
            updateUrl={updateUrl}
          />
        }
      </div>
    )
  }
}

export default AppointmentModal
