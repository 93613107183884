import React from 'react'

const TimeOptions = ({ timeOptions, selectOption, selectedOption }) => {

  const optionSelected = (option) => {
    return option.value === selectedOption
  }

  const selectTimeOption = (option) => {
    if(option.isDisabled) return
    selectOption(option)
  }

  const timeOption = (option) => {
    if (option.value) {
      return (
        <div
          key={option.value}
          className={`option ${option.isDisabled ? 'disabled' : ''} ${optionSelected(option) ? 'selected' : ''}`}
          onClick={() => selectTimeOption(option)}>
          <span>{option.label}</span>
        </div>
      )
    }
  }

  return (
    <div className='time-options-wrapper'>
      <div className='time-options'>
        {timeOptions.map(option => timeOption(option))}
      </div>
    </div>
  )
}

export default TimeOptions
