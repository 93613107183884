import React, { Component } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import axios from 'axios'
import moment from 'moment'
import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import map from 'lodash/map'
import { t } from 'i18n'
import classNames from 'classnames'
import CalendarAppointmentModal from './CalendarAppointmentModal'
import AbsenceModal from './AbsenceModal'
import NewAppointmentModal from './NewAppointmentModal'
import Notice, { showNotice } from '../utilityComponents/Notice'

const localizer = momentLocalizer(moment)

const REFRESH_TIMEOUT = 60 * 1000
const TYPE_ABSENCE = 'absence'
const TYPE_APPOINTMENT = 'appointment'

class AppointmentsCalendar extends Component {
  constructor(props) {
    super(props)

    this.defaultCalendarView = 'week'
    this.defaultCalendarDate = new Date()

    this.state = {
      view: this.defaultCalendarView,
      date: this.defaultCalendarDate,
      absenceEvents: [],
      appointmentEvents: [],
      newAppointmentModalActive: false,
      selectedAppointmentId: null,
      selectedAbsence: null,
      absenceModalActive: false,
      showNightTimes: false,
      noticeText: null
    }

    this.showNotice = showNotice.bind(this)
  }

  componentDidMount() {
    this.pollAbsences()
    this.pollAppointments()
  }

  clearSelectedAppointment = () => {
    this.setState({ selectedAppointmentId: null })
  }

  selectAbsence = (event) => {
    const absence = this.state.absenceEvents.find(e => e.id === event.id)
    this.toggleAbsenceModal(true, absence)
  }

  selectAppointment = (appointment) => {
    this.setState({ selectedAppointmentId: appointment.id })
  }

  pollAbsences = () => {
    this.fetchAbsences(() =>
      setTimeout(
        () => requestAnimationFrame(() => this.pollAbsences()), // poll only when browser tab is opened
        REFRESH_TIMEOUT
      )
    )
  }

  pollAppointments = (updatedOnly = false) => {
    this.fetchAppointments(updatedOnly, () =>
      setTimeout(
        () => requestAnimationFrame(() => this.pollAppointments(true)), // poll only when browser tab is opened
        REFRESH_TIMEOUT
      )
    )
  }

  fetchEvents = () => {
    this.fetchAbsences()
    this.fetchAppointments()
  }

  fetchAbsences = (callback = () => {}) => {
    const { date, view } = this.state

    axios.get(
      '/notary/absences', { params: { date, view } }
    ).then(({ data }) =>
      this.replaceEvents('absenceEvents', data)
    ).finally(callback)
  }

  fetchAppointments = (updatedOnly = false, callback = () => {}) => {
    const { date, view, appointmentEvents } = this.state

    let latestUpdate
    if (updatedOnly) {
      latestUpdate = map(appointmentEvents, e => e.updated_at).sort().reverse()[0]
    }
    axios.get(
      '/notary/appointments.json', { params: { date, view, updated_later_than: latestUpdate } }
    ).then(
      ({ data }) => this.updateOrAddEvents('appointmentEvents', data)
    ).finally(callback)
  }

  eventTitle = (eventType, event) => {
    switch(eventType) {
      case TYPE_ABSENCE:
        if (event.imported_from_google_calendar) {
          return (
            <>
              <span>{t('activerecord.models.notaries/absence')}</span>
              <i className='event-icon fa fa-google' />
            </>
          )
        } else if (event.imported_from_nis) {
          return (
            <>
              <span>{t('activerecord.models.notaries/absence')}</span>
              <span className='event-icon'>NIS</span>
            </>
          )
        } else {
          return event.note?.trim() || t('components.appointments_calendar.busy')
        }
      case TYPE_APPOINTMENT:
        return event.appointment_users.map(user => user.user_full_name).join(', ')
    }
  }

  scopeToEventType = (eventsType) => {
    switch(eventsType) {
      case 'absenceEvents': return TYPE_ABSENCE
      case 'appointmentEvents': return TYPE_APPOINTMENT
    }
  }

  replaceEvents = (eventsType, events) => {
    const eventType = this.scopeToEventType(eventsType)
    const newEvents = map(events, event => this.parsedCalendarEvent(event, eventType))

    this.setState({ [eventsType]: newEvents })
  }

  updateOrAddEvents = (eventsType, events) => {
    let loadedEvents = this.state[eventsType]

    events.forEach(event => {
      const eventType = this.scopeToEventType(eventsType)
      const calendarEvent = this.parsedCalendarEvent(event, eventType)

      const eventIndex = findIndex(loadedEvents, e => e.id == calendarEvent.id)
      if (eventIndex > -1) {
        loadedEvents.splice(eventIndex, 1, calendarEvent)
      } else {
        loadedEvents.push(calendarEvent)
      }
    })
    this.setState({ [eventsType]: loadedEvents })
  }

  removeSelectedAppointment = () => {
    const { appointmentEvents, selectedAppointmentId } = this.state

    this.setState({
      selectedAppointmentId: null,
      appointmentEvents: filter(appointmentEvents, appointment =>
        appointment.id !== selectedAppointmentId
      )
    })
  }

  replaceAppointmentAndShowNotification = (appointment, text, keepAppointmentModal = false) => {
    if (!keepAppointmentModal) {
      this.setState({ selectedAppointmentId: null })
    }

    this.updateOrAddEvents('appointmentEvents', [appointment])
    this.showNotice(text)
    this.fetchAppointments(true)
  }

  handleAppointmentConfirm = (appointment) => {
    this.replaceAppointmentAndShowNotification(appointment, t('notary.appointments.confirm.confirmed'))
  }

  handleAppointmentSave = (appointment) => {
    this.replaceAppointmentAndShowNotification(appointment, t('appointments.update.rescheduled'))
  }

  handleAppointmentUsersSave = (appointment) => {
    this.replaceAppointmentAndShowNotification(
      appointment,
      t('appointments.update.appointment_users_saved'),
      true
    )
  }

  handleAbsenceSave = (absence) => {
    this.toggleAbsenceModal(false)
    this.updateOrAddEvents('absenceEvents', [absence])
    this.showNotice(t('components.appointments_calendar.absence_saved'))
  }

  handleAbsenceDestroy = (absence) => {
    this.toggleAbsenceModal(false)
    let absenceEvents = [ ...this.state.absenceEvents ]

    const absenceIndex = findIndex(absenceEvents, e => e.id === absence.id)
    if (absenceIndex > -1) {
      absenceEvents.splice(absenceIndex, 1)
    }

    this.setState({ absenceEvents })
    this.showNotice(t('components.appointments_calendar.absence_deleted'))
  }

  parsedCalendarEvent = (event, eventType) => {
    let parsedEvent = { ...event }

    parsedEvent.type = eventType
    parsedEvent.title = this.eventTitle(eventType, event)
    parsedEvent.time_from = new Date(event.time_from)
    parsedEvent.time_until = event.time_until ? new Date(event.time_until) : null

    // Display neverending events with no end time
    parsedEvent.calendar_time_until = event.time_until ? new Date(event.time_until) : new Date('9999-12-31')

    return parsedEvent
  }

  handleAppointmentCancel = (appointment) => {
    let { appointmentEvents } = this.state
    const appointmentIndex = appointmentEvents.findIndex(a => a.id == appointment.id)
    appointmentEvents.splice(appointmentIndex, 1)

    this.setState({ appointmentEvents, selectedAppointmentId: null })

    const noticeText = t('appointments.cancel.appointment_canceled', {
      name: appointment.user_full_names.join(', ')
    })
    this.showNotice(noticeText)
  }

  eventProps = (event) => {
    const classes = classNames({
      'canceled': event.canceled,
      'confirmed': event.confirmed,
      'unconfirmed': !event.confirmed && !event.canceled,
      'passed': event.passed,
      'finished': event.finished
    })

    return { className: `${event.type} ${classes}` }
  }

  renderReschedulingModal = () => {
    const { selectedAppointmentId, appointmentEvents } = this.state
    const selectedAppointment = find(appointmentEvents, appointment =>
      appointment.id === selectedAppointmentId
    )

    if (selectedAppointmentId) {
      return (
        <CalendarAppointmentModal
          appointment={selectedAppointment}
          closeModal={this.clearSelectedAppointment}
          placesOfPractice={this.props.notary.places_of_practice}
          afterSave={this.handleAppointmentSave}
          afterUsersSave={this.handleAppointmentUsersSave}
          afterCancel={this.handleAppointmentCancel}
          afterConfirm={this.handleAppointmentConfirm}
          updateUrl={`${this.props.updateUrl}/${selectedAppointmentId}`}
          removeSelectedAppointment={this.removeSelectedAppointment}
          showNotice={this.showNotice}
        />
      )
    }
  }

  addAppointment = () => {
    this.fetchAppointments(true)

    this.setState({ newAppointmentModalActive: false })
    const noticeText = t('components.appointments_calendar.appointment_saved')
    this.showNotice(noticeText)
  }

  renderNewAppointmentModal = () => {
    if (this.state.newAppointmentModalActive) {
      return (
        <NewAppointmentModal
          closeModal={this.toggleNewAppointmentModalActive.bind(this)}
          placesOfPractice={this.props.placesOfPractice}
          defaultDurationMinutes={this.props.defaultDurationMinutes}
          onSave={this.addAppointment.bind(this)}
        />
      )
    }
  }

  renderNightTimeButton = () => {
    const { showNightTimes } = this.state

    return (
      <a className='btn btn-default btn-dark ml-5' onClick={this.toggleShowNightTimes}>
        {t(`components.appointments_calendar.${showNightTimes ? 'hide_night_hours' : 'show_night_hours'}`)}
      </a>
    )
  }

  toggleShowNightTimes = () => {
    this.setState({ showNightTimes: !this.state.showNightTimes })
  }

  toggleNewAppointmentModalActive = () => {
    this.setState({ newAppointmentModalActive: !this.state.newAppointmentModalActive })
  }

  handleViewChange = (view) => {
    this.setState({ view }, this.fetchEvents)
  }

  handleNavigate = (date, view) => {
    this.setState({ date, view }, this.fetchEvents)
  }

  toggleAbsenceModal = (absenceModalActive, selectedAbsence = null) => {
    this.setState({ absenceModalActive, selectedAbsence })
  }

  visibleEvents = () => {
    const { absenceEvents, appointmentEvents } = this.state
    const visibleAppointmentEvents = filter(appointmentEvents, event => !event.canceled)

    return [...absenceEvents, ...visibleAppointmentEvents]
  }

  onSelectEvent = (event) => {
    switch(event.type) {
      case TYPE_ABSENCE: return this.selectAbsence(event)
      case TYPE_APPOINTMENT: return this.selectAppointment(event)
    }
  }

  render() {
    const { showNightTimes, noticeText, absenceModalActive, selectedAbsence } = this.state

    return (
      <div>
        <div className="mb-3 appointments-calendar-buttons">
          <div className='left'>
            <a href='#' className='btn btn-default new-appointment-btn mr-2' onClick={this.toggleNewAppointmentModalActive.bind(this)}>
              <i className='fa fa-plus mr-2'/>
              {t('components.appointments_calendar.new_appointment')}
            </a>
            <a href='#' className='btn btn-default btn-dark' onClick={() => this.toggleAbsenceModal(true)}>
              <i className='fa fa-plus mr-2'/>
              {t('components.appointments_calendar.new_absence')}
            </a>
          </div>
          <div className='right'>
            {this.renderNightTimeButton()}
          </div>
          {this.renderReschedulingModal()}
          {this.renderNewAppointmentModal()}
          <AbsenceModal
            key={selectedAbsence?.id}
            isOpen={absenceModalActive}
            absence={selectedAbsence}
            onSave={this.handleAbsenceSave}
            onDestroy={this.handleAbsenceDestroy}
            onClose={() => this.toggleAbsenceModal(false)}
          />
        </div>
        <Calendar
          events={this.visibleEvents()}
          defaultDate={this.defaultCalendarDate}
          defaultView={this.defaultCalendarView}
          views={['month', 'week', 'day']}
          startAccessor='time_from'
          endAccessor='calendar_time_until' // Display neverending events with no end time
          min={showNightTimes ? undefined : moment.utc('1995-12-17T06:00:00').toDate()}
          max={showNightTimes ? undefined : moment.utc('1995-12-17T21:00:00').toDate()}
          onSelectEvent={this.onSelectEvent}
          eventPropGetter={this.eventProps}
          onView={view => this.handleViewChange(view)}
          onNavigate={(date, view) => this.handleNavigate(date, view)}
          messages={t('components.appointments_calendar.defaults')}
          formats={{ dayFormat: 'dd DD/MM' }}
          localizer={localizer}
        />
        <Notice text={noticeText} />
      </div>
    )
  }
}

export default AppointmentsCalendar
