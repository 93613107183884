import React, { Component, Fragment } from 'react'
import axios from 'axios'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import find from 'lodash/find'
import { t, locale } from 'i18n'
import useNotaryAsapAvailabilityProvider from '../NotaryAsapAvailabilityProvider'
import InputWrapper from '../utilityComponents/InputWrapper'
import MediaQueries from '../utilityComponents/MediaQueries'
import { renderAddressBlock,
  handleTimeInputChange,
  handlePlaceOfPracticeChange,
  selectedWorkingTimeOptions,
  changedAppointment,
  handleDateBlur,
  timeOptions,
  fetchFutureUnavailabilityIntervals,
  isDateAvailable,
  workingWeekdays } from './AppointmentFunctions'
import TimeOptions from './TimeOptions'

class ProfileAppointmentForm extends Component {
  constructor(props) {
    super(props)

    const selectedPlaceOfPractice = find(props.placesOfPractice, pop => pop.active)
    const closestDate = selectedPlaceOfPractice.appointments_open_from_date

    this.state = {
      selectedPlaceOfPractice: selectedPlaceOfPractice,
      futureUnavailabilityIntervals: [],
      saved: false,
      errors: {},
      appointment: {
        asap_appointment: false,
        date: closestDate ? moment(closestDate) : null,
        raw_time: '',
        address: selectedPlaceOfPractice.address,
        place_of_practice_id: selectedPlaceOfPractice.id,
        subject: '',
        notary_id: props.notaryId,
        user_email: props.userEmail,
        user_phone_number: props.userPhoneNumber
      }
    }

    // MIXIN functions
    this.handleDateBlur = handleDateBlur.bind(this)
    this.handlePlaceOfPracticeChange = handlePlaceOfPracticeChange.bind(this)
    this.selectedWorkingTimeOptions = selectedWorkingTimeOptions.bind(this)
    this.renderAddressBlock = renderAddressBlock.bind(this)
    this.handleTimeInputChange = handleTimeInputChange.bind(this)
    this.changedAppointment = changedAppointment.bind(this)
    this.timeOptions = timeOptions.bind(this)
    this.fetchFutureUnavailabilityIntervals = fetchFutureUnavailabilityIntervals.bind(this)
    this.isDateAvailable = isDateAvailable.bind(this)
    this.workingWeekdays = workingWeekdays.bind(this)
  }

  componentDidMount() {
    this.fetchFutureUnavailabilityIntervals()
  }

  componentDidUpdate(prevProps) {
    this.handleNotaryAvailabilityUpdate(prevProps)
  }

  handleNotaryAvailabilityUpdate = ({ isReadyForAsapAppointment }) => {
    if (isReadyForAsapAppointment === this.props.isReadyForAsapAppointment) return
    const asapAppointment = this.props.isReadyForAsapAppointment ? this.state.appointment.asap_appointment : false

    this.setState({
      appointment: this.changedAppointment({ asap_appointment: asapAppointment })
    })
  }

  handleDateChange = (date) => {
    this.setState({
      appointment: this.changedAppointment({ date: date, raw_time: '' })
    })
  }

  handleInputChange = ({ target: { name, value } }) => {
    this.setState({
      appointment: this.changedAppointment({ [name]: value })
    })
  }

  handleCheckboxChange = ({ target: { name, checked } }) => {
    this.setState({
      appointment: this.changedAppointment({ [name]: checked })
    })
  }

  submitAppointment = () => {
    let { appointment } = this.state
    axios.post(
      '/appointments',
      {
        appointment: {
          ...appointment,
          // Make sure that only date is passed in params, not Datetime
          date: appointment.date.format(t('moment_date.formats.default')),
          time: appointment.raw_time && moment(appointment.raw_time, t('moment_time.formats.short')).format('H:mm')
        }
      }
    ).
      then( data => this.setState({ saved: true }) ).
      catch( req => this.setState({ errors: req.response.data }))
  }

  dayClassName = (date) =>  {
    return `date-${moment(date).format('YYYY-MM-DD')}`
  }

  renderForm() {
    const { isMobile, isReadyForAsapAppointment } = this.props
    const { selectedPlaceOfPractice, appointment, errors } = this.state

    const workingWeekdays = this.workingWeekdays(selectedPlaceOfPractice)
    const timeOptions = this.timeOptions()

    return (
      <div className='appointment-form'>
        <h5 className='mb-4'>{t('components.appointments.profile_appointment_form.apply_for_consultation')}</h5>
        <div className='consultation-container'>
          <div className='form-inline mb-2'>
            {isReadyForAsapAppointment &&
              <div className='d-flex w-75 mb-3'>
                <InputWrapper errorArray={errors.asap_appointment}>
                  <input type='checkbox' id='asap-appointment' className='mr-1'
                    name='asap_appointment'
                    checked={appointment.asap_appointment}
                    onChange={this.handleCheckboxChange}
                  />
                  <label htmlFor='asap-appointment'>{t('components.appointments.profile_appointment_form.apply_for_asap_appointment')}</label>
                </InputWrapper>
              </div>
            }
            {!appointment.asap_appointment && this.renderAddressBlock()}
            <div className='inputs-holder'>
              {!appointment.asap_appointment &&
                <Fragment>
                  <InputWrapper errorArray={errors.date || errors.time}>
                    <div className='custom-datepicker'>
                      <DatePicker
                        className='form-control'
                        minDate={moment(selectedPlaceOfPractice.appointments_open_from_date)}
                        maxDate={moment(selectedPlaceOfPractice.appointments_open_until_date)}
                        filterDate={date => this.isDateAvailable(date, workingWeekdays)}
                        dateFormat={t('date.formats.moment')}
                        dayClassName={this.dayClassName}
                        selected={appointment.date}
                        onChange={this.handleDateChange}
                        onBlur={this.handleDateBlur}
                        dropdownMode='select'
                        id='date'
                        name='date'
                        todayButton={t('components.datepicker.today')}
                        ref={el => { if (isMobile && el && el.input) el.input.readOnly = true }}
                        locale={locale}
                        inline
                      />
                      </div>
                  </InputWrapper>
                  <TimeOptions
                    timeOptions={timeOptions}
                    selectOption={this.handleTimeInputChange}
                    selectedOption={appointment.raw_time}/>
                </Fragment>
              }
            </div>
          </div>
          <InputWrapper errorArray={errors.subject}>
            <textarea name='subject'
              placeholder={t('activerecord.attributes.appointment.subject')}
              onChange={this.handleInputChange}
              className='form-control'>
            </textarea>
          </InputWrapper>
          <InputWrapper errorArray={errors[`appointment_users[0].user_email`]}>
            <input type='text'
              name='user_email'
              value={appointment.user_email}
              placeholder={t('activerecord.attributes.user.email')}
              onChange={this.handleInputChange}
              className='form-control'
            />
          </InputWrapper>
          <InputWrapper errorArray={errors[`appointment_users[0].user_phone_number`]}>
            <input type='text'
              name='user_phone_number'
              placeholder={t('activerecord.attributes.user.phone_number')}
              value={appointment.user_phone_number}
              onChange={this.handleInputChange}
              className='form-control'
            />
          </InputWrapper>
          <div className='submit-btn-container'>
            <button className='btn submit-appointment-btn' onClick={this.submitAppointment} data-action='submit'>
              {t('components.appointments.profile_appointment_form.apply')}
              <i className='fa fa-chevron-right'></i>
            </button>
          </div>
        </div>
      </div>
    )
  }

  renderSuccess() {
    const { appointment: { asap_appointment } } = this.state
    const translation = asap_appointment ? 'asap_appointment_saved_text' : 'appointment_saved_text'

    return (
      <div className='consultation-success text-center'>
        <i className="fa fa-check fa-2x fa-rounded mb-2"></i>
        <h5 className="consultation-success-title mb-3">
          {t('components.appointments.profile_appointment_form.appointment_saved')}
        </h5>
        <p className='consultation-success-text'>
          {t(`components.appointments.profile_appointment_form.${translation}`)}
        </p>
      </div>
    )
  }

  renderLoginText() {
    const { loginLink, afterLoginRedirectUrl } = this.props
    return (
      <div className="login-text text-center">
        <p className="mb-2">{t('notaries.show.sign_in_for_consultation')}</p>
        <a href={`${loginLink}?redirect_url=${afterLoginRedirectUrl}`} className="btn btn-with-icon">
          <i className="fa fa-arrow-right"></i>
        </a>
      </div>
    )
  }


  render() {
    if (this.state.saved) {
      return this.renderSuccess()
    } else if (!this.props.currentUser) {
      return this.renderLoginText()
    } else {
      return this.renderForm()
    }
  }
}

export default MediaQueries(useNotaryAsapAvailabilityProvider(ProfileAppointmentForm))
