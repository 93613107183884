import React, { Component } from 'react'
import moment from 'moment'
import uniqueId from 'lodash/uniqueId'
import cableConsumer from '../files/cableConsumer'

const REFRESH_TIMEOUT = 1000 * 10

const useNotaryAsapAvailabilityProvider = WrappedComponent => {
  return class extends Component {
    constructor(props) {
      super(props)

      this.state = {
        isReadyForAsapAppointment: false,
        readyForAsapAppointmentUntil: null,
      }

      this.timer = null
    }

    componentDidMount() {
      setTimeout(() => {
        this.subscription = cableConsumer.subscriptions.create(
          { channel: 'NotaryAsapAvailabilityChannel', notary_id: this.props.notaryId, connection_uid: uniqueId() },
          { received: this.handleReceived, rejected: () => this.rejected = true }
        )
      }, 500) // Wait for unsubscribe between requests
    }

    componentWillUnmount() {
      clearTimeout(this.timer)

      if (this.subscription && !this.rejected) {
        this.subscription.unsubscribe()
      }
    }

    handleReceived = ({ ready_for_asap_appointment_until }) => {
      this.setState({
        readyForAsapAppointmentUntil: moment(ready_for_asap_appointment_until)
      }, this.refreshAvailability)
    }

    isReadyForAsapAppointment = () => {
      const { readyForAsapAppointmentUntil } = this.state
      const minutesRemaining = this.minutesRemaining()

      return !!readyForAsapAppointmentUntil && minutesRemaining !== null && minutesRemaining > 0
    }

    minutesRemaining = () => {
      const { readyForAsapAppointmentUntil } = this.state
      if (!readyForAsapAppointmentUntil) return null

      return (readyForAsapAppointmentUntil.diff(moment(new Date).startOf('minute'), 'minutes'))
    }

    refreshAvailability = () => {
      const wasReadyForAsapAppointment = this.state.isReadyForAsapAppointment

      this.setState({ isReadyForAsapAppointment: this.isReadyForAsapAppointment() }, () => {
        if (this.state.isReadyForAsapAppointment) {
          if (this.timer) {
            clearTimeout(this.timer)
          }

          this.timer = setTimeout(this.refreshAvailability, REFRESH_TIMEOUT)
        }
      })
    }

    render() {
      const { isReadyForAsapAppointment } = this.state

      return (
        <WrappedComponent
          isReadyForAsapAppointment={isReadyForAsapAppointment}
          minutesRemaining={this.minutesRemaining()}
          {...this.props}
        />
      )
    }
  }
}

export default useNotaryAsapAvailabilityProvider
