import React, { Component } from 'react'
import Modal from 'react-modal'
import { t } from 'i18n'

class MoreServicesButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false
    }

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this)
  }

  openModal() {
    this.setState({
      modalOpen: true
    })
  }

  closeModal() {
    this.setState({
      modalOpen: false
    })
  }

  render() {
    const { modalOpen } = this.state;

    return (
      <div>
        <div className="services-item" onClick={this.openModal}>
          <div className="services-item-image mb-2">
            <i className="fa fa-plus fa-2x"></i>
          </div>
          <span className="services-item-title">{t('services.index.more_services')}</span>
        </div>
        <Modal isOpen={modalOpen} className="modal-fullscreen more-services-modal">
          <span className="modal-close" onClick={this.closeModal}>
            <i className="fa fa-close fa-lg"></i>
          </span>
          <div className="container">
            <div className="services-block-header mb-4">
              <h2 className="services-block-title">{t('services.index.actions')}</h2>
            </div>
            <div className="row">
            {this.props.services.map((service, index) => {
              return(
                <div key={index} className="col-6 col-sm-6 col-md-4 col-lg-2 mb-4">
                  <div className="services-item">
                    <div className="services-item-image mb-2">
                      <i className={`fa fa-2x ${service.image}`}></i>
                    </div>
                    <span className="services-item-title">{service.title}</span>
                  </div>
                </div>
              )
            })}
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default MoreServicesButton
