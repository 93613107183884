import React, { Component } from 'react'
import { t } from 'i18n'
import Messenger from '../messenger/Messenger'
import UnreadMessagesBubble from '../UnreadMessagesBubble'
import NotarySidebar from './NotarySidebar'
import ClientSidebar from './ClientSidebar'

class SidebarWrapper extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeBlock: 'activities'
    }
  }

  handleChannelReceive(data) {
    this.setState({ project: data })
  }

  renderActivities() {
    const { isNotary, project, cableConsumer, subscription } = this.props
    const SidebarComponent = isNotary ? NotarySidebar : ClientSidebar

    return (
      <SidebarComponent
        {...this.props}
        project={project}
        subscription={subscription}
        cableConsumer={cableConsumer}
      />
    )
  }

  renderMessenger() {
    return (
      <div className='video-room-sidebar-content sidebar-messenger-container'>
        <Messenger
          userId={this.props.currentUserId}
          conversationId={this.props.conversationId}
          recipients={this.props.recipients}
          conversationOnly={true}
        />
      </div>
    )
  }

  toggleActiveBlock(block) {
    this.setState({ activeBlock: block }, () => {
      if (block === 'messenger') {
         // hacky
        setTimeout(() => {
          const messagesContainer = document.querySelector('.messages-container')

          messagesContainer.scrollTop = messagesContainer.scrollHeight
        }, 100)
      }
    })
  }

  renderHeaderButtons() {
    const { activeBlock } = this.state
    return ['activities', 'messenger'].map(type => {
      let className = 'btn video-room-sidebar-btn mr-2'
      let iconClassName = 'fa mr-2'
      iconClassName += type == 'activities' ? ' fa-bolt' : ' fa-comment'

      if (type == activeBlock) {
        className += ' active'
      }

      return(
        <button className={className} onClick={this.toggleActiveBlock.bind(this, type)} data-action={type} key={type}>
          <i className={iconClassName}></i>
          {t(`components.video_room.${type}`)}
          {this.renderUnreadMessagesCount(type)}
        </button>
      )
    })
  }

  renderUnreadMessagesCount(type) {
    if (type != 'messenger') return

    return (
      <UnreadMessagesBubble
        unreadMessagesCount={this.props.unreadMessagesCount}
        currentUserId={this.props.currentUserId}
        conversationId={this.props.conversationId}
      />
    )
  }

  render() {
    const { visible } = this.props

    let sidebarClass = 'video-room-sidebar-wrapper d-lg-block'
    if (!visible) {
      sidebarClass += ' d-none'
    }

    return (
      <div className={sidebarClass}>
        <i className="fa fa-times fa-2x d-lg-none" onClick={() => this.props.toggleSidebar()}></i>
        <div className="video-room-sidebar">
          <div className="video-room-sidebar-header py-3 py-md-4">
            <h1 className="video-room-sidebar-title mb-3 mb-md-4">{t('components.video_room.visit')}</h1>

            <div className="video-room-sidebar-switcher">
              {this.renderHeaderButtons()}
            </div>
          </div>

          {this.state.activeBlock === 'activities' ? this.renderActivities() : this.renderMessenger()}
        </div>
      </div>
    )
  }
}

export default SidebarWrapper
