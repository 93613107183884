import React, { Component } from 'react'
import InputMask from 'react-input-mask'

class TextMaskInput extends Component {
  state = {
    value: this.props.value || ''
  }

  onChange = ({ target: { value } }) => {
    this.setState({ value })
  }

  render() {
    const { mask, name, placeholder, className, id, maskChar = '_' } = this.props

    return (
      <InputMask
        className={`form-control ${className}`}
        id={id}
        onChange={this.onChange}
        value={this.state.value}
        mask={mask}
        name={name}
        placeholder={placeholder}
        maskChar={maskChar}
      />
    )
  }
}

export default TextMaskInput
