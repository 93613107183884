import React, { Component } from 'react'
import { t } from 'i18n'

class UserRow extends Component {
  constructor(props) {
    super(props)
    this.check = this.check.bind(this)
  }

  check() {
    const { user } = this.props
    this.props.onCheck(user.id, !user.selected)
  }

  userRowClass({ selected }) {
    if (selected) {
      return 'selected-row'
    } else {
      return 'not-selected'
    }
  }

  userRoles(user) {
    const roleClasses = {
      user: 'light',
      master_admin: 'primary',
      assistant: 'primary',
      employee: 'primary',
      admin: 'danger'
    }

    return user.roles.map(role => {
      return (
        <span key={role} className={`badge badge-${roleClasses[role]} mr-2`}>
          {t(`activerecord.attributes.user.roles.${role}`)}
        </span>
      )
    })
  }

  render() {
    const { user } = this.props
    return (
      <tr className={this.userRowClass(user)} data-object-id={user.id}>
        <td><input id={'user_' + user.id} type="checkbox" checked={user.selected} onChange={this.check} /></td>
        <td>{user.full_name}</td>
        <td>{this.userRoles(user)}</td>
        <td className="icon-actions">
          <a href={user.user_edit_url} data-action='edit'><i className='fa fa-cog fa-2x' /></a>
        </td>
      </tr>
    )
  }
}

export default UserRow;
