import React, { Component } from 'react'
import FooterItemInput from './FooterItemInput'
import { t } from 'i18n'
import InputWrapper from './utilityComponents/InputWrapper'
import { uniqueId } from 'lodash'

class FooterItemFieldset extends Component {
  constructor(props) {
    super(props)
    props.footerItems.forEach((i) => i.formId = uniqueId())
    this.state = {
      footerItems: props.footerItems,
      selectedLanguage: props.selectedLanguage || 'lv'
    }
    this.addRow = this.addRow.bind(this)
    this.setLanguage = this.setLanguage.bind(this)
    this.renderFooterItem = this.renderFooterItem.bind(this)
  }

  renderFooterItem(item) {
    return <FooterItemInput
      item={item}
      key={item.formId}
      staticPages={this.props.staticPages}
      dynamicSelect={this.props.dynamicPageSelection}
      categoryLanguage={this.state.selectedLanguage} />
  }

  addRow() {
    const newFooterItems = this.state.footerItems
    newFooterItems.push({
      formId: uniqueId(),
      destroyed: false,
      for_static_page: false // For checkbox input inside footer item input component
    })
    this.setState({
      footerItems: newFooterItems
    })
  }

  setLanguage(e) {
    this.setState({selectedLanguage: e.target.value})
  }

  render() {
    const {languageOptions, languageErrors } = this.props

    return (
      <div>
        <InputWrapper className='cms_category_language' errorsArray={languageErrors}>
          <label className='control-label required' htmlFor='cms_category_language'>
            {t('activerecord.attributes.cms/category.language')}
          </label>
          <select
            id='cms_category_language'
            name="cms_category[language]"
            className='form-control select required'
            value={this.state.selectedLanguage}
            onChange={this.setLanguage}>
            {languageOptions.map( l => <option key={l[1]} value={l[1]}>{l[0]}</option> )}
          </select>
        </InputWrapper>
        <span className='pointer' data-action='add-new' onClick={this.addRow}>{t('components.footer_item_fieldset.add_new')}</span>
        {this.state.footerItems.map(this.renderFooterItem)}
      </div>
    )
  }
}

export default FooterItemFieldset
