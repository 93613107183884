import React, { Component } from 'react'
import VideoRoom from './video_room'
import SidebarWrapper from './SidebarWrapper'
import cableConsumer from '../../files/cableConsumer'

class Container extends Component {
  constructor(props) {
    super(props)

    this.state = {
      project: props.project,
      sidebarOpened: false,
    }

    this.subscription = cableConsumer.subscriptions.create(
      {
        channel: 'ProjectChannel',
        project_id: props.project.id
      },
      {
        received: this.handleChannelReceive.bind(this)
      }
    )

    this.toggleSidebar = this.toggleSidebar.bind(this)
  }

  handleChannelReceive(data) {
    this.setState({ project: data })
  }

  toggleSidebar() {
    this.setState(({ sidebarOpened }) => ({ sidebarOpened: !sidebarOpened }))
  }

  render() {
    const videoRoomWrapperClass = `video-room-conversation-wrapper ${this.state.sidebarOpened ? 'd-none' : ''}`

    return(
      <div className='video-room-container'>
        <SidebarWrapper
          isNotary={this.props.isNotary}
          visible={this.state.sidebarOpened}
          toggleSidebar={this.toggleSidebar}
          project={this.state.project}
          projectUsers={this.props.projectUsers}
          placesOfPractice={this.props.placesOfPractice}
          recipients={this.props.recipients}
          conversationId={this.props.conversationId}
          currentUserId={this.props.currentUserId}
          unreadMessagesCount={this.props.unreadMessagesCount}
          cableConsumer={cableConsumer}
          subscription={this.subscription}
        />

        <div className={videoRoomWrapperClass}>
          <i className='fa fa-chevron-down fa-2x d-lg-none' onClick={this.toggleSidebar}></i>
          <VideoRoom
            projectId={this.state.project.id}
            closestAppointmentId={this.props.closestAppointmentId}
            participants={this.props.recipients}
            isNotary={this.props.isNotary}
            callActive={this.props.callActive}
            currentUserName={this.props.currentUserName}
            currentUserId={this.props.currentUserId}
            joinVideoCall={this.props.joinVideoCall}
            appointmentUsers={this.state.project.appointmentUsers}
            cableConsumer={cableConsumer}
            subscription={this.subscription}
            performTwilioTest={this.props.performTwilioTest}
          />
        </div>
      </div>
    )
  }
}

export default Container
