import React, { Component } from 'react'
import { t } from 'i18n'

class WarrantPaymentStep extends Component {
  constructor(props) {
    super(props)
    this.state = {
      warrant_check: props.warrant_check
    }
  }

  openPaymentWindow() {
    const payment = this.props.warrant_check.payment
    let windowWidth = Math.max(screen.width * 0.7, 820)
    let windowHeight = Math.max(screen.height * 0.7, 720)

    this.paymentWindow = window.open(payment.vraa_url, '', `width=${windowWidth},height=${windowHeight}`)
  }

  renderPaymentLink() {
    return (
      <div className='text-center'>
        <a className='btn main-page-button' onClick={this.openPaymentWindow.bind(this)}>
          {t('components.warrant_check_container.pay')}
        </a>
      </div>
    )
  }

  renderSpinner() {
    return (
      <span className='loading-indicator'></span>
    )
  }

  render() {
    const { payment } = this.props.warrant_check

    return(
      <div className='payment-screen col-sm-4 m-auto py-5'>
        <h4 className='text-center mt-5 pt-5'>{t('components.warrant_check_container.payment_heading')}</h4>
        {payment.vraa_url ? this.renderPaymentLink() : this.renderSpinner()}
      </div>
    )
  }
}

export default WarrantPaymentStep
