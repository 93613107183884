import React, { Component } from 'react'
import { List, Map } from 'immutable'
import axios from 'axios'
import moment from 'moment'
import { t } from 'i18n'
import debounce from 'lodash/debounce'
import MediaQueries from '../utilityComponents/MediaQueries'
import {
  messageTimeLabel,
  immuteConversationMessages,
  concatDateMessages,
  userNameWithRole
} from './helpers/ConversationHelper'

const initialState = {
  datesMessages: new List,
  messageText: '',
  page: 1,
  loading: true,
  other_parties: [],
  absenceMessage: null,
  replacementLink: null
}

class Conversation extends Component {
  state = initialState

  constructor(props) {
    super(props)
    this.adjustMessengerHeight = debounce(this.adjustMessengerHeight, 100)
  }

  enableAutoHeightAdjust = () => {
    window.addEventListener('resize', this.adjustMessengerHeight)
    this.adjustMessengerHeight()
  }

  disableAutoHeightAdjust = () => {
    this.adjustMessengerHeight.cancel()
    window.removeEventListener('resize', this.adjustMessengerHeight)
    if(this.messagesWrapper) {
      this.messagesWrapper.style.maxHeight = 'none'
    }
  }

  adjustMessengerHeight = () => {
    let paddingTop = 66
    if (this.props.conversationOnly) paddingTop += 112

    if (this.messagesWrapper) {
      this.messagesWrapper.style.height = `${window.innerHeight - paddingTop}px`
    }
  }

  componentWillMount() {
    this.loadConversation(this.props)
  }

  componentDidUpdate() {
    if (this.messagesWrapper) {
      const { isMobile } = this.props
      isMobile ? this.enableAutoHeightAdjust() : this.disableAutoHeightAdjust()
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  componentWillReceiveProps(props) {
    if (props.conversationId === this.conversationId) return

    this.subscription.unsubscribe()
    this.loadConversation(props)
  }

  loadConversation({ conversationId }) {
    this.conversationId = conversationId

    this.setState({ rerendering: true })
    this.fetchConversationData()
    this.subscribe()
  }

  async fetchConversationData() {
    const { data } = await axios.get('/messenger/conversations/' + this.conversationId)
    const { messages, can_fetch_more, other_parties, notary_absence_message, notary_absence_replacement_link } = data

    this.setState({
      datesMessages: new List(immuteConversationMessages(messages)),
      canFetchMore: can_fetch_more === false ? false : true,
      absenceMessage: notary_absence_message,
      replacementLink: notary_absence_replacement_link,
      loading: false,
      page: 1,
      other_parties
    }, () => setTimeout(() => {
        this.scrollToBottom()
        this.setState({ rerendering: false })
      }, 150)
    )
  }

  subscribe() {
    this.subscription = this.props.cableConsumer.subscriptions.create(
      { channel: 'ConversationChannel', conversation_id: this.conversationId },
      { received: this.received.bind(this) }
    )
  }

  async fetchMoreMessages() {
    this.setState({ loading: true })

    const page = this.state.page + 1
    const { data } = await axios.get('/messenger/conversations/' + this.conversationId, { params: { messages_only: true, page } })
    const newDatesMessages = immuteConversationMessages(data.messages)

    this.setState(state => {
      let datesMessages = state.datesMessages

      newDatesMessages.forEach(dateMessages => {
        const index = datesMessages.findIndex(dm => dm.get('date').isSame(dateMessages.get('date')))

        if (index === -1) {
          datesMessages = datesMessages.push(dateMessages)
        } else {
          datesMessages = concatDateMessages(datesMessages, index, dateMessages.get('messages'))
        }
      })

      return Object.assign(state, { loading: false, canFetchMore: data.can_fetch_more, page, datesMessages })
    })
  }

  received(message) {
    this.setState(state => {
      let datesMessages = state.datesMessages
      let index = datesMessages.findIndex(dm => dm.get('date').isSame(moment(message.created_at), 'day'))

      if (index === -1) {
        datesMessages = datesMessages.unshift(new Map({ date: moment(new Date()).startOf('day'), messages: new List([message]) }))
      } else {
        datesMessages = concatDateMessages(datesMessages, index, message)
      }

      state.datesMessages = datesMessages

      return state
    }, this.scrollToBottom.bind(this))
  }

  onChangeText = ({ target }) => {
    this.setState({ messageText: target.value })
  }

  onChatScroll = ({ target }) => {
    const { loading, canFetchMore } = this.state

    if (!loading && canFetchMore && target.scrollTop < 300) {
     this.fetchMoreMessages()
    }
  }

  submitMessage = ({ key }) => {
    if (key && key !== 'Enter') return

    this.subscription.send({ body: this.state.messageText })

    this.setState({ messageText: '' })
  }

  scrollToBottom() {
    this.messagesContainer.scrollTop = this.messagesContainer.scrollHeight
  }

  formatDateTitle(date) {
    const currentDate = moment(new Date())

    let dateTitle
    if (date.isSame(currentDate, 'day')) {
      dateTitle = t('messenger.conversations.today')
    } else if (date.isSame(currentDate.subtract(1, 'day'), 'day')) {
      dateTitle = t('messenger.conversations.yesterday')
    } else {
      dateTitle = date.format('L')
    }

    return dateTitle
  }

  renderMessageDays() {
    return this.state.datesMessages.reverse().map((dateMessages, index) => {
      return(
        <div key={index}>
          <div className='conversation-date-wrapper'>
            <span className="conversation-date">{this.formatDateTitle(dateMessages.get('date'))}</span>
          </div>
          <div className='inverted-list'>
            {this.renderMessages(dateMessages.get('messages'))}
          </div>
        </div>
      )
    })
  }

  messageClasses(senderId) {
    let messageClasses = 'message-text '
    let detailsClasses = 'message-details '

    if (senderId === this.props.userId) {
      messageClasses += 'sent-message'
      detailsClasses += 'sent-details'
    } else {
      messageClasses += 'received-message'
      detailsClasses += 'received-details'
    }

    return [messageClasses, detailsClasses]
  }

  renderMessages(messages) {
    return messages.map(message => {
      const { body, sender_id } = message

      const [messageClasses, detailsClasses] = this.messageClasses(sender_id)

      return(
        <div key={message.id} className='message-item'>
          <span className={messageClasses}>
            <img className='message-sender-image' src={message.sender_image_url} />
            <span className='message-body'>{body}</span>
          </span>
          <span className={detailsClasses}>
            <span className='message-sender'>{message.sender}</span>
            <span className='message-time'>{messageTimeLabel(message, messages)}</span>
          </span>
        </div>
      )
    })
  }

  notaryAbsence() {
    const { absenceMessage, replacementLink } = this.state
    if (!absenceMessage) return

    const replacementMessage = replacementLink && <div>
      <div className='replacement' dangerouslySetInnerHTML={{__html: replacementLink}}></div>
      <div>{t('components.messenger.please_contact_replacer')}</div>
    </div>

    return (
      <div className='absence-container'>
        <div className='absence'>
          <i className='fa fa-exclamation-circle' />
          {absenceMessage}
        </div>
        {<div>{replacementMessage}</div>}
      </div>
    )
  }

  render() {
    const { rerendering, other_parties } = this.state
    const invisible = rerendering ? 'invisible' : ''

    return(
      <div className='messages-wrapper' ref={messagesWrapper => this.messagesWrapper = messagesWrapper}>
        <div className={['messages-title', invisible].join(' ')}>
          <span>{other_parties.map(user => userNameWithRole(user)).join(', ')}</span>
          <i className="fa fa-close fa-lg" onClick={this.props.openConversation.bind(null, 'none')}></i>
        </div>
        <div className={['messages-container', invisible].join(' ')}
              onScroll={this.onChatScroll}
              ref={messagesContainer => this.messagesContainer = messagesContainer}>
          {this.renderMessageDays()}
        </div>
        {this.notaryAbsence()}
        <div className="messages-container-input-container">
          <div className="messages-container-input-wrapper">
            <input className='messages-container-input' placeholder={t('messenger.conversations.new_message')}
                  value={this.state.messageText}
                  onChange={this.onChangeText}
                  onKeyPress={this.submitMessage}
                  disabled={rerendering}
                  name='new-message' />
            <button className='messages-container-button' onClick={this.submitMessage} data-action='submit-message'>
              <i className="fa fa-send"></i>
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default MediaQueries(Conversation)
