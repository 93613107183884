import cableConsumer from '../../files/cableConsumer'
import { currentUserId } from '../../files/helpers'

class Notifier {
  constructor() {
    this.userId = currentUserId()

    if (this.userId) {
      this.askNotificationPermission()
      this.subscribe()
    }
  }

  askNotificationPermission() {
    if (typeof Notification !== 'undefined' && Notification.permission !== 'granted') {
      Notification.requestPermission()
    }
  }

  subscribe() {
    this.windowVisibilityState = document.visibilityState

    document.addEventListener('visibilitychange', newState => {
      this.windowVisibilityState = document.visibilityState
    })

    cableConsumer.subscriptions.create('UserMessagesChannel', {
      received: this.createWebNotification.bind(this)
    })
  }

  createWebNotification(message) {
    if (typeof Notification === 'undefined') return
    if (message.sender_id === this.userId) return
    if (this.windowVisibilityState === 'visible' && window.location.pathname === '/messenger') return

    const notification = new Notification(message.sender, { body: message.body })

    notification.onclick = this.onNotificationClick.bind(null, message.conversation_id)
  }

  onNotificationClick(conversationId) {
    if (window.activeConversationId !== conversationId && !window.location.pathname.match(/projects\/\d+/)) {
      window.location = '/messenger?conversation_id=' + conversationId
    }

    window.focus()
  }
}

export default Notifier
