import React, { Component } from 'react'
import axios from 'axios'
import find from 'lodash/find'
import snakeCase from 'lodash/snakeCase'
import { t } from 'i18n'
import ProjectItemTabs, { scrollTabIntoView } from './ProjectItemTabs'
import ProjectNewAppointmentButton from '../../components/project/NewAppointmentButton'
import ProjectFiles from './sidebar_steps/ProjectFiles'
import SigningBlock from './sidebar_steps/SigningBlock'
import PaymentBlock from './sidebar_steps/PaymentBlock'
import FinalDocumentBlock from './sidebar_steps/FinalDocumentBlock'

class NotarySidebar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedProjectItemIndex: props.project.projectItems.length - 1
    }
  }

  componentDidMount() {
    scrollTabIntoView(this.state.selectedProjectItemIndex)
  }

  componentDidUpdate(prevProps) {
    const currentItemCount = this.props.project.projectItems.length
    const prevItemCount = prevProps.project.projectItems.length

    if (currentItemCount !== prevItemCount) {
      // Select last item
      const selectedProjectItemIndex = currentItemCount - 1
      scrollTabIntoView(selectedProjectItemIndex)
      this.setState({ selectedProjectItemIndex })
    }
  }

  selectedProjectItem = () => {
    return this.props.project.projectItems[this.state.selectedProjectItemIndex]
  }

  toggleBlock = (flagName) => {
    const { project, subscription } = this.props
    const selectedProjectItem = this.selectedProjectItem()

    const params = {
      [snakeCase(flagName)]: !selectedProjectItem[flagName]
    }

    axios.put(`/projects/${project.id}/project_items/${selectedProjectItem.id}`, { project_item: params })
      .then(() => subscription.send({}))
      .catch(() => {})
  }

  closeProjectItem = () => {
    const { project, subscription } = this.props
    const selectedProjectItem = this.selectedProjectItem()

    axios.put(`/projects/${project.id}/project_items/${selectedProjectItem.id}/close`)
      .then(({ data }) => subscription.send({}))
      .catch(() => {})
  }

  openProjectItem = () => {
    const { project, subscription } = this.props
    const selectedProjectItem = this.selectedProjectItem()

    axios.put(`/projects/${project.id}/project_items/${selectedProjectItem.id}`, { project_item: { closed_at: null }})
      .then(({ data }) => subscription.send({}))
      .catch(() => {})
  }

  onSelectProjectItem = (selectedProjectItemIndex) => {
    const projectItemCount = this.props.project.projectItems.length
    if (selectedProjectItemIndex < 0 || selectedProjectItemIndex > projectItemCount - 1) return

    scrollTabIntoView(selectedProjectItemIndex)
    this.setState({ selectedProjectItemIndex })
  }

  renderFinishButton() {
    const isClosed = this.selectedProjectItem().closedAt

    return (
      <button
        className="btn video-room-sidebar-btn d-block w-100 mt-3 mb-2"
        onClick={isClosed ? this.openProjectItem : this.closeProjectItem}
        data-action={isClosed ? 'open-project-item' : 'close-project-item'}
      >
        <i className='fa fa-chevron-right mr-2' />
        {t(`components.project.notary_sidebar.${isClosed ? 'open_project' : 'close_project'}`)}
      </button>
    )
  }

  render() {
    const { project, projectUsers, placesOfPractice, subscription, cableConsumer } = this.props
    const { selectedProjectItemIndex } = this.state
    const selectedProjectItem = this.selectedProjectItem()

    return (
      <div className='video-room-sidebar-content-wrapper'>
        <ProjectItemTabs
          subscription={subscription}
          project={project}
          onSelect={this.onSelectProjectItem}
          selectedIndex={selectedProjectItemIndex}
          isNotary={true}
        />

        {selectedProjectItem &&
          <div className='video-room-sidebar-content' key={selectedProjectItem.id}>
            <ProjectFiles
              isNotary={true}
              subscription={subscription}
              projectUsers={projectUsers}
              onToggleActive={this.toggleBlock}
              project={project}
              projectItem={selectedProjectItem}
            />

            <SigningBlock
              subscription={subscription}
              cableConsumer={cableConsumer}
              onToggleActive={this.toggleBlock}
              project={project}
              projectItem={selectedProjectItem}
              projectUsers={projectUsers}
              isNotary={true}
            />

            <PaymentBlock
              subscription={subscription}
              onToggleActive={this.toggleBlock}
              project={project}
              projectItem={selectedProjectItem}
              projectUsers={projectUsers}
              isNotary={true}
            />

            <FinalDocumentBlock
              subscription={subscription}
              cableConsumer={cableConsumer}
              onToggleActive={this.toggleBlock}
              finalDocument={find(selectedProjectItem.signedDocuments, sd => sd.finalVersion)}
              project={project}
              projectItem={selectedProjectItem}
              isNotary={true}
            />

            <div className="video-room-sidebar-item-holder mb-3">
              {this.renderFinishButton()}
            </div>

            <div className="video-room-sidebar-item-holder">
              <ProjectNewAppointmentButton
                className="btn video-room-sidebar-btn d-block w-100 mb-3"
                projectId={project.id}
                notaryId={project.notaryId}
                placesOfPractice={placesOfPractice}
                isNotary={true}
              />
            </div>
          </div>
        }
      </div>
    )
  }
}

export default NotarySidebar
