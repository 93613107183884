// Skip global XMLHttpRequest patching due to Google Maps JS API not accepting X-CSRF-Token header.
// Patch axios instead.

// const send = XMLHttpRequest.prototype.send
// XMLHttpRequest.prototype.send = function() {
//   this.setRequestHeader(headerName, csrfToken)
//   return send.apply(this, arguments)
// }

import axios from 'axios'

const csrfTag    = document.querySelector('meta[name="csrf-token"]')
const csrfToken  = csrfTag && csrfTag.getAttribute('content')
const headerName = 'X-CSRF-Token'

axios.defaults.headers.common[headerName] = csrfToken
