import React, { Component } from 'react'
import Modal from 'react-modal'
import DatePicker from 'react-datepicker'
import TimePicker from 'rc-time-picker'
import { AsyncPaginate } from 'react-select-async-paginate'
import moment from 'moment'
import axios from 'axios'
import map from 'lodash/map'
import { t, locale } from 'i18n'
import InputWrapper from '../utilityComponents/InputWrapper'
import { renderAddressBlock,
  handlePlaceOfPracticeChange,
  selectedWorkingTimeOptions,
  changedAppointment,
  handleDateChange } from '../appointments/AppointmentFunctions'

class NewAppointmentModal extends Component {

  constructor(props) {
    super(props)
    const activePlaceOfPractice = props.placesOfPractice.find( (pop) => pop.active)

    this.newAppointmentUser = {
      user_obj: '',
      user_id: '',
      user_email: '',
      user_full_name: '',
      user_phone_number: ''
    }

    this.state = {
      selectedPlaceOfPractice: activePlaceOfPractice,
      errors: {},
      loading: false,
      appointment: {
        date: moment(new Date()),
        address: activePlaceOfPractice.address,
        time: null,
        duration_minutes: props.defaultDurationMinutes,
        subject: '',
        appointment_users_attributes: [{...this.newAppointmentUser}]
      }
    }

    this.handleInputChange = this.handleInputChange.bind(this)

    // MIXIN functions
    this.renderAddressBlock = renderAddressBlock.bind(this)
    this.handlePlaceOfPracticeChange = handlePlaceOfPracticeChange.bind(this)
    this.selectedWorkingTimeOptions = selectedWorkingTimeOptions.bind(this)
    this.changedAppointment = changedAppointment.bind(this)
    this.handleDateChange = handleDateChange.bind(this)
  }

  handleInputChange(event) {
    const { value, name } = event.target

    this.setState({
      appointment: this.changedAppointment({ [name]: value })
    })
  }

  async fetchUsers(input, currentOptions) {
    const { data } = await axios.get('/notary/users.json', {
      params: { q: input, offset: currentOptions.length }
    })

    return {
      options: data.users,
      hasMore: data.has_more
    }
  }

  saveAppointment() {
    const { appointment, loading } = this.state
    if (loading) { return }
    this.setState({ loading: true })
    axios.post('/notary/appointments', {
      appointment: {
        ...appointment,
        // Make sure that only date is passed in params, not Datetime
        date: appointment.date.format(t('moment_date.formats.default')),
        time: appointment.time && appointment.time.format('H:mm')
      }
    }).
      then(({ data }) => {
        this.setState({ loading: false })
        this.props.onSave(data)
      }).
      catch(({ response }) => {
        this.setState({ errors: response.data, loading: false })
      })
  }

  renderUsersNestedForm() {
    return (
      <div className='clients-section'>
        <label>{t('components.project.new_appointment_modal.clients')}</label>
        {this.renderUserInputs()}
        <a href='#' onClick={this.handleAddClient.bind(this)} className='add-client-btn'>
          <i className='fa fa-plus-circle'/>
          <span>{t('components.project.new_appointment_modal.add_client')}</span>
        </a>
      </div>
    )
  }

  renderClientOption = (option) => {
    return (
      <>
        <div>{option.label}</div>
        <div className='dropdown-only text-muted'>
          <small>{`${option.phone_number || '-'}, ${option.email || '-'}`}</small>
        </div>
      </>
    )
  }

  renderUserInputs() {
    const { errors, appointment: { appointment_users_attributes } } = this.state

    return (
      map(appointment_users_attributes, (appointmentUser, index) => {
        return (
          <div className='row appointment-user' key={index}>
            <div className='col-xl-4'>
              <InputWrapper>
                <AsyncPaginate
                  classNamePrefix='react-select'
                  className='react-select user-id'
                  loadOptions={this.fetchUsers}
                  formatOptionLabel={this.renderClientOption}
                  placeholder={t('components.appointments_calendar.client_search_placeholder')}
                  onChange={selectedUser => this.handleUserChange(selectedUser, appointmentUser)}
                  value={appointmentUser.user_obj}
                  name='user_id'
                  debounceTimeout={500}
                />
              </InputWrapper>
            </div>
            <div className='col-xl-3'>
              <InputWrapper className='input-group-sm' errorArray={errors[`appointment_users[${index}].user_email`]}>
                <input type='text'
                  className='form-control'
                  onChange={e => this.handleUserInputChange(e, appointmentUser)}
                  value={appointmentUser.user_email}
                  name='user_email'
                  placeholder={t('activerecord.attributes.user.email')}
                />
              </InputWrapper>
            </div>
            <div className='col-xl-2'>
              <InputWrapper className='input-group-sm' errorArray={errors[`appointment_users[${index}].user_full_name`]}>
                <input type='text'
                  className='form-control'
                  onChange={e => this.handleUserInputChange(e, appointmentUser)}
                  value={appointmentUser.user_full_name}
                  name='user_full_name'
                  placeholder={t('activerecord.attributes.user.full_name')}
                />
              </InputWrapper>
            </div>
            <div className='col-xl-2'>
              <InputWrapper className='input-group-sm' errorArray={errors[`appointment_users[${index}].user_phone_number`]}>
                <input type='text'
                  className='form-control'
                  onChange={e => this.handleUserInputChange(e, appointmentUser)}
                  value={appointmentUser.user_phone_number}
                  name='user_phone_number'
                  placeholder={t('activerecord.attributes.user.phone_number')}
                />
              </InputWrapper>
            </div>
            <div className='col-xl-1'>
              <a href='#'
                className='remove-client-btn'
                disabled={appointment_users_attributes.length == 1}
                onClick={e => this.handleRemoveClient(appointmentUser)}
              >
                <i className='fa fa-times'/>
              </a>
            </div>
          </div>
        )
      })
    )
  }

  handleUserChange(selectedUser, appointmentUser) {
    Object.assign(appointmentUser,
      selectedUser ? {
        user_obj: selectedUser,
        user_id: selectedUser.value,
        user_email: selectedUser.email,
        user_full_name: selectedUser.full_name,
        user_phone_number: selectedUser.phone_number
      } : this.newAppointmentUser
    )

    this.setState({ appointment: this.state.appointment })
  }

  handleUserInputChange(e, appointmentUser) {
    appointmentUser[e.target.name] = e.target.value
    this.setState({ appointment: this.state.appointment })
  }

  handleAddClient() {
    const newAppointmentUser = Object.assign({}, this.newAppointmentUser)

    this.setState({
      appointment: this.changedAppointment({
        appointment_users_attributes: [...this.state.appointment.appointment_users_attributes, newAppointmentUser]
      })
    })
  }

  handleRemoveClient(appointmentUser) {
    const { appointment } = this.state
    if (appointment.appointment_users_attributes.length == 1) return

    let appointmentUsers = [...appointment.appointment_users_attributes]
    const userIndex = appointmentUsers.findIndex(u => u.user_id == appointmentUser.user_id)
    appointmentUsers.splice(userIndex, 1)

    this.setState({
      appointment: this.changedAppointment({
        appointment_users_attributes: appointmentUsers
      })
    })
  }

  handleAppointmentTimeChange = (time) => {
    this.setState({
      appointment: this.changedAppointment({ time })
    })
  }

  render() {
    const { appointment, errors } = this.state

    return (
      <div>
        <Modal isOpen={true} className="modal-wide blue-modal">
          <span className="modal-close" onClick={this.props.closeModal}>
            <i className="fa fa-close fa-lg"></i>
          </span>
          <div className='inputs-holder'>
            {this.renderUsersNestedForm()}
            {this.renderAddressBlock()}

            <div className='row'>
              <div className='col-sm-4'>
              <label className='input-label'>{t('activerecord.attributes.appointment.date')}</label>
                <InputWrapper className='datepicker' errorArray={errors.date}>
                  <DatePicker
                    className='form-control'
                    selected={appointment.date}
                    onChange={this.handleDateChange.bind(this)}
                    showMonthDropdown
                    showYearDropdown
                    id='date'
                    name='date'
                    dropdownMode='select'
                    dateFormat={t('date.formats.moment')}
                    todayButton={t('components.datepicker.today')}
                    locale={locale}
                  />
                </InputWrapper>
              </div>
              <div className='col-sm-4'>
                <InputWrapper className='time-input' errorArray={errors.time}>
                  <label className='input-label'>{t('activerecord.attributes.appointment.time')}</label>
                  <TimePicker
                    value={appointment.time}
                    onChange={this.handleAppointmentTimeChange}
                    className='appointment-time time-picker-light'
                    showSecond={false}
                    allowEmpty={false}
                    format='H.mm'
                  />
                </InputWrapper>
              </div>
              <div className='col-sm-4'>
                <InputWrapper className='input-group-sm' errorArray={errors.duration}>
                  <label className='input-label'>{t('activerecord.attributes.appointment.duration_minutes')}</label>
                  <input
                    type='number'
                    name='duration_minutes'
                    value={appointment.duration_minutes}
                    onChange={this.handleInputChange}
                    className='form-control'
                  />
                </InputWrapper>
              </div>
            </div>

            <InputWrapper className='input-group-sm' errorArray={errors.subject}>
              <label className='input-label'>{t('activerecord.attributes.appointment.subject')}</label>
              <textarea name='subject' id='subject' onChange={this.handleInputChange} className='form-control'></textarea>
            </InputWrapper>
          </div>
          <div className='text-center mt-5'>
            <a href='#' className='btn blue-modal-btn' onClick={this.saveAppointment.bind(this)}>
              {t('save')}
            </a>
          </div>
        </Modal>
      </div>
    )
  }
}

export default NewAppointmentModal
