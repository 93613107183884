import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { t } from 'i18n'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import TransferInformation from './TransferInformation'
import PlaceOfPracticeWorkingSchedule from './PlaceOfPracticeWorkingSchedule'
import ProfileAppointmentForm from '../appointments/ProfileAppointmentForm'
import NotaryAvailabilityIndicator from '../NotaryAvailabilityIndicator'
import Tooltip from '../utilityComponents/Tooltip'

class NotarySideBar extends Component {
  componentDidMount() {
    ReactDOM.findDOMNode(this).parentElement.scrollTop = 0
    window.scrollTop = 0
  }

  splitStringIntoSpans(string) {
    let words = string.split(' ')

    return (
      words.map((word, index) =>
        <span key={index}>
          <span className='text-nowrap'>{word}</span>
          <span>{index == words.length - 1 ? '' : ' '}</span>
        </span>
      )
    )
  }

  notaryAddresses() {
    const { notary } = this.props
    return (
      filter(notary.places_of_practice, 'active').map(place =>
        <div className='icon-container d-flex align-items-center' key={place.id}>
          <i className='fa fa-location-arrow fa-rounded' />
          <div className='d-inline-block'>{this.splitStringIntoSpans(place.full_address)}</div>
        </div>
      )
    )
  }

  renderNotaryAbsence() {
    const { notary: { absence_interval,  absence_replacement_link } } = this.props

    if (absence_interval) {
      return (
        <div className='absence-container'>
          <p className='absence-date'>
            {absence_interval}
          </p>
          <span dangerouslySetInnerHTML={{__html: absence_replacement_link}}></span>
        </div>
      )
    }
  }

  renderNotaryEmergencyNotice() {
    const { notary: { emergency_notice } } = this.props
    if (!emergency_notice) return

    return (
      <div className='mt-3'>
        <p>{emergency_notice}</p>
      </div>
    )
  }

  renderAppointmentForm() {
    const { currentUser, submitButtonImagePath, notary, loginLink } = this.props
    if (!notary.active || !notary.application_for_appointment) return

    return (
      <div className='notary-container'>
        <div className='container'>
          <div className='info-container'>
            <ProfileAppointmentForm
              submitButtonImagePath={submitButtonImagePath}
              placesOfPractice={notary.places_of_practice}
              notaryId={notary.id}
              userEmail={currentUser ? currentUser.email : null}
              userPhoneNumber={currentUser ? currentUser.phone_number : null}
              currentUser={currentUser}
              loginLink={loginLink}
              afterLoginRedirectUrl={`/notaries_map?open_notary=${notary.id}`} />
          </div>
        </div>
      </div>
    )
  }

  notaryWorkingTime() {
    const { notary } = this.props
    return sortBy(notary.places_of_practice, p => !p.active).map(place => {
      if(place.active){
        return ( <PlaceOfPracticeWorkingSchedule place={place} key={place.id} /> )
      } else {
        return (
          <TransferInformation
            place={place}
            key={place.id}
            onTransferClick={this.props.onChangeSidebarNotaryById}/>
        )
      }
    })
  }
  renderNotaryWorkers(workersType) {
    const notaryWorkers = this.props.notary[workersType].map(worker => {
      return `${worker.first_name} ${worker.last_name}`
    }).join(', ')

    if(notaryWorkers) {
      return(
        <div className="notary-workers icon-container">
          <Tooltip tooltipText={t(`notaries_map.index.notary_${workersType}`)}>
            <i className='fa fa-user fa-rounded'/><span>{notaryWorkers}</span>
          </Tooltip>
        </div>
      )
    }
  }

  renderPhoneNumbers() {
    const { notary } = this.props

    return ['office_number', 'extra_number'].map(number => {
      if(notary[number]) {
        return(
          <div key={number} className='icon-container'>
            <i className='fa fa-mobile fa-rounded fa-lg' />
            {notary[number]}
          </div>
        )
      }
    })
  }

  renderNotaryInfo() {
    const { notary } = this.props

    if (notary.active) {
      return (
        <div>
          <div className="notary-container">
            <div className="container">
              <div className="info-container">
                {this.notaryAddresses()}
                {this.renderPhoneNumbers()}
                <div className='icon-container'>
                  <i className='fa fa-at fa-rounded' />
                  <a href={`mailto:${notary.email}`} className='email-link'>{notary.email}</a>
                </div>
                {this.renderNotaryWorkers('assistants')}
                {this.renderNotaryWorkers('employees')}
              </div>
            </div>
          </div>
          {this.renderAppointmentForm()}
          <div className="notary-container">
            <div className="container">
              <h6>{t('notaries.show.working_hours')}</h6>
              {this.notaryWorkingTime()}
            </div>
          </div>
        </div>
      )
    } else {
      const transfers = this.props.notary.places_of_practice.map(place => {
        return (
          <TransferInformation
            place={place}
            key={place.id}
            onTransferClick={this.props.onChangeSidebarNotaryById} />
        )
      })

      return(
        <div className="notary-container">
          <div className="container">
            {transfers}
          </div>
        </div>
      )
    }
  }

  renderNotaryProfileLink() {
    const { notary } = this.props
    if(notary.active) {
      return(
        <div className="sidebar-button">
          <a className='btn btn-primary' href={notary.notary_link}>{t('notaries.show.view_profile')}</a>
        </div>
      )
    }
  }

  render() {
    const { notary } = this.props
    let languages = notary.known_languages.map(lang => {
      return <div key={lang} className='language-icon'>{lang.toUpperCase()}</div>
    })

    return(
      <div className="notary-profile" id="profile">
        <div className="notary-container header-container">
          <div className="close pointer">
            <i className='fa fa-times-thin fa-2x' onClick={this.props.onClose} />
          </div>
          <div className="container">
            <div className="info-container">
              <div className="info-header">
                <div className="notary-image">
                  <div className="image-container">
                    <img src={notary.image_url} />
                    <NotaryAvailabilityIndicator notaryId={notary.id} onlineOperations={notary.online_operations} />
                  </div>
                </div>
                <div className="inner-info-header">
                  <h6>{notary.full_name}</h6>
                  <p className='county-court'>{notary.county_court}</p>
                  <div className="languages">
                    {notary.active ? languages : null}
                  </div>
                  {this.renderNotaryAbsence()}
                  {this.renderNotaryEmergencyNotice()}
                </div>
                {this.renderNotaryProfileLink()}
              </div>
            </div>
          </div>
        </div>
        {this.renderNotaryInfo()}
      </div>
    )
  }
}

export default NotarySideBar
