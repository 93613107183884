import React from 'react'
import ReactHintFactory from 'react-hint'

const ReactHint = ReactHintFactory(React)

const Tooltip = (props) => {
  if(props.children) {
    return (
      <div className='tooltip-wrapper'>
        <ReactHint events delay={100} className='react-hint' />
        <div className='tooltip-container' data-rh={props.tooltipText} data-rh-at={props.position}>
          {props.children}
        </div>
      </div>
    )
  } else {
    return (
      <div className='tooltip-wrapper'>
        <ReactHint events delay={100} className='react-hint' />
        <div className='tooltip-container' data-rh={props.tooltipText} data-rh-at={props.position} dangerouslySetInnerHTML={{ __html: props._html }} />
      </div>
    )
  }
}

Tooltip.defaultProps = {
  position: 'top'
}

export default Tooltip
