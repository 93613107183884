import React, { Component } from 'react'

class InputWrapper extends Component {
  defaultClassNames = 'form-group'

  wrapperClassNames() {
    let classNames = this.defaultClassNames + ' ' + (this.props.className || '')

    if(this.hasError()) {
      classNames += ' has-error'
    }
    return classNames
  }

  hasError() {
    return this.props.errorArray && this.props.errorArray.length > 0
  }

  renderErrorMessage() {
    if(this.hasError()) {
      return (
        <span className='help-block'>{this.props.errorArray[0]}</span>
      )
    }
  }

  render() {
    return (
      <div className={this.wrapperClassNames()}>
        {this.props.children}
        {this.renderErrorMessage()}
      </div>
    )
  }
}

export default InputWrapper
