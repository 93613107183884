import React, { Component } from 'react'
import some from 'lodash/some'
import { t, l } from 'i18n'
import axios from 'axios'

class ProjectFileItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false
    }
  }

  deleteFile() {
    const { projectId, projectItemId, projectFile } = this.props
    axios.delete(`/projects/${projectId}/project_items/${projectItemId}/project_files/${projectFile.id}`).
      then(({data}) => this.props.subscription.send({}))
  }

  acceptFile() {
    const { projectId, projectItemId, projectFile } = this.props
    axios.put(`/projects/${projectId}/project_items/${projectItemId}/project_files/${projectFile.id}/accept`).
      then(({data}) => this.props.subscription.send({}))
  }

  renderDeleteButton() {
    if(this.props.canEdit && this.props.isNotary) {
      return <i className="fa fa-times pointer" onClick={this.deleteFile.bind(this)}></i>
    }
  }

  renderApproveButton() {
    const { hasAcceptOption, canEdit, isNotary, projectFile, currentUserId } = this.props
    const userApprovalExists = some(projectFile.approvals, a => a.approverId === currentUserId)

    if (hasAcceptOption && canEdit && !isNotary && !userApprovalExists) {
      return (
        <div className="text-center">
          <button className="btn video-room-sidebar-btn mt-2" onClick={this.acceptFile.bind(this)}>
            <i className="fa fa-check mr-2"></i>
            {t('components.video_room.approve')}
          </button>
        </div>
      )
    }
  }

  renderFileApprovals() {
    const { isNotary, projectFile: { approvals } } = this.props

    return (
      approvals.map((approval, index) => {
        let approverInfo = approval.approverName
        if (isNotary) approverInfo += ` (${approval.approverPersonalCode})`

        return (
          <div key={index} className='project-file-item mb-2'>
            <div className='author-info'>
              {approverInfo}
            </div>
            <div className='create-time'>
              {l('time.formats.extended', approval.createdAt)}
            </div>
          </div>
        )
      })
    )
  }

  renderFileDetails() {
    const { projectFile, hasAcceptOption, isNotary } = this.props

    if (hasAcceptOption && projectFile.approvals.length > 0) {
      return (
        <div className="video-room-sidebar-item-block-text italic">
          <p className='approved-by-heading'>{t('components.project.sidebar_steps.project_file_item.accepted_by')}:</p>
          {this.renderFileApprovals()}
        </div>
      )
    }

    if(!hasAcceptOption) { // File is on user side, just show the author and upload time
      let authorInfo = projectFile.authorName
      if (isNotary) authorInfo += ` (${projectFile.authorPersonalCode})`

      return(
        <div className='project-file-item video-room-sidebar-item-block-text mb-0'>
          <div className='author-info'>
            {authorInfo}
          </div>
          <div className='create-time'>
            {l('time.formats.extended', projectFile.createdAt)}
          </div>
        </div>
      )
    }
  }

  render() {
    const { projectId, projectItemId, projectFile } = this.props
    const documentUrl = `/projects/${projectId}/project_items/${projectItemId}/project_files/${projectFile.id}/download`

    return (
      <div className='project-file' key={projectFile.id}>
        <div className="video-room-sidebar-item-block-link-wrapper mb-0">
          <a href={documentUrl} target='_blank' className="video-room-sidebar-item-block-link mr-2">
            {projectFile.name}
          </a>
          {this.renderDeleteButton()}
        </div>
        {this.renderFileDetails()}
        {this.renderApproveButton()}
      </div>
    )
  }
}

export default ProjectFileItem
