import React, { Component, Fragment } from 'react'
import axios from 'axios'
import { t } from 'i18n'
import useNotaryAsapAvailabilityProvider from './NotaryAsapAvailabilityProvider'

const ReminderResult = ({ updateResult, onClose }) => {
  return (
    <div className='notification'>
      {!!updateResult &&
        <Fragment>
          <p className='mb-3 font-weight-bold'>{t('components.notary_asap_availability_reminder.extended_title')}</p>
          <p>{t('components.notary_asap_availability_reminder.extended_description')}</p>
        </Fragment>
      }
      {!updateResult &&
        <Fragment>
          <p className='mb-3 font-weight-bold'>{t('components.notary_asap_availability_reminder.canceled_title')}</p>
          <p>{t('components.notary_asap_availability_reminder.canceled_description')}</p>
        </Fragment>
      }

      <div className='buttons text-center'>
        <button className='btn btn-primary' onClick={onClose}>
          {t('components.notary_asap_availability_reminder.close')}
        </button>
      </div>
    </div>
  )
}

const ReminderPrompt = ({ minutesRemaining, toggleReadyForAsapAppointment }) => {
  return (
    <div className='notification asap-availability-reminder'>
      {minutesRemaining > 0 &&
        <Fragment>
          <p className='mb-3 font-weight-bold'>{t('components.notary_asap_availability_reminder.expires_soon_title')}</p>
          <p className='mb-3'>
            <i className='fa fa-clock-o mr-2' />
            {t('components.notary_asap_availability_reminder.expires_soon_description', {
              minutes: minutesRemaining
            })}
          </p>
        </Fragment>
      }
      {minutesRemaining <= 0 &&
        <Fragment>
          <p className='mb-3 font-weight-bold'>{t('components.notary_asap_availability_reminder.expired_title')}</p>
          <p className='mb-3'>
            {t('components.notary_asap_availability_reminder.expired_description')}
          </p>
        </Fragment>
      }
      <div className='buttons text-center'>
        <button className='btn btn-primary' onClick={() => toggleReadyForAsapAppointment(true)}>
          {t('components.notary_asap_availability_reminder.extend_availability')}
        </button>
        <button className='btn btn-secondary' onClick={() => toggleReadyForAsapAppointment(false)}>
          {t('components.notary_asap_availability_reminder.cancel_availability')}
        </button>
      </div>
    </div>
  )
}

const Notification = ({
  show, minutesRemaining, toggleReadyForAsapAppointment, updatePerformed, updateResult, onClose
}) => {
  if (!show) return null

  if (updatePerformed) {
    return <ReminderResult updateResult={updateResult} onClose={onClose} />
  } else {
    return <ReminderPrompt minutesRemaining={minutesRemaining} toggleReadyForAsapAppointment={toggleReadyForAsapAppointment} />
  }
}

class NotaryAsapAvailabilityReminder extends Component {
  initialState = {
    updatePerformed: false,
    updateResult: null
  }

  state = this.initialState

  toggleReadyForAsapAppointment = (value) => {
    axios.patch('/notary/notary/set_ready_for_asap_appointment', {
      ready_for_asap_appointment: value
    }).then(() => {
      this.setState({ updatePerformed: true, updateResult: value })
    })
  }

  reset = () => {
    this.setState(this.initialState)
  }

  render() {
    const { minutesRemaining } = this.props
    const { updatePerformed, updateResult } = this.state

    return (
      <Notification
        show={(minutesRemaining !== null && minutesRemaining <= 5) || updatePerformed}
        minutesRemaining={minutesRemaining}
        toggleReadyForAsapAppointment={this.toggleReadyForAsapAppointment}
        updatePerformed={updatePerformed}
        updateResult={updateResult}
        onClose={this.reset}
      />
    )
  }
}

export default useNotaryAsapAvailabilityProvider(NotaryAsapAvailabilityReminder)
