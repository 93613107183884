import React, { useState } from 'react'
import axios from 'axios'
import { t } from 'i18n'
import { ButtonContent } from './InheritanceCheckForm'

const scope = 'components.inheritance_check'

const searchResultText = (searchForm, searchType) => {
  if(searchType === 'personal_code') {
    return t('with_personal_code', { scope, personalCode: searchForm.personalCode })
  }

  const dateOfBirthText = !!searchForm.dateOfBirthTo
    ? t('with_birthdate_interval', {
      scope,
      dateOfBirthFrom: searchForm.dateOfBirthFrom.format(t('date.formats.moment')),
      dateOfBirthTo: searchForm.dateOfBirthTo.format(t('date.formats.moment'))
    })
    : t('with_birthdate', { scope, dateOfBirthFrom: search.dateOfBirthFrom.format(t('date.formats.moment')) })

  const searchText = searchType === 'personal_code'
    ? t('with_personal_code', { scope, personalCode: searchForm.personalCode })
    : t('with_name', { scope, firstName: searchForm.firstName, lastName: searchForm.lastName })

  return `${searchText} ${dateOfBirthText}`
}

const SingleMatterResult = ({
  searchForm, searchType, getMatters, loading, showPaymentDisclaimer, onContinueClick
}) => {
  if(showPaymentDisclaimer) {
    return <PaymentDisclaimer loading={loading} getMatters={getMatters}/>
  }

  return (
    <div className='info-container bg-light inheritance'>
      <span className='info-field'>
        {searchResultText(searchForm, searchType)} {t('one_result_found', { scope })}
      </span>
      <span className='info-field'>
        {t('see_result', { scope })}
      </span>
    <div className='text-center'>
      <button type='button'
        className='btn form-submit-button'
        onClick={onContinueClick}
      >
        <ButtonContent loading={loading} buttonText={t('continue', { scope })}/>
      </button>
    </div>
  </div>
  )
}

const PaymentDisclaimer = ({ loading, getMatters }) => {
  return (
    <div className='info-container bg-light inheritance'>
      <span className='info-field'>{t('fee_price', { scope })}</span>
      <span className='info-field'>
        {t('fee_agree', { scope })}
      </span>
      <div className='text-center'>
        <button type='button'
          className='btn form-submit-button'
          onClick={getMatters}
        >
          <ButtonContent loading={loading} buttonText={t('continue', { scope })}/>
        </button>
      </div>
    </div>
  )
}

const testatorFromSearch = (searchForm, testator) => {
  if (testator.surname === searchForm.lastName) {
    return [testator.first_name, testator.other_names].includes(searchForm.firstName)
  }

  return false
}

const TestatorRow = ({ searchForm, matter, onMatterSelect, selected }) => {
  const testator = matter.testators.find(testator => testatorFromSearch(searchForm, testator))
  return (
    <tr key={`${testator.first_name}-${testator.birth_date}`} className='bg-light-blue'>
      <td>
        <input
          type='checkbox'
          name={`select-matter-${matter.matter_id}`}
          checked={selected}
          onChange={() => onMatterSelect(matter.matter_id)}
        />
      </td>
      <td>
        {testator.first_name} {testator.other_names ? `(${testator.other_names})` : ''} {testator.surname}
      </td>
      <td>
        {testator.birth_date}
      </td>
    </tr>
  )
}

const MultipleMattersResult = ({
  searchForm, searchType, getMatters, loading, matterResults, selectedMatters, setSelectedMatters,
  showPaymentDisclaimer, onContinueClick
}) => {

  const onAllSelectedChange = (event) => {
    const checked = event.target.checked
    if(checked) {
      setSelectedMatters(matterResults.map(matter => matter.matter_id))
    } else {
      setSelectedMatters([])
    }
  }

  const onMatterSelect = (matterId) => {
    if(selectedMatters.includes(matterId)) {
      setSelectedMatters(selectedMatters.filter(matter => matter !== matterId))
    } else {
      setSelectedMatters(selectedMatters.concat([matterId]))
    }
  }

  if(showPaymentDisclaimer) {
    return <PaymentDisclaimer loading={loading} getMatters={getMatters}/>
  }

  return(
    <>
      <div className='info-container bg-light inheritance'>
        <span className='info-field'>
          {searchResultText(searchForm, searchType)} {t('results_found', { scope, number: matterResults.length })}
        </span>
        <span className='info-field'>
          {t('see_result', { scope })}
        </span>
      </div>
      <table className='table services-table testators-table'>
        <thead>
          <tr>
            <th className='checkbox-th'>
              <input
                type='checkbox'
                name='select_all'
                checked={selectedMatters.length === matterResults.length}
                onChange={onAllSelectedChange}
              />
            </th>
            <th>{t('table.testators', { scope })}</th>
            <th>{t('table.birth_date', { scope })}</th>
          </tr>
        </thead>
        <tbody>
          {matterResults.map(result =>
            <TestatorRow
              key={result.matter_id}
              matter={result}
              searchForm={searchForm}
              onMatterSelect={onMatterSelect}
              selected={selectedMatters.includes(result.matter_id)}
            />
          )}
        </tbody>
      </table>
      <div className='text-center'>
        <button type='button'
          className='btn form-submit-button'
          onClick={onContinueClick}
          disabled={!selectedMatters.length}
        >
          <ButtonContent loading={loading} buttonText={t('continue', { scope })}/>
        </button>
      </div>
    </>
  )
}

const getInitialSelectedMatter = (matterResults) => {
  if(matterResults.length === 1) {
    return matterResults.map(matter => matter.matter_id)
  } else {
    return []
  }
}

const ErrorMessage = ({ errors }) => {
  if (!errors.error && typeof errors !== 'string') return null

  return (
    <div className='errors-section'>
      <i className='fa fa-exclamation-circle mr-2' />
      {errors.error || t('components.inheritance_check.service_unavailable')}
    </div>
  )
}

const SearchResult = ({ matterResults, searchForm, searchType }) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [selectedMatters, setSelectedMatters] = useState(getInitialSelectedMatter(matterResults))
  const [showPaymentDisclaimer, setShowPaymentDisclaimer] = useState(false)

  const onContinueClick = () => {
    setLoading(true)
    axios.get('/inheritance_checks/is_free_service').then(({ data }) => {
      if (data.is_free) {
        getMatters()
      } else {
        setShowPaymentDisclaimer(true)
      }
    }).catch(({response}) => {
      setErrors(response.data)
    }).finally(() =>
      setLoading(false)
    )
  }

  const getMatters = () => {
    setLoading(true)
    axios.post('/inheritance_checks/find_matters', {
      inheritance_check: {
        search_type: searchType,
        personal_code: searchForm.personalCode,
        first_name: searchForm.firstName,
        last_name: searchForm.lastName,
        date_of_birth_from: searchForm.dateOfBirthFrom?.format('DD.MM.yyyy'),
        date_of_birth_to: searchForm.dateOfBirthTo?.format('DD.MM.yyyy'),
      },
      matter_ids: selectedMatters
    })
      .then(({ data }) => {
        if(data.inheritanceMatters.length > 0) {
          location = `/inheritance_checks/${data.id}`
        }
      }).catch(({ response }) => {
        setErrors(response.data)
      }).finally(() =>
        setLoading(false)
      )
    }

    return (
      <>
        <ErrorMessage errors={errors} />
        {matterResults.length === 1 &&
          <SingleMatterResult
            searchForm={searchForm}
            searchType={searchType}
            getMatters={getMatters}
            loading={loading}
            matterResults={matterResults}
            showPaymentDisclaimer={showPaymentDisclaimer}
            onContinueClick={onContinueClick}
          />
        }
        {matterResults.length > 1 &&
          <MultipleMattersResult
            searchForm={searchForm}
            searchType={searchType}
            getMatters={getMatters}
            loading={loading}
            matterResults={matterResults}
            selectedMatters={selectedMatters}
            setSelectedMatters={setSelectedMatters}
            showPaymentDisclaimer={showPaymentDisclaimer}
            onContinueClick={onContinueClick}
          />
        }
      </>
    )
  }

export default SearchResult
