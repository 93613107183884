import React, { Component } from 'react'
import NewAppointmentModal from './NewAppointmentModal'
import Notice, { showNotice } from './../utilityComponents/Notice'
import { t } from 'i18n'

class NewAppointmentButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newAppointmentModalActive: false,
      noticeText: null
    }

    this.showNotice = showNotice.bind(this)
  }

  renderNewAppointmentModal() {
    if (this.state.newAppointmentModalActive) {
      return (
        <NewAppointmentModal
          projectId={this.props.projectId}
          notaryId={this.props.notaryId}
          closeModal={this.toggleModalActive}
          placesOfPractice={this.props.placesOfPractice}
          onSave={this.handleAppointmentSave}
          isNotary={this.props.isNotary}
        />
      )
    }
  }

  handleAppointmentSave = () => {
    this.toggleModalActive()

    const noticeText = t('components.appointments_calendar.appointment_saved')
    this.showNotice(noticeText)
  }

  toggleModalActive = () => {
    this.setState({ newAppointmentModalActive: !this.state.newAppointmentModalActive })
  }

  render() {
    return(
      <div>
        <a href='#' onClick={this.toggleModalActive} className={this.props.className}>
          <i className='fa fa-plus mr-2' />
          {t('components.project.next_appointment')}
        </a>
        {this.renderNewAppointmentModal()}
        <Notice text={this.state.noticeText} />
      </div>
    )
  }
}

export default NewAppointmentButton
