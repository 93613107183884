import React, { Component } from 'react'
import cableConsumer from '../files/cableConsumer'

class UnreadMessagesBubble extends Component {
  constructor(props) {
    super(props)
    this.state = {
      unreadMessagesCount: props.unreadMessagesCount
    }

    cableConsumer.subscriptions.create(
      { channel: 'UnreadMessagesCountChannel', conversation_id: this.props.conversationId },
      { received: this.refreshUnreadCount.bind(this) }
    )
  }

  refreshUnreadCount(unreadMessagesCount) {
    this.setState({ unreadMessagesCount })
  }

  render() {
    let count = this.state.unreadMessagesCount
    if(count == 0) return null

    let formattedCount = (count > 9) ? '9+' : count
    return(
      <div className='unread-messages-bubble' ref={ elem => this.bubble = elem }>
        { formattedCount }
      </div>
    )
  }
}

export default UnreadMessagesBubble
