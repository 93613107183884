import React, { Component } from 'react'
import { l } from 'i18n'

class BrowserDatetime extends Component {
  render() {
    const dt = new Date(this.props.datetime)
    const tzOffset = dt.getTimezoneOffset() / -60

    let formattedTime = `${l('time.formats.full', dt)} UTC`

    if (tzOffset !== 0) {
      formattedTime += `${tzOffset > 0 ? '+' : ''}${tzOffset}` // Force '+' sign for positive offset
    }

    return formattedTime
  }
}

export default BrowserDatetime
