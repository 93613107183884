import React, { Component } from 'react'
import { captureMessage } from '@sentry/browser'

class ErrorBoundary extends Component {
  componentDidCatch(error, info) {
    console.error(error)
    captureMessage(error)
  }

  render() {
    return this.props.children
  }
}

export default ErrorBoundary
