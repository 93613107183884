import "@babel/polyfill"
/* eslint no-console:0 */

import moment from 'moment'
moment.locale('lv')

// --- CSS imports
import 'bootstrap/dist/css/bootstrap.css'
import 'font-awesome/css/font-awesome.css'
import 'react-modal-video/scss/modal-video.scss'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-hint/css/index.css'
import 'rc-time-picker/assets/index.css'
// ---

import 'bootstrap'
import '../files/initializers/application'
import Rails from 'rails-ujs'

Rails.start()
