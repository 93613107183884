import React, { Component } from 'react'
import moment from 'moment'
import axios from 'axios'
import { t, locale } from 'i18n'
import Modal from 'react-modal'
import DatePicker from 'react-datepicker'
import InputWrapper from '../utilityComponents/InputWrapper'

class AbsenceModal extends Component {
  constructor(props) {
    super(props)

    this.newAbsence = {
      id: null,
      time_from: null,
      time_until: null,
      note: ''
    }

    this.state = {
      errors: {},
      absence: props.absence || this.newAbsence
    }
  }

  isExistingRecord = () => {
    return !!this.state.absence.id
  }

  isImportedFromNis = () => {
    return !!this.state.absence.imported_from_nis
  }

  isImportedFromGoogleCalendar = () => {
    return !!this.state.absence.imported_from_google_calendar
  }

  isEditable = () => {
    return !this.isImportedFromNis() && !this.isImportedFromGoogleCalendar()
  }

  saveAbsence = () => {
    const { absence } = this.state
    const existingRecord = this.isExistingRecord()

    const method = existingRecord ? 'PUT' : 'POST'

    let url = '/notary/absences'
    if (existingRecord) {
      url += `/${absence.id}`
    }

    axios({ method, url, data: { absence } }).then(
      ({ data }) => this.props.onSave(data)
    ).catch(({ response: { data } }) => {
      this.setState({ errors: data })
    })
  }

  destroyAbsence = () => {
    if (!confirm(t('components.appointments_calendar.absence_delete_prompt'))) return

    const { absence } = this.state

    axios.delete(`/notary/absences/${absence.id}`).then(() => {
      this.props.onDestroy(absence)
    }).catch(({ response: { data } }) => {
      this.setState({ errors: data })
    })
  }

  onAbsenceChange = (attr, value) => {
    let absence = { ...this.state.absence }

    absence[attr] = value

    this.setState({ absence })
  }

  handleDateBlur = ({ target: { name, value } }, dateFormat) => {
    const parsedDate = moment(value, dateFormat)
    const date = parsedDate.isValid() ? parsedDate : null

    let absence = { ...this.state.absence }

    absence[name] = date

    this.setState({ absence })
  }

  renderDateTimePicker = (attr) => {
    const { absence, errors } = this.state

    const dateFormat = t('moment_time.formats.default')
    const canEdit = this.isEditable()

    return (
      <div>
        <label className='input-label'>{t(`activerecord.attributes.notaries_absences.${attr}`)}</label>
        <InputWrapper
          className={`datepicker w-100 ${!canEdit ? 'disabled' : ''}`}
          errorArray={errors[attr]}
        >
          <DatePicker
            disabled={!canEdit}
            className='form-control'
            id={attr}
            name={attr}
            showTimeSelect
            timeFormat='HH:mm'
            timeCaption={t('components.appointments_calendar.defaults.time')}
            timeIntervals={30}
            dateFormat={dateFormat}
            selected={absence[attr] ? moment(absence[attr]) : null}
            onChange={date => this.onAbsenceChange(attr, date)}
            onBlur={e => this.handleDateBlur(e, dateFormat)}
            dropdownMode='select'
            todayButton={t('components.datepicker.today')}
            locale={locale}
          />
        </InputWrapper>
      </div>
    )
  }

  modalTitle = () => {
    if (this.isImportedFromNis()) return 'nis_absence'
    if (this.isImportedFromGoogleCalendar()) return 'google_calendar_event'

    return this.state.absence?.id ? 'edit_absence' : 'new_absence'
  }

  render = () => {
    const { isOpen, onClose } = this.props
    const { absence, errors } = this.state

    return (
      <Modal isOpen={isOpen} className='blue-modal'>
        <span className="modal-close" onClick={onClose}>
          <i className="fa fa-close fa-lg"></i>
        </span>
        <h4 className='mb-4'>
          {t(`components.appointments_calendar.${this.modalTitle()}`)}
        </h4>
        <div className='row mb-2'>
          <div className='col-6'>
            {this.renderDateTimePicker('time_from')}
          </div>
          <div className='col-6'>
            {this.renderDateTimePicker('time_until')}
          </div>
        </div>
        {this.isEditable() &&
          <>
            <div className='row mb-4'>
              <div className='col-12'>
                <InputWrapper className='' errorArray={errors.note}>
                  <label className='input-label'>{t(`activerecord.attributes.notaries_absences.note`)}</label>
                  <input
                    className='form-control'
                    type='text'
                    name='note'
                    autoComplete='off'
                    value={absence.note}
                    onChange={({ target: { name, value } }) => this.onAbsenceChange(name, value)}
                  />
                </InputWrapper>
              </div>
            </div>

            <div className='text-center'>
              <a
                href='#'
                className='btn blue-modal-btn'
                onClick={this.saveAbsence}
              >
                {t('save')}
              </a>
            </div>

            {this.isExistingRecord() &&
              <div className='text-center mt-3'>
                <a
                  href='#'
                  className='text-white'
                  onClick={this.destroyAbsence}
                >
                  <i className='fa fa-times mr-1'/>
                  {t('delete')}
                </a>
              </div>
            }
          </>
        }
      </Modal>
    )
  }
}

export default AbsenceModal
