import React, { Component, Fragment } from 'react'
import moment from 'moment'
import map from 'lodash/map'
import { t } from 'i18n'

class WarrantChecksTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      warrantChecks: props.warrantChecks
    }
  }

  renderWarrantCheckRows() {
    return this.state.warrantChecks.map(warrantCheck => {
      const rowClass = warrantCheck.show_results ? '' : 'bg-light-blue'

      return (
        <tr key={warrantCheck.id} className={rowClass}>
          <td>
            <div className='th-mobile'>{t('components.warrant_checks_table.request_time')}</div>
            {moment(warrantCheck.created_at).format(t('moment_time.formats.default'))}
          </td>
          <td>
            <div className='th-mobile'>{t('components.warrant_checks_table.search_criteria')}</div>
            {this.renderSearchFields(warrantCheck)}
          </td>
          <td>
            <div className='th-mobile'>{t('components.warrant_checks_table.status')}</div>
            {this.renderStatus(warrantCheck)}
          </td>
          <td>
            <div className='th-mobile'>{t('components.warrant_checks_table.actions')}</div>
            {this.renderActions(warrantCheck)}
          </td>
        </tr>
      )
    })
  }

  renderSearchFields(warrantCheck) {
    return warrantCheck.search_criteria.map((field, index) => {
      return <div key={index}>{field}</div>
    })
  }

  renderStatus(warrantCheck) {
    const { status, payment, show_results } = warrantCheck

    switch (status) {
      case 'in_progress': return t('components.warrant_checks_table.statuses.search_in_progress')
      case 'finished': {
        if (show_results) return t('components.warrant_checks_table.statuses.finished')

        if (payment) {
          switch (payment.status) {
            case 'unpaid':  return t('components.warrant_checks_table.statuses.unpaid')
            case 'pending': return t('components.warrant_checks_table.statuses.payment_processing')
          }
        }
      }
    }
  }

  renderActions(warrantCheck) {
    const { show_results } = warrantCheck

    if (show_results) {
      const resultsFileUrl = `/warrant_checks/${warrantCheck.id}/view_results`

      return (
        <div>
          <a href={resultsFileUrl} target='_blank'>
            {t('components.warrant_checks_table.view_response')}
          </a>
          {this.renderWarrantLinks(warrantCheck)}
        </div>
      )

    } else {
      return <a href={`/warrant_checks/${warrantCheck.id}`}>{t('open')}</a>
    }
  }

  renderWarrantLinks(warrantCheck) {
    if (warrantCheck.search_type != 'warrant_number') return
    if (!warrantCheck.results || warrantCheck.results.length == 0) return

    const result = warrantCheck.results[0]

    return (
      <div>
        <div>{this.renderWarrantLink(result)}</div>
        <div>{this.renderRevocationLink(result)}</div>
      </div>
    )
  }

  renderWarrantLink(result) {
    if (!result.document) return
    const documentUrl = `/warrant_checks/${result.warrant_check_id}/view_warrant`

    return (
      <a href={documentUrl} target='_blank'>{t('warrant_checks.results.view_warrant')}</a>
    )
  }

  renderRevocationLink(result) {
    if (!result.revoked) return

    let announcements = result.info.announcement
    if (!announcements) return

    if (announcements.constructor !== Array) {
      announcements = [announcements]
    }

    return <Fragment>
      {map(announcements, (announcement, index) => {
        if (!announcement) return null

        let announcementText
        if (announcement.announcement_url) {
          announcementText = t('warrant_checks.results.view_revocation')
        } else {
          announcementText = t('warrant_checks.results.no_announcement_url')
        }

        // announcement date
        if (announcement.announced) {
          announcementText += ` (${announcement.announced})`
        }

        return <div key={index}>
          {announcement.announcement_url && <a href={announcement.announcement_url} target='_blank'>{announcementText}</a>}
          {!announcement.announcement_url && <span className='no-announcement-text'>{announcementText}</span>}
        </div>
      })}
    </Fragment>
  }

  render() {
    return (
      <table className='table services-table warrant-checks-table table-mobile-collapse'>
        <thead>
          <tr>
            <th>{t('components.warrant_checks_table.request_time')}</th>
            <th>{t('components.warrant_checks_table.search_criteria')}</th>
            <th>{t('components.warrant_checks_table.status')}</th>
            <th>{t('components.warrant_checks_table.actions')}</th>
          </tr>
        </thead>
        <tbody>
          {this.renderWarrantCheckRows()}
        </tbody>
      </table>
    )
  }
}

export default WarrantChecksTable
