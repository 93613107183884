import React, { Component } from 'react'
import NotaryLocation from './NotaryLocation'
import NotaryListItem from './map/NotaryListItem'
import NotarySideBar from './map/NotarySideBar'
import MapComponent from './MapComponent'
import { t, locale } from 'i18n'
import { flatMap, map, filter, includes, shuffle, debounce, uniqBy, uniq } from 'lodash'
import axios from 'axios'
import { transliterate as tr } from 'transliterations'
import ErrorBoundary from './errorBoundary'
import { DebounceInput } from 'react-debounce-input'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import MediaQueries from './utilityComponents/MediaQueries'

const RIGA_COORDINATES = { lat: 56.9496487, lng: 24.1051865 }

class NotaryMap extends Component {
  constructor(props) {
    super(props)

    this.state = {
      originalNotaries: [],
      query: '',
      languageQuery: 'lv',
      countyCourt: '',
      city: '',
      selectedDate: null,
      selectedWeekday: null,
      availableWeekdays: [],
      timeOptions: [],
      timeFrom: '',
      timeUntil: '',
      notaries: [],
      placesOfPractice: [],
      sidebarNotary: null,
      sidebarOpen: true,
      userNotariesIds: this.props.userNotariesIds,
      userNotaries: [],
      orderedPlacesOfPracticeIds: [],
      loading: true,
      openedPopup: null,
      advancedSearchOpened: false,
      countyCourtCities: {},
      notaryStatuses: {}
    }
  }

  componentWillMount() {
    const { openedNotary } = this.props
    if (openedNotary) {
      this.setState({
        notaries: [openedNotary],
        originalNotaries: [openedNotary],
        sidebarNotary: openedNotary
      })
    }
  }

  componentDidMount() {
    // Should take statuses into account when sorting
    this.fetchNotaryStatuses(this.fetchRandomNotaries)
  }

  componentDidUpdate(prevProps, prevState) {
    const boundControlStates = ['query', 'languageQuery', 'sidebarNotary', 'countyCourt', 'city', 'selectedWeekday', 'timeFrom', 'timeUntil']
    if (boundControlStates.find((key) => this.state[key] !== prevState[key])) {
      // Delay filtering to increase responsiveness of inputs
      setTimeout(() => {
        const filteredPlacesOfPractice = this.filteredPlacesOfPractice()
        const notaries = uniqBy(filteredPlacesOfPractice.map(place => place.notary), 'id')

        this.setState({
          placesOfPractice: filteredPlacesOfPractice,
          notaries: notaries
        }, () => {
          this.adjustPlacesOfPracticeBounds()
        })
      }, 20)
    }
  }

  adjustPlacesOfPracticeBounds() {
    const { placesOfPractice } = this.state
    this.adjustBounds(placesOfPractice)
  }

  fetchNotaryStatuses = (callback = () => {}) => {
    axios.get('/notaries/statuses').then(({ data }) => {
      this.setState({ notaryStatuses: data }, callback)
    })
  }

  fetchRandomNotaries() {
    const { originalNotaries, userNotariesIds, userNotaries } = this.state
    const { openedNotary } = this.props

    axios.get('/notaries.json', { params: { with_includes: true } })
      .then(response => {
        response.data.forEach(notary => {
          if (openedNotary && openedNotary.id == notary.id) { return }
          if (userNotariesIds.findIndex(id => id == notary.id) > -1) {
            userNotaries.push(notary)
          } else {
            originalNotaries.push(notary)
          }
        })

        this.setState({ originalNotaries: shuffle(originalNotaries) }, () => {
          const filteredPlacesOfPractice = this.filteredPlacesOfPractice()
          const notaries = uniqBy(filteredPlacesOfPractice.map(place => place.notary), 'id')

          this.setState({
            loading: false,
            userNotaries: userNotaries,
            placesOfPractice: filteredPlacesOfPractice,
            notaries: notaries
          }, this.adjustPlacesOfPracticeBounds)
        })

        this.initializeFilterOptions(response.data)
      })
      .catch(() => {})
  }

  initializeFilterOptions = (notaries) => {
    let countyCourtCities = {}

    flatMap(notaries, notary => notary.places_of_practice).map(place => {
      const countyCourt = place.county_court.trim()
      const city = place.city.trim()

      countyCourtCities[countyCourt] = countyCourtCities[countyCourt] || []

      if (!countyCourtCities[countyCourt].includes(city)) {
        countyCourtCities[countyCourt].push(city)
      }
    })

    this.setState({ countyCourtCities })
  }

  sortedNotaries() {
    const { originalNotaries, userNotaries, sidebarNotary, notaryStatuses } = this.state
    if (sidebarNotary) return [sidebarNotary]

    const asapAvailableNotaries = originalNotaries.filter(n => n.active && notaryStatuses[n.id].ready_for_asap_appointment)
    const restActiveNotaries = originalNotaries.filter(n => n.active && !notaryStatuses[n.id].ready_for_asap_appointment)
    const inactiveNotaries = originalNotaries.filter(n => !n.active)

    return userNotaries.concat(asapAvailableNotaries).concat(restActiveNotaries).concat(inactiveNotaries)
  }

  notaryFilter = (notary) => {
    const { query, languageQuery, countyCourt } = this.state
    const places = map(notary.places_of_practice, place => place.full_address)
    const notaryNames = map(notary.former_names, former_name =>
      `${notary.first_name} ${former_name.former_surname}`
    ).concat(notary.full_name)

    const searchString = tr(places.concat(notaryNames).join(',').toLowerCase())
    if (query.length === 0 && (!notary.active || notary.is_test_notary)) return false
    if (countyCourt && notary.county_court !== countyCourt) return false
    if (query.length > 0 && !includes(searchString, tr(query.toLowerCase()))) return false
    if (notary.active && languageQuery.length > 0 && !includes(notary.known_languages.join(','), languageQuery)) return false

    return true
  }

  filteredPlacesOfPractice() {
    const { city, timeFrom, timeUntil } = this.state
    const timeFromMinutes = timeFrom && this.getMinutes(timeFrom)
    const timeUntilMinutes = timeUntil && this.getMinutes(timeUntil)

    const sortedAndFilteredNotaries = filter(this.sortedNotaries(), this.notaryFilter)

    const placesWithAnyWorkingTime = flatMap(sortedAndFilteredNotaries, notary => {
      if (!this.notaryFilter(notary)) return null
      return notary.places_of_practice.filter((pop) => {
        if (notary.active && !pop.active) return false
        if (city && pop.city !== city) return false
        return true
      }).map(place => {
        return { ...place, notary: notary }
      })
    })

    this.updateWorkingTimeFilters(placesWithAnyWorkingTime)
    return filter(placesWithAnyWorkingTime, (place) => this.notaryWorkingTimesFilter(place, timeFromMinutes, timeUntilMinutes))
  }

  getMinutes(time) {
    const momentTime = moment(time, 'H.mm')
    return momentTime.hours() * 60 + momentTime.minutes()
  }

  disabledHours = () => {
    const { timeOptions } = this.state
    const allHours = [...Array(24).keys()]

    return allHours.filter(hour => hour < minWorkingHour || hour > maxWorkingHour)
  }

  updateWorkingTimeFilters(placesOfPractice) {
    const workingTimes = flatMap(placesOfPractice, place => place.working_times.filter(wt => wt.time_from && wt.time_until))

    let weekdays = []
    let timesFrom = []
    let timesUntil = []

    workingTimes.map(wt => {
      weekdays.push(wt.weekday)
      timesFrom.push(wt.time_from_minutes)
      timesUntil.push(wt.time_until_minutes)
    })

    const minTimeMinutes = timesFrom.length > 0 ? Math.min(...timesFrom) : 0
    const maxTimeMinutes = timesUntil.length > 0 ? Math.max(...timesUntil) : 24 * 60

    let time      = moment({ hour: Math.floor(minTimeMinutes / 60), minute: minTimeMinutes % 60 })
    const maxTime = moment({ hour: Math.floor(maxTimeMinutes / 60), minute: maxTimeMinutes % 60 })

    let timeOptions = []

    while (time <= maxTime) {
      timeOptions.push(time.format('H.mm'))
      time.add(30, 'minutes')
    }

    this.setState({
      availableWeekdays: uniq(weekdays),
      timeOptions
    })
  }

  notaryWorkingTimesFilter = (place, timeFromMinutes, timeUntilMinutes) => {
    const { selectedWeekday } = this.state
    const { working_times, notary } = place

    return working_times.filter(wt => selectedWeekday ? selectedWeekday === wt.weekday : true).some(wt => {
      if (!notary.active) {
        // Show all inactive notaries if we're not looking for particular working time
        return !selectedWeekday && !timeFromMinutes && !timeUntilMinutes
      } else if (!wt.time_from_minutes || !wt.time_until_minutes) {
        // For active notaries, ignore days without set working time
        return false
      } else {
        // Check if both time_from and time_until is in working time interval
        return [timeFromMinutes, timeUntilMinutes].every(minutes => {
          if (!minutes) return true // If field is not filled, it matches
          return minutes >= wt.time_from_minutes && minutes <= wt.time_until_minutes
        })
      }
    })
  }

  onChangeSidebarNotaryById = (id) => {
    let { originalNotaries, userNotaries } = this.state
    const allNotaries = originalNotaries.concat(userNotaries)

    const notary = allNotaries[allNotaries.findIndex(n => n.id == parseInt(id))]
    this.setSidebarNotary(notary)
    return filter(withUserNotaries, this.notaryFilter)
  }

  removeSidebarNotary = () => {
    this.setState({ sidebarNotary: null })
  }

  setSidebarNotary = (sidebarNotary) => {
    this.setState({ sidebarNotary })
  }

  closePopup = () => {
    this.setState({ openedPopup: null })
  }

  renderPlacesOfPractice = (place, key, notary) => {
    if (place.gps_latitude) {
      return <NotaryLocation
        notary={notary}
        id={`place_${place.id}`}
        lat={place.gps_latitude}
        lng={place.gps_logitude}
        key={key}
        placeOfPractice={place}
        markerImage={this.props.marker_image_path}
        onMouseEnter={this.onChildMouseEnter}
        onMouseLeave={this.onChildMouseLeave}
        onClick={this.onChildClick}
        openedPopupId={this.state.openedPopup}
        closePopup={this.closePopup}
        setSidebarNotary={this.setSidebarNotary}
      />
    }
  }

  onChildMouseEnter = (childProps) => {
    const { originalNotaries, userLocation } = this.state
    const service = new google.maps.DistanceMatrixService

    this.setState({ openedPopup: childProps.id })

    let currentLat, currentLng
    if (userLocation) {
      currentLat  = userLocation.latitude
      currentLng  = userLocation.longitude
    } else {
      currentLat  = RIGA_COORDINATES.lat
      currentLng  = RIGA_COORDINATES.lng
    }
    service.getDistanceMatrix({
      origins: [{ lat: currentLat, lng: currentLng }],
      destinations: [{ lat: parseFloat(childProps.placeOfPractice.gps_latitude), lng: parseFloat(childProps.placeOfPractice.gps_logitude) }],
      travelMode: 'DRIVING',
      unitSystem: google.maps.UnitSystem.METRIC
    }, (response, status) => {
      if (status == 'OK') {
        childProps.notary.distance = response.rows[0].elements[0].distance.text
        this.setState({originalNotaries})
      } else {
        throw 'Google maps Error: ' + response
      }
    })
    let notaryItem = document.getElementById(`notary_${childProps.notary.id}`)
    // condition if user has already selected a notary
    if (notaryItem) {
      notaryItem.className = 'notary-container pointer scrolled-item'
      document.getElementById('notaries-container').scrollTop = (notaryItem.offsetTop - window.innerHeight / 2 + 100)
    }
  }

  onChildMouseLeave(childProps) {
    let notaryItem = document.getElementById(`notary_${childProps.notary.id}`)
    // condition if user has already selected a notary
    if (notaryItem) {
      notaryItem.className = 'notary-container pointer'
    }
  }

  onChildClick = (childProps) => {
    if (window.outerWidth <= 992) {
      this.setState({ openedPopup: childProps.id })
      return
    }

    this.setSidebarNotary(childProps.notary)

    if (childProps.id === this.state.openedPopup) {
      this.closePopup()
    }
  }

  adjustBounds(placesOfPractice) {
    if(this.map && placesOfPractice.length > 0) {
      if(this.fitBounds === undefined) {
        // Only initialize this once
        this.fitBounds = debounce((bounds) => { this.map.fitBounds(bounds) }, 1500)
      }
      let bounds = new google.maps.LatLngBounds()
      let latlngList = []
      placesOfPractice.forEach(pop => {
        if(pop && pop.gps_latitude && pop.gps_logitude) {
          latlngList.push(new google.maps.LatLng({lat: parseFloat(pop.gps_latitude), lng: parseFloat(pop.gps_logitude)}))
        }
      })
      latlngList.forEach(listItem => bounds.extend(listItem))

      this.fitBounds(bounds)
    }
  }

  renderMap = () => {
    const { googleMapsUrl, cluster_image_path } = this.props

    if (!googleMapsUrl) {
      return null
    }

    const { placesOfPractice } = this.state
    const renderablePlacesOfPractice = placesOfPractice.map(place => {
      if (!place) return

      // Places with equal coordinates will no longer be clustered when zoomed in
      let matchingPlaces = placesOfPractice.filter(pop => pop && pop.gps_latitude === place.gps_latitude && pop.gps_logitude === place.gps_logitude)
      if (matchingPlaces.length > 1) {
        matchingPlaces.map((place, index) => {
          const newLongitude = parseFloat(place.gps_logitude) + index / 6000
          place.gps_logitude = newLongitude
        })
      }

      return this.renderPlacesOfPractice(place, place.id, place.notary)
    })

    return <ErrorBoundary>
      <MapComponent mapRef={(map) => this.map = map}
                    googleMapURL={googleMapsUrl}
                    markers={renderablePlacesOfPractice}
                    clusterImage={cluster_image_path}
                    loadingElement={<div />}
                    containerElement={<div />}
                    mapElement={<div id='notary-map' />} />
    </ErrorBoundary>
  }

  renderNotaries = () => {
    const { submitButtonImagePath, currentUser, loginLink } = this.props
    const { loading, notaries } = this.state

    if (!loading && notaries.length === 0) return <li>{t('notaries_map.index.nothing_found')}</li>

    return notaries.map(notary =>
      <NotaryListItem
        key={notary.id}
        searchActive={!!this.state.query}
        notary={notary}
        submitButtonImagePath={submitButtonImagePath}
        currentUser={currentUser}
        loginLink={loginLink}
        onClick={this.setSidebarNotary}
      />
    )
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSelectChange = (option, name) => {
    this.setState({ [name]: option && option.value })
  }

  handleDateChange = (date) => {
    this.setState({
      selectedDate: date,
      selectedWeekday: date && date.isoWeekday()
    })
  }

  handleCountyCourtChange = (e) => {
    const countyCourt = e.target.value

    this.setState({
      countyCourt,
      city: ''
    })
  }

  filterDate = (date) => {
    return this.state.availableWeekdays.includes(date.isoWeekday())
  }

  toggleMap = (state) => {
    this.setState({ sidebarOpen: !state })
  }

  toggleAdvancedSearch = () => {
    this.setState({
      advancedSearchOpened: !this.state.advancedSearchOpened
    })
  }

  renderLoading() {
    if(this.state.loading) {
      return (
        <li className='notary-container'>
          <span className='loading-indicator'></span>
        </li>
      )
    }
  }

  renderSidebar() {
    const { sidebarNotary } = this.state
    const { isMobile } = this.props

    if (sidebarNotary) {
      const { submitButtonImagePath, currentUser, loginLink } = this.props

      return (
        <ul className='nav navbar-nav side-nav map-sidebar' id='notary-sidebar-container'>
          <NotarySideBar
            notary={sidebarNotary}
            submitButtonImagePath={submitButtonImagePath}
            currentUser={currentUser}
            loginLink={loginLink}
            onClose={this.removeSidebarNotary}
            onChangeSidebarNotaryById={this.onChangeSidebarNotaryById}
          />
        </ul>
      )
    } else {
      return (
        <ul className='nav navbar-nav side-nav map-sidebar' id='notaries-container'>
          {isMobile && this.renderMobileMenu()}
          {!isMobile &&
            <li className='has-search d-none d-lg-block'>
              <h5>{t('notaries_map.index.latvian_notaries')}</h5>
              {this.renderSearch()}
            </li>
          }
          {this.renderLoading()}
          {this.renderNotaries()}
        </ul>
      )
    }
  }

  renderSearch() {
    const { advancedSearchOpened, query } = this.state

    return (
      <div className='notary_search'>
        <div className='main-filters'>
          <DebounceInput
            id='full-query'
            name='query'
            placeholder={t('notaries_map.index.search_placeholder')}
            className='form-control search-input'
            debounceTimeout={400}
            value={query}
            onChange={this.handleChange}
          />
          <a href='#' className='more-filters' onClick={this.toggleAdvancedSearch}>
            <span>{advancedSearchOpened ? t('notaries_map.index.less') : t('notaries_map.index.more')}</span>
            <i className={`fa fa-chevron-${advancedSearchOpened ? 'up' : 'down'}`} />
          </a>
        </div>
        {this.renderAdvancedSearch()}
      </div>
    )
  }

  renderAdvancedSearch() {
    const { languageQuery, countyCourt, city, timeFrom, timeUntil, countyCourtCities } = this.state

    const languageOptions = this.props.languageSelect.map(language =>
      <option key={language[1]} value={language[1]}>{language[0]}</option>
    )

    const countyCourtOptions = Object.keys(countyCourtCities).map(court =>
      <option key={court} value={court}>{court.split(' ')[0]}</option>
    )

    const cityOptions = (countyCourtCities[countyCourt] || uniq(flatMap(Object.values(countyCourtCities)))).sort().map(city =>
      <option key={city} value={city}>{city}</option>
    )

    const timeOptions = this.state.timeOptions.map((time, idx) =>
      <option key={idx} value={time}>{time}</option>
    )

    return (
      <div className={`advanced-search ${this.state.advancedSearchOpened ? 'collapse show' : 'collapse'}`}>
        <div className='d-flex dropdowns-wrapper'>
          <div id='language-filter' className='select-wrapper mr-2'>
            <i className="fa fa-globe"></i>
            <select className='form-control' name='languageQuery' id='language-query' value={languageQuery} onChange={this.handleChange}>
              {languageOptions}
            </select>
          </div>
          <div id='county-court-filter' className='select-wrapper mr-2'>
            <select name='countyCourt' id='county-court' value={countyCourt} onChange={this.handleCountyCourtChange}>
              <option value=''>{t('notaries_map.index.county_court')}</option>
              {countyCourtOptions}
            </select>
          </div>
          <div id='city-filter' className='select-wrapper'>
            <select name='city' id='city' value={city} onChange={this.handleChange}>
              <option value=''>{t('notaries_map.index.city')}</option>
              {cityOptions}
            </select>
          </div>
        </div>
        <div className='mt-3'>
          <p className='mb-2'>{`${t('notaries_map.index.working_times')}:`}</p>
          <div className='row'>
            <div className='col-6 date-picker'>
              <DatePicker
                className='form-controls'
                ref={el => { if (el && el.input) el.input.readOnly = true }}
                placeholderText={t('notaries_map.index.date')}
                dateFormat={t('date.formats.moment')}
                selected={this.state.selectedDate}
                onChange={this.handleDateChange}
                onChangeRaw={(e) => e.preventDefault()}
                filterDate={this.filterDate}
                minDate={moment(new Date())}
                maxDate={moment(new Date()).add('1', 'years').endOf('year')}
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                isClearable={true}
                todayButton={t('components.datepicker.today')}
                withPortal={this.props.isMobile}
                locale={locale}
              />
            </div>
            <div className='col-6 d-flex time-interval'>
              <div className='select-wrapper'>
                <select name='timeFrom' value={timeFrom} onChange={this.handleChange}>
                  <option value=''>{t('notaries_map.index.time_from')}</option>
                  {timeOptions}
                </select>
              </div>
              <span className='ml-2 mr-2 text-white'>–</span>
              <div className='select-wrapper'>
                <select name='timeUntil' value={timeUntil} onChange={this.handleChange}>
                  <option value=''>{t('notaries_map.index.time_until')}</option>
                  {timeOptions}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderMobileMenu() {
    const { sidebarOpen } = this.state

    return (
      <li className='map-mobile-menu has-search d-block d-lg-none'>
        <div className="map-mobile-menu-title-row">
          {this.renderSearch()}
        </div>
        <div className="map-mobile-menu-buttons mt-4">
          <button onClick={() => this.toggleMap(true)} className={`btn map-mobile-menu-btn mr-2 ${!sidebarOpen ? 'active' : ''}`}>
            <i className="fa fa-map"></i>
            {t('notaries_map.index.search_map')}
          </button>
          <button onClick={() => this.toggleMap(false)} className={`btn map-mobile-menu-btn ${sidebarOpen ? 'active' : ''}`}>
            <i className="fa fa-list"></i>
            {t('notaries_map.index.list')}
          </button>
        </div>
      </li>
    )
  }

  render() {
    const { sidebarOpen } = this.state

    return (
      <div className={sidebarOpen ? 'sidebar-open' : 'map-open'}>
        {this.renderSidebar()}
        <div className='google-map'>
          {this.renderMap()}
        </div>
      </div>
    )
  }
}

export default MediaQueries(NotaryMap)
