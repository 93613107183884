import React, { Component } from 'react'
import { t } from 'i18n'
import axios from 'axios'

class ArticleCardsBlock extends Component {
  constructor(props) {
    super(props)
    this.state = {
      articles: JSON.parse(props.passedArticles),
      isLoading: false
    }
    this.skippableArticleId = props.skippableArticleId || null

    this.fetchMoreArticles = this.fetchMoreArticles.bind(this)
  }
  articlesParams() {
    const { articles } = this.state
    return {
      offset_id: articles[articles.length -1].id,
      skippable_article_id: this.skippableArticleId
    }
  }

  articleMaxCount() {
    if(this.skippableArticleId) {
      // Means that one article is in show mode and
      // should not be displayed in cards
      return this.props.totalCount - 1
    } else {
      return this.props.totalCount
    }
  }
  fetchMoreArticles() {
    const { articles } = this.state

    this.setState({isLoading: true})
    axios.get(`/articles.json`, { params: this.articlesParams() }).then( (response) => {
      if (response.status == 200){
        let newArticles = articles
        for(let article of response.data) {
          newArticles.push(article)
        }
        this.setState({articles: newArticles})
      }
      this.setState({isLoading: false})
    })
  }

  renderArticle(article) {
    return(
      <div className='col-sm-4 col-12 custom-card' key={article.id}>
        <a href={article.url}>
          <div className='image-container'>
            <div className='card-img-top custom-card-image'>
              <img src={article.thumb_image_url} />
            </div>
          </div>
          <div className='card-block'>
            <div className='title'>
              {article.title}
            </div>
          </div>
        </a>
      </div>
    )
  }

  renderReadMoreButton() {
    if(this.props.noMoreButton || this.state.articles.length == this.articleMaxCount()) {
     return
    }
    if(this.state.isLoading){
      return(
        <div className='row'>
          <div className='text-center col-12'>
            <span className='loading-indicator'></span>
          </div>
        </div>
      )
    } else {
      return (
        <div className='row'>
          <div className='text-center col-12'>
            <span onClick={this.fetchMoreArticles} className='btn main-page-button'>
              {t('main.articles.more')}
            </span>
          </div>
        </div>
      )
    }
  }

  render() {
    const articles = this.state.articles.map(article => this.renderArticle(article))

    return(
      <div id='articles-cards'>
        <div className='row'>
          {articles}
        </div>
        {this.renderReadMoreButton()}
      </div>
    )
  }
}

export default ArticleCardsBlock
