import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/content/default/content.css'
import 'tinymce/themes/silver/theme.js'
import 'tinymce/icons/default/icons.js'
import 'tinymce/plugins/link/plugin.js'
import 'tinymce/plugins/image/plugin.js'
import 'tinymce/plugins/imagetools/plugin.js'
import 'tinymce/plugins/code/plugin.js'

tinymce.init({
  selector: '.editor',
  valid_elements: '*[*]',
  extended_valid_elements: '*[*],script[charset|defer|language|src|type]',
  custom_elements: '*[*],script[charset|defer|language|src|type]',
  plugins: ['link', 'image', 'imagetools', 'code'],
  automatic_uploads: true,
  entity_encoding: 'raw',
  skin: false,
  content_css: false,
  theme: 'silver',
  images_upload_credentials: true,
  relative_urls: false,
  verify_html: false,
  media_strict: false,
  init_instance_callback: (editor) => {
    // jw: this code is heavily borrowed from tinymce.jquery.js:12231 but modified so that it will
    //     just remove the escaping and not add it back.
    editor.serializer.addNodeFilter('script', (nodes, name) => {
      let i = nodes.length, node, value, type

      function trim(value) {
        /*jshint maxlen:255 */
        /*eslint max-len:0 */
        return value.replace(/(<!--\[CDATA\[|\]\]-->)/g, '\n')
          .replace(/^[\r\n]*|[\r\n]*$/g, '')
          .replace(/^\s*((<!--)?(\s*\/\/)?\s*<!\[CDATA\[|(<!--\s*)?\/\*\s*<!\[CDATA\[\s*\*\/|(\/\/)?\s*<!--|\/\*\s*<!--\s*\*\/)\s*[\r\n]*/gi, '')
          .replace(/\s*(\/\*\s*\]\]>\s*\*\/(-->)?|\s*\/\/\s*\]\]>(-->)?|\/\/\s*(-->)?|\]\]>|\/\*\s*-->\s*\*\/|\s*-->\s*)\s*$/g, '')
      }
      while (i--) {
        node = nodes[i];
        value = node.firstChild ? node.firstChild.value : ''

        if (value.length > 0) {
          node.firstChild.value = trim(value)
        }
      }
    })
  },
  images_upload_handler: (blobInfo, success, failure, progress) => {
    // Patch upload handler source with custom upload URL and CSRF token header
    const imagesUploadUrl = '/admin/cms_management/images'
    const csrfHeader = 'X-CSRF-Token'
    const csrfTag    = document.querySelector('meta[name="csrf-token"]')
    const csrfToken  = csrfTag && csrfTag.getAttribute('content')

    let xhr, formData

    xhr = new XMLHttpRequest()

    xhr.withCredentials = false
    xhr.open('POST', imagesUploadUrl)

    xhr.setRequestHeader(csrfHeader, csrfToken)

    xhr.upload.onprogress = function (e) {
      progress(e.loaded / e.total * 100)
    }

    xhr.onload = function() {
      let json

      if (xhr.status === 403) {
        failure('HTTP Error: ' + xhr.status, { remove: true })
        return
      }

      if (xhr.status < 200 || xhr.status >= 300) {
        failure('HTTP Error: ' + xhr.status)
        return
      }

      json = JSON.parse(xhr.responseText);

      if (!json || typeof json.location != 'string') {
        failure('Invalid JSON: ' + xhr.responseText)
        return
      }

      success(json.location)
    }

    xhr.onerror = function () {
      failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status)
    }

    formData = new FormData()
    formData.append('file', blobInfo.blob(), blobInfo.filename())

    xhr.send(formData)
  }
})
