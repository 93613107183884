import moment from 'moment'
import tz from 'moment-timezone'
import { t } from 'i18n'

function stringifiedTimeInterval(timeFrom, timeUntil) {
  let defaultValue = t('notaries.show.by_note')
  if(timeFrom) {
    let from = toRigaTimezone(moment(timeFrom)).format(t('moment_time.formats.short'))
    let until = toRigaTimezone(moment(timeUntil)).format(t('moment_time.formats.short'))
    return `${from} - ${until}`
  } else {
    return defaultValue
  }
}

const currentUserId = () => {
  const $metaTag = document.querySelector('meta[name=user-id]')
  const userId   = $metaTag && $metaTag.content

  if (userId) {
    return parseInt(userId)
  } else {
    return null
  }
}

const objectToFormData = (obj, form, namespace) => {
  let fd = form || new FormData()
  let formKey

  for(let property in obj) {
    if(obj.hasOwnProperty(property) && obj[property] != 'undefined') {
      if (namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property
      }

      // if the property is an object, but not a File, use recursivity.
      if (obj[property] instanceof Date) {
        fd.append(formKey, obj[property].toISOString())
      }
      else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        objectToFormData(obj[property], fd, formKey)
      } else { // if it's a string or a File object
        fd.append(formKey, obj[property])
      }
    }
  }

  return fd
}

const toRigaTimezone = (date) => {
  return date.tz('Europe/Riga')
}

export function getMetaValue(name) {
  const metaTag = document.querySelector(`meta[name='${name}']`)
  return metaTag ? metaTag.getAttribute('content') : null
}

export { stringifiedTimeInterval, currentUserId, objectToFormData, toRigaTimezone }
