import React, { Component } from 'react'
import axios from 'axios'
import { t } from 'i18n'
import cableConsumer from '../../files/cableConsumer'
import ConversationsList from './ConversationsList'
import Conversation from './Conversation'

class Messenger extends Component {
  state = { conversationId: 'none' }

  componentDidMount() {
    const { conversationId } = this.props

    if (conversationId) {
      this.openConversation(conversationId)
    }
  }

  componentWillUnmount() {
    window.activeConversationId = null
  }

  async openConversation(conversationId, recipients, notary_id = null) {
    if (!conversationId) {
      let params = {}

      if (notary_id) {
        params.notary_id = notary_id
      } else if (recipients) {
        params.recipient_ids = recipients.map(r => r.id)
      }
      const { data } = await axios.post('/messenger/conversations', params)
      conversationId = data.id
    }

    if (!this.props.conversationOnly) {
      history.replaceState({}, null, '/messenger?conversation_id=' + conversationId)
    }

    window.activeConversationId = conversationId
    this.setState({ conversationId })
  }

  renderConversation(conversationActive) {
    const { conversationId, recipients } = this.state

    let classNames = 'messages-container-wrapper'
    if (!conversationActive) {
      classNames += ' d-none d-lg-block'
    }

    if (conversationId !== 'none') {
      return(
        <div className={classNames}>
          <Conversation cableConsumer={cableConsumer}
                        conversationId={conversationId}
                        recipients={recipients}
                        userId={this.props.userId}
                        openConversation={this.openConversation.bind(this)}
                        conversationOnly={this.props.conversationOnly} />
        </div>
      )
    } else {
      return(
        <div className={classNames}>
          <div className='empty-conversation'><div className="logo"></div>{t('messenger.empty')}</div>
        </div>
      )
    }
  }

  renderConversationsList(conversationActive) {
    if (this.props.conversationOnly) {
      return null
    }

    let classNames = 'messages-list-container-wrapper'
    if (conversationActive) {
      classNames += ' d-none d-lg-block'
    }

    return(
      <div className={classNames}>
        <ConversationsList cableConsumer={cableConsumer}
                           openConversation={this.openConversation.bind(this)}
                           activeConversationId={this.state.conversationId}
                           userId={this.props.userId} />
      </div>
    )
  }

  render() {
    const conversationActive = this.state.conversationId !== 'none'

    return(
      <div className='messenger-container'>
        {this.renderConversationsList(conversationActive)}
        {this.renderConversation(conversationActive)}
      </div>
    )
  }
}

export default Messenger
