import React, { Component } from 'react'
import { t } from 'i18n'
import { stringifiedTimeInterval } from '../../files/helpers'

class PlaceOfPracticeWorkingSchedule extends Component {

  notaryWorkingPlace() {
    const { place } = this.props
    let days = Object.values(t('date.day_names'))
    days.push(days.shift())
    return days.map((translatedDay, index) => {
      let workingTime = place.working_times.find(wt => wt.weekday == index + 1)
      if(workingTime && workingTime.open){
        return (
          <div key={index}>
            <div className='working-time'>
              <div className='weekday'>{days[index]}</div>
              <div className='working-time'>
                {stringifiedTimeInterval(workingTime.time_from, workingTime.time_until)}
              </div>
            </div>
            {this.notaryLunchTime(workingTime)}
          </div>
        )
      } else {
        return (
          <div key={index} className='working-time'>
            <div className='not-working'>{translatedDay}</div>
            <div className='not-working'>{t('notaries.show.not_working')}</div>
          </div>
        )
      }
    })
  }

  notaryLunchTime(workingTime) {
    if(workingTime.pause_from && workingTime.pause_until) {
      return (
        <div className='launch-time'>
          {t('notaries.show.pause')}
          <div className='pause-time'>
            {stringifiedTimeInterval(workingTime.pause_from, workingTime.pause_until)}
          </div>
        </div>
      )
    }
  }

  render() {
    const { place } = this.props
    return(
      <div className='info-container'>
        <h6>{place.address}</h6>
        {this.notaryWorkingPlace()}
      </div>
    )
  }
}
export default PlaceOfPracticeWorkingSchedule
