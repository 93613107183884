import React from 'react'
import axios from 'axios'
import debounce from 'debounce'
import { t } from 'i18n'
import escapeRegExp from 'lodash/escapeRegExp'

class Searcher extends React.Component {
  state = {
    messages: [],
    users:    [],
    loading: true,
    searchRequest: null
  }

  componentWillMount() {
    this.performSearch = debounce(this.performSearch, 150)
    this.performSearch(this.props.searchPhrase)
  }

  componentWillUnmount() {
    this.cleanupActiveSearchRequest()
  }

  componentWillReceiveProps({ searchPhrase }) {
    this.setState({ loading: true })
    this.performSearch(searchPhrase)
  }

  cleanupActiveSearchRequest() {
    const { searchRequest } = this.state

    if (searchRequest) {
      searchRequest.cancel()
    }
  }

  performSearch(searchPhrase) {
    this.cleanupActiveSearchRequest()

    const searchRequest = axios.CancelToken.source()
    this.setState({ loading: true, searchRequest })

    axios.get('/messenger/search', { params: { q: searchPhrase }, cancelToken: searchRequest.token }).then(({ data }) => {
      const { messages, users, users_type } = data
      this.setState({ loading: false, usersType: users_type, messages, users })
    }).catch((error) => {
      console.error(error)
    })
  }

  boldifyMatchingText(searchPhrase, body, regex) {
    if (searchPhrase) {
      return body.split(regex).map((string, i) => {
        return <span className={string.match(regex) && 'font-weight-bold'} key={i}>{string}</span>
      })
    } else {
      return body
    }
  }

  renderUsers() {
    const { openConversation } = this.props
    return this.state.users.map(user => {
      const { name, surname, notary_id, conversation_id, id, image_url, notary_name, role } = user

      let additionalText = ''
      if (role) {
        additionalText = t(`messenger.searcher.role_${role}`, { notary: notary_name })
      }

      return(
        <div className='search-result-user' key={`${id}-${notary_id}`} data-notary-id={notary_id} onClick={() => openConversation(conversation_id, [user], notary_id)}>
          <div className="search-result-user-image-wrapper">
            <img className='img-fluid search-result-user-image' src={image_url} />
          </div>
          <p>{name} {surname} {additionalText}</p>
        </div>
      )
    })
  }

  renderMessages() {
    const { searchPhrase, openConversation } = this.props
    const regex = new RegExp(`(${escapeRegExp(searchPhrase)})`, 'gi')

    return this.state.messages.map(message => {
      const { body, id, conversation_id, sender } = message
      const resultText = this.boldifyMatchingText(searchPhrase, body, regex)

      return(
        <div className='search-result-text' key={id} onClick={() => openConversation(conversation_id, null)}>
          <span>
            {sender}: <span className='font-italic'> {resultText}</span>
          </span>
        </div>
      )
    })
  }

  render() {
    const { messages, users, usersType, loading } = this.state
    const hasUsers    = users && users.length > 0
    const hasMessages = messages && messages.length > 0

    return(
      <div>
        {loading && <span className='loading-indicator' />}
        {!loading && !hasUsers && !hasMessages && <span>{t('messenger.conversations.no_results_found')}</span>}
        {hasUsers && <h3 className="search-results-title">{t(`messenger.conversations.${usersType}`)}</h3>}
        {this.renderUsers()}
        {hasMessages && <h3 className="search-results-title">{t('messenger.conversations.messages')}</h3>}
        {this.renderMessages()}
      </div>
    )
  }
}

export default Searcher
