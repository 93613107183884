import React from 'react'
import { l } from 'i18n'
import map from 'lodash/map'
import { getMetaValue } from '../../files/helpers'

const ActivityRow = (props) => {
  const {
    activity: { act_description, date, notary_name, number, act_files }
  } = props

  const hasFiles = act_files?.length > 0
  const authenticityToken = getMetaValue('csrf-token') || ''

  return (
    <tr>
      <td>{date ? l('date.formats.default', date) : null}</td>
      <td>{number}</td>
      <td>{notary_name}</td>
      <td>{act_description}</td>
      <td>
        {!hasFiles && '-'}
        {hasFiles && map(act_files, act_file =>
          <form key={act_file.nis_act_file_uid} target='_blank' action='/user/activities/download_act_file' method='post'>
            <input type='hidden' name='authenticity_token' value={authenticityToken} />
            <input type='hidden' name='nis_act_file_uid' value={act_file.nis_act_file_uid} />
            <button type='submit' className='btn-file-download text-white'>
              <i className='icon fa fa-download' />
              <span className='label'>{act_file.act_file_name}</span>
            </button>
          </form>
        )}
      </td>
    </tr>
  )
}

export { ActivityRow }
