import React from 'react'

const TransferInformation = (props) => {
  const { place } = props
  return(
    <div className='info-container transfer-information'>
      <div>
        <span dangerouslySetInnerHTML={{__html: place.transfer_information}}></span>
      </div>
    </div>
  )
}
export default TransferInformation
