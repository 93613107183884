import React, { Component } from 'react'
import { t } from 'i18n'

class FileInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      destroyed: props.file.destroyed
    }

    this.destroyFile = this.destroyFile.bind(this)
  }
  attributeName(name) {
    const { namePrefix, file } = this.props
    return `${namePrefix}[${file.uniqueId}][${name}]`
  }

  attributeId(name) {
    return this.attributeName(name).replace(/\[/g, '_').replace(/\]/g, '')
  }

  wrapperClassName() {
    let className = 'form-inline file-input'
    if(this.state.destroyed) {
      className += ' d-none'
    }
    return className
  }

  destroyFile(e) {
    e.preventDefault()
    this.setState({destroyed: true})
  }

  renderDestroyInput() {
    if(this.state.destroyed) {
      return(
        <input type='hidden' name={this.attributeName('_destroy')} defaultValue={this.state.destroyed} />
      )
    }
  }
  renderIdInput() {
   if(this.props.file.id) {
     return(
       <input type='hidden' name={this.attributeName('id')} defaultValue={this.props.file.id} />
     )
   }
  }
  render() {
    const { file, acceptString } = this.props

    return (
      <div className={this.wrapperClassName()}>
        {this.renderIdInput()}
        {this.renderDestroyInput()}
        <div className='form-group'>
          <label className='control-label' name={this.attributeName('name')} htmlFor={this.attributeId('name')}>
            {t('activerecord.attributes.resource_attachment.name')}
          </label>
          <input
            type='text'
            className='form-control'
            name={this.attributeName('name')}
            id={this.attributeId('name')}
            defaultValue={file.name} />
        </div>
        <div className='form-group'>
          <label className='control-label' name={this.attributeName('file')} htmlFor={this.attributeId('file')}>
            {t('activerecord.attributes.resource_attachment.file')}
          </label>
          <input
            type='file'
            className='form-control'
            name={this.attributeName('file')}
            defaultValue={null}
            id={this.attributeId('file')}
            accept={acceptString}
            />
          <a href='#' className='btn btn-danger btn-sm' onClick={this.destroyFile}><i className="fa fa-trash mr-1"></i>{t('delete')}</a>
        </div>

      </div>
    )
  }
}

export default FileInput
