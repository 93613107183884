import React from 'react'
import { t } from 'i18n'
import moment from 'moment'

const scope = 'components.inheritance_check'

const InheritanceCheckRows = ({ inheritanceChecks }) => {
  return inheritanceChecks.map(inheritanceCheck =>
    <tr className='bg-light-blue' key={`check-${inheritanceCheck.id}`}>
      <td>
        <div className='th-mobile'>{t('request_time', { scope })}</div>
        {moment(inheritanceCheck.createdAt).format(t('moment_time.formats.default'))}
      </td>
      <td>
        <div className='th-mobile'>{t('search_criteria', { scope })}</div>
        <SearchFields inheritanceCheck={inheritanceCheck} />
      </td>
      <td>
        <div className='th-mobile'>{t('actions', { scope })}</div>
        <a href={`/inheritance_checks/${inheritanceCheck.id}`}>{t('view', { scope })}</a>
      </td>
    </tr>
  )
}

const SearchFields = ({ inheritanceCheck }) => {
  if(inheritanceCheck.personalCode) {
    return (
      <div> {t('personal_code', { scope })}: {inheritanceCheck.personalCode} </div>
    )
  } else {
    return (
      <>
        <div>{t('first_name', { scope })}: {inheritanceCheck.firstName} </div>
        <div>{t('last_name', { scope })}: {inheritanceCheck.lastName} </div>
        <div>{t('date_of_birth_from', { scope })}: {inheritanceCheck.dateOfBirthFrom}</div>
        <div>{t('date_of_birth_to', { scope })}: {inheritanceCheck.dateOfBirthTo}</div>
      </>
    )
  }
}

const InheritanceHistoryTable = ({ inheritanceChecks }) => {
  return(
    <table className='table services-table inheritance-history-table table-mobile-collapse'>
      <thead>
        <tr>
          <th>{t('request_time', { scope })}</th>
          <th>{t('search_criteria', { scope })}</th>
          <th>{t('actions', { scope })}</th>
        </tr>
      </thead>
      <tbody>
        <InheritanceCheckRows inheritanceChecks={inheritanceChecks}/>
      </tbody>
    </table>
  )
}

export default InheritanceHistoryTable
