import React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer'

const LATVIA_COORDINATES = { lat: 56.8343957, lng: 24.5525848 }

const clusterStyles = (url) => {
  const textColor = '#FFF'

  return [
    { height: 60,  width: 60,  textSize: 11, url, textColor },
    { height: 70,  width: 70,  textSize: 12, url, textColor },
    { height: 80,  width: 80,  textSize: 13, url, textColor },
    { height: 90,  width: 90,  textSize: 14, url, textColor },
    { height: 100, width: 100, textSize: 15, url, textColor },
    { height: 110, width: 110, textSize: 16, url, textColor },
    { height: 120, width: 120, textSize: 17, url, textColor },
    { height: 130, width: 130, textSize: 18, url, textColor }
  ]
}

const calculator = (markers, stylesCount) => {
  const markerCount = markers.length

  switch (true) {
    case (markerCount < 3):   return { text: markerCount, index: 0 }
    case (markerCount < 6):   return { text: markerCount, index: 1 }
    case (markerCount < 11):  return { text: markerCount, index: 2 }
    case (markerCount < 18):  return { text: markerCount, index: 3 }
    case (markerCount < 25):  return { text: markerCount, index: 4 }
    case (markerCount < 35):  return { text: markerCount, index: 5 }
    case (markerCount < 50):  return { text: markerCount, index: 6 }
    case (markerCount >= 50): return { text: markerCount, index: 7 }
  }
}

const MapComponent = withScriptjs(withGoogleMap(props => {
  const defaultZoom = window.outerWidth > 992 ? 7 : 6

  return(
    <GoogleMap ref={props.mapRef}
               googleMapURL={props.googleMapURL}
               options={{maxZoom: 19}}
               defaultZoom={defaultZoom}
               defaultCenter={LATVIA_COORDINATES}
               loadingElement={props.loadingElement}
               containerElement={props.containerElement}
               mapElement={props.mapElement}
               defaultOptions={{ zoomControlOptions: { position: google.maps.ControlPosition.TOP_RIGHT } }}
               onBoundsChanged={() => window.dispatchEvent(new Event('resize'))}>
      <MarkerClusterer averageCenter
                       enableRetinaIcons
                       gridSize={60}
                       maxZoom={18}
                       styles={clusterStyles(props.clusterImage)}
                       calculator={calculator}>
        {props.markers}
      </MarkerClusterer>
    </GoogleMap>
  )
}))

export default MapComponent
