import React, { Component } from 'react'
import Select from 'react-select'
import axios from 'axios'
import { t } from 'i18n'
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc'

class SortableSignatures extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedSignatures: this.props.projectUsers.map((user, index) => { return { position: index + 1 }})
    }

    this.renderSignature = this.renderSignature.bind(this)
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    let sortedSignatures = [...this.state.sortedSignatures]
    sortedSignatures = arrayMove(sortedSignatures, oldIndex, newIndex)

    sortedSignatures.map((signature, index) => {
      signature.position = index + 1
    })

    this.setState({ sortedSignatures })
  }

  handleSignerChange(selectedUser, signature) {
    Object.assign(signature, {
      signer_obj: selectedUser,
      signer_id: selectedUser && selectedUser.value,
      signer_name: selectedUser && selectedUser.label
    })

    this.setState({
      sortedSignatures: this.state.sortedSignatures
    })
  }

  beginSigningStep() {
    const { projectItem, subscription } = this.props

    axios.put(`/projects/${projectItem.projectId}/project_items/${projectItem.id}/begin_signing_step`, {
      signed_document: {
        ordered_signer_ids: this.state.sortedSignatures.filter(s => !!s.signer_id).sort((s1, s2) => s1.position - s2.position).map(s => s.signer_id)
      }
    }).then(() => {
      subscription.send({})
    })
  }

  unselectedProjectUsers() {
    const selectedSignatures = this.state.sortedSignatures.filter(s => !s._destroy)

    return this.props.projectUsers.filter(u =>
      !selectedSignatures.map(s => s.signer_id).includes(u.value)
    )
  }

  renderSignature(signature) {
    return (
      <Select
        classNamePrefix='react-select'
        className='react-select'
        placeholder={t('choose')}
        value={signature.signer_obj}
        onChange={val => this.handleSignerChange(val, signature)}
        options={this.unselectedProjectUsers()}
      />
    )
  }

  render() {
    const { sortedSignatures } = this.state

    return (
      <div className='video-room-sidebar-item-block-sortable-signatures'>
        <SortableList
          items={sortedSignatures}
          onRender={this.renderSignature}
          onSortEnd={this.onSortEnd}
          useDragHandle={true}
        />
        <button
          type='button'
          className='btn video-room-sidebar-btn d-block w-100 mb-2'
          onClick={this.beginSigningStep.bind(this)}
          disabled={sortedSignatures.length == 0}
        >
          {t('components.project.sidebar_steps.signing_block.enable_signing_phase')}
        </button>
      </div>
    )
  }
}

const DragHandle = SortableHandle(() => <i className='fa fa-bars movable'/>)

const SortableItem = SortableElement(({ item, onRender }) => {
  return (
    <li className='signature movable-signature'>
      <div className='d-flex'>
        <DragHandle/>
        <div className='user-select-wrapper'>{onRender(item)}</div>
      </div>
    </li>
  )
})

const SortableList = SortableContainer(({ items, onRender }) => {
  return (
    <ol className='signatures-list'>
      {items.map((item, index) => {
        if (!!item._destroy) return

        return (
          <SortableItem
            key={`item-${index}`}
            index={index}
            item={item}
            onRender={onRender}
          />
        )
      })}
    </ol>
  )
})

export default SortableSignatures
