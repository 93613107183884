import React, { Component } from 'react'
import axios from 'axios'
import ModalVideo from 'react-modal-video'
import { t } from 'i18n'

class VideoCardsBlock extends Component {
  constructor(props) {
    super(props)
    this.state = {
      videos: JSON.parse(props.passedVideos),
      isLoading: false,
      modalVideoId: null,
      modalIsOpen: false
    }
    this.fetchMoreVideos = this.fetchMoreVideos.bind(this)
    this.openModal       = this.openModal.bind(this)
  }
  videosParams() {
    const { videos } = this.state
    return {
      offset_id: videos[videos.length -1].id,
    }
  }

  fetchMoreVideos() {
    const { videos } = this.state

    this.setState({isLoading: true})
    axios.get(`/videos.json`, { params: this.videosParams() }).then( (response) => {
      if (response.status == 200){
        let newVideos = videos
        for(let video of response.data) {
          newVideos.push(video)
        }
        this.setState({videos: newVideos})
      }
      this.setState({isLoading: false})
    })
  }

  openModal(e, videoId) {
    e.preventDefault()

    this.setState({
      modalVideoId: videoId,
      modalIsOpen: true
    })
  }

  renderVideo(video) {
    return(
      <div className='col-lg-4 col-md-6 col-sm-12 col-12 custom-card' key={video.id}>
        <a href='#' onClick={(e) => this.openModal(e, video.video_id)}>
          <div className='image-container'>
            <div className='card-img-top custom-card-image'>
              <img src={video.thumbnail_url} />
            </div>
          </div>
          <div className='card-block'>
            <div className='title'>
              {video.title}
            </div>
          </div>
        </a>
      </div>
    )
  }

  renderShowMoreButton() {
    if(this.props.noMoreButton || this.state.videos.length == this.props.totalCount) {
     return
    }
    if(this.state.isLoading){
      return(
        <span className='loading-indicator'></span>
      )
    } else {
      return (
        <span onClick={this.fetchMoreVideos} className='btn main-page-button'>
          {t('main.videos.more')}
        </span>
      )
    }
  }

  render() {
    const videos = this.state.videos.map(video => this.renderVideo(video))

    return(
      <div id='videos-cards'>
        <div className='container'>
          <div className='row'>
            {videos}
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="text-center col-12 main-page-button-container">
              {this.renderShowMoreButton()}
            </div>
          </div>
        </div>
        <ModalVideo
          channel='youtube'
          isOpen={this.state.modalIsOpen}
          videoId={this.state.modalVideoId}
          onClose={() => this.setState({modalIsOpen: false})} />
      </div>
    )
  }
}

export default VideoCardsBlock
