import React, { Component } from 'react'
import axios from 'axios'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import { t, locale } from 'i18n'
import Payment from '../Payment'
import { renderBlockStatusIcon, refreshTooltips, openCollapse } from './commonFunctions'
import { objectToFormData } from '../../../files/helpers'

class PaymentBlock extends Component {
  constructor(props) {
    super(props)

    const payments = this.props.projectItem.payments

    this.state = {
      tooltipHidden: false,
      collapseOpened: props.projectItem.paymentBlockActive,
      paymentAmount: '',
      paymentDate: moment(new Date()),
      paymentNumber: '',
      paymentUser: (props.projectUsers && props.projectUsers.length === 1) ? props.projectUsers[0] : null,
      newPayment: !payments.length,
      paymentErrors: [],
      payments
    }

    this.renderBlockStatusIcon = renderBlockStatusIcon.bind(this)
    this.refreshTooltips = refreshTooltips.bind(this)
    this.openCollapse = openCollapse.bind(this)
    this.activeFlag = 'paymentBlockActive'
  }

  componentWillReceiveProps(nextProps) {
    const payments = nextProps.projectItem.payments

    this.setState({ payments: payments, newPayment: !payments.length })
    this.refreshTooltips(nextProps)
    if (this.props.isNotary) this.openCollapse(nextProps)
  }

  addNewPayment() {
    this.setState({ newPayment: true })
  }

  onPaymentInputChange(field, e) {
    this.setState({ [field]: e.target.value })
  }

  onPaymentDocumentChange(e) {
    const paymentDocument = e.target.files[0]

    if (paymentDocument) this.setState({ paymentDocument })
  }

  removeDocument() {
    this.setState({ paymentDocument: null })
  }

  onDateChange(date) {
    this.setState({ paymentDate: moment(date, t('date.formats.moment')) })
  }

  onUserChange(user) {
    this.setState({ paymentUser: user })
  }

  createPayment() {
    const { saving, paymentAmount, paymentDocument, paymentDate, paymentNumber, paymentUser } = this.state
    const { projectItem } = this.props

    if (saving) return

    this.setState({ saving: true })

    const params = {
      payment: {
        amount: paymentAmount,
        document: paymentDocument,
        date: paymentDate.format(t('date.formats.moment')),
        number: paymentNumber,
        user_id: paymentUser.value
      }
    }

    axios.post(`/project_items/${projectItem.id}/payments`, objectToFormData(params)).then(() => {
      this.setState({
        saving: false,
        newPayment: false,
        paymentAmount: '',
        paymentDate: moment(new Date()),
        paymentNumber: '',
        paymentDocument: null,
        paymentErrors: []
      })

      this.props.subscription.send({})
    }).catch(e => {
      this.setState({ paymentErrors: e.response.data, saving: false })
    })
  }

  isDisabled() {
    return !this.props.projectItem.paymentBlockActive
  }

  isBlockActive() {
    return this.props.projectItem[this.activeFlag]
  }

  renderPaymentErrors() {
    if (this.state.paymentErrors.length === 0) return

    const errors = this.state.paymentErrors.map((error, index) => {
      return <div key={index}>{error}</div>
    })

    return(
      <div className='video-room-sidebar-item-note red'>
        {errors}
      </div>
    )
  }

  renderPaymentUserSelect() {
    const { projectUsers } = this.props
    if (projectUsers.length === 1) return

    const { paymentUser } = this.state

    return (
      <Select
        classNamePrefix='react-select'
        className='react-select video-room-sidebar-item-select mb-4'
        options={projectUsers}
        placeholder={`${t('components.project.sidebar_steps.payment_block.payment_user')} *`}
        onChange={this.onUserChange.bind(this)}
        value={paymentUser}
      />
    )
  }

  renderNewPayment() {
    if (!this.props.isNotary) return

    const { isMobile } = this.props
    const { newPayment, paymentAmount, paymentDocument, paymentDate, paymentNumber } = this.state

    if (newPayment) {
      return(
        <div>
          <div className='video-room-sidebar-item-note'>
            <strong className='video-room-sidebar-item-block-text'>{t('components.project.sidebar_steps.payment_block.payment_data')}</strong>
          </div>

          {this.renderPaymentErrors()}

          <div className='video-room-sidebar-item-block mt-3'>
            <div className='mb-4'>
              {this.renderDocument(paymentDocument)}
              {this.renderPaymentUserSelect()}

              <div className='payment-datepicker'>
                <DatePicker
                  className='form-control video-room-sidebar-item-input mb-4'
                  selected={paymentDate}
                  onChange={this.onDateChange.bind(this)}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                  disabled={this.isDisabled()}
                  dateFormat={t('date.formats.moment')}
                  todayButton={t('components.datepicker.today')}
                  ref={el => { if (isMobile && el && el.input) el.input.readOnly = true }}
                  withPortal={isMobile}
                  locale={locale}
                />
              </div>

              <input
                className='form-control video-room-sidebar-item-input mb-4'
                placeholder={`${t('activerecord.attributes.payment.number')} *`}
                value={paymentNumber}
                onChange={this.onPaymentInputChange.bind(this, 'paymentNumber')}
                disabled={this.isDisabled()}
              />

              <input
                className='form-control video-room-sidebar-item-input mb-4'
                placeholder={`${t('activerecord.attributes.payment.amount')}, € *`}
                value={paymentAmount}
                type='number'
                step='any'
                onChange={this.onPaymentInputChange.bind(this, 'paymentAmount')}
                disabled={this.isDisabled()}
              />
            </div>

            {this.renderDocumentForm()}
            {this.renderSaveButton()}
          </div>
        </div>
      )
    } else {
      return(
        <div className='video-room-sidebar-item-block'>
          <button className='btn video-room-sidebar-btn d-block w-100 mb-2' onClick={this.addNewPayment.bind(this)}>
            <i className='fa fa-plus mr-2' />
            {t('components.project.sidebar_steps.payment_block.new_payment')}
          </button>
        </div>
      )
    }
  }

  renderDocumentForm() {
    return(
      <div>
        <label htmlFor='payment-document' className={`btn video-room-sidebar-btn d-block w-100 mb-2 ${this.isDisabled() && 'disabled'}`}>
          <i className='fa fa-upload mr-2' />
          {t('components.project.sidebar_steps.payment_block.upload')}
        </label>

        <input id='payment-document'
               className='video-room-sidebar-item-file-input'
               type='file'
               onChange={this.onPaymentDocumentChange.bind(this)}
               disabled={this.isDisabled()} />
      </div>
    )
  }

  renderDocument(paymentDocument) {
    if (!paymentDocument) return

    return(
      <div className='ml-2 mb-4 flexbox'>
        <div className='payment-document-name'>
          <strong className='video-room-sidebar-item-block-text d-inline-block'>{paymentDocument.name}</strong>
        </div>
        <i className='fa fa-times ml-3 pointer video-room-sidebar-item-block-text opaque d-inline-block'
           onClick={this.removeDocument.bind(this)} />
      </div>
    )
  }

  renderSaveButton() {
    if (this.state.saving) {
      return(
        <button className='btn video-room-sidebar-btn d-block w-100 mb-2'>
          <i className='fa fa-spinner fa-pulse mt-1' />
        </button>
      )
    } else {
      const { paymentAmount, paymentDate, paymentNumber, paymentDocument } = this.state
      const paymentValid = paymentAmount && paymentDate && paymentNumber && paymentDocument

      return(
        <button className='btn video-room-sidebar-btn d-block w-100 mb-2'
                onClick={this.createPayment.bind(this)}
                disabled={this.isDisabled() || !paymentValid}>
          <i className='fa fa-check mr-2' />
          {t('components.project.sidebar_steps.payment_block.create_payment')}
        </button>
      )
    }
  }

  renderPayments() {
    const { isNotary, subscription, projectItem, currentUserId } = this.props

    if (!isNotary && this.state.payments.length === 0) {
      return(
        <div className='video-room-sidebar-item-block'>
          {t('components.project.sidebar_steps.payment_block.no_payments')}
        </div>
      )
    }

    return this.state.payments.map(payment => {
      if (!isNotary && payment.userId != currentUserId) return

      return <Payment key={payment.id}
                      payment={payment}
                      isNotary={isNotary}
                      projectItemId={projectItem.id}
                      disabled={this.isDisabled()}
                      subscription={subscription}
                      isPaymentBlockActive={this.isBlockActive()} />
    })
  }

  renderCustomerBlockDescription() {
    const { currentUserId } = this.props

    if (this.props.isNotary || this.state.payments.length === 0) return
    const translation = this.state.payments.some(p => p.userId == currentUserId)
      ? 'payment_required'
      : 'no_payments'

    return(
      <div className='video-room-sidebar-payment-item border-0'>
        {t(`components.project.sidebar_steps.payment_block.${translation}`)}
      </div>
    )
  }

  render() {
    const { collapseOpened } = this.state

    return(
      <div className="video-room-sidebar-item-holder">
        <div className="video-room-sidebar-item mb-3">
          <a data-toggle="collapse" href="#collapse3" className={`video-room-sidebar-item-toggle ${collapseOpened ? '' : 'collapsed'}`}>
            <span>{t('components.video_room.payment')}</span>
            <i className='fa fa-chevron-right ml-auto' />
          </a>

          <div className={`video-room-sidebar-item-content video-room-sidebar-payments-block collapse ${collapseOpened ? 'show' : ''}`} id="collapse3">
            {this.renderCustomerBlockDescription()}
            {this.renderPayments()}
            {this.renderNewPayment()}
          </div>
        </div>

        {this.renderBlockStatusIcon()}
      </div>
    )
  }
}

export default PaymentBlock
