import React, { Component } from 'react'
import FileInput from './FileInput'
import uniqueId from 'lodash/uniqueId'
import map from 'lodash/map'
import { t } from 'i18n'

class FileUploaderComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filesArray: this.adjustedFileObjects()
    }
    this.addFile = this.addFile.bind(this)
  }

  adjustedFileObjects() {
    this.props.files.forEach( (f) =>{
      f.destroyed = false
      f.uniqueId = uniqueId()
    })
    return this.props.files
  }

  addFile(e) {
    e.preventDefault()
    const newFile = {
      name: null,
      file: null,
      uniqueId: uniqueId(),
      destroyed: false
    }

    let filesArray = this.state.filesArray
    filesArray.push(newFile)
    this.setState({ filesArray })
  }

  render() {
    const { filesArray } = this.state
    const acceptString = map(this.props.accept, ext => `.${ext}`).join(',')

    const fileInputs = filesArray.map(f => {
      return <FileInput file={f} namePrefix={this.props.namePrefix} acceptString={acceptString} key={f.uniqueId} />
    })

    return (
      <div className='file-uploads-container'>
        {fileInputs}
        <a href='#' className='btn btn-primary btn-sm' onClick={this.addFile}>
          <i className="fa fa-plus mr-1"></i>
          {t('components.file_uploader_container.add_new')}
        </a>
      </div>
    )
  }
}

export default FileUploaderComponent
