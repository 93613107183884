import React, { Component } from 'react'
import concat from 'lodash/concat'
import findIndex from 'lodash/findIndex'
import map from 'lodash/map'
import AsapAppointmentNotification from './AsapAppointmentNotification'
import VideoCallNotification from './VideoCallNotification'
import cableConsumer from '../../files/cableConsumer'

class Notifications extends Component {
  constructor(props) {
    super(props)

    this.state = {
      notifications: [],
      focusedNotification: null
    }

    this.subscription = cableConsumer.subscriptions.create({
      channel: 'NotificationsChannel'
    }, {
      received: this.handleChannelReceive
    })

    this.notificationSound = new Audio('/sounds/appointed.ogg')
  }

  maybePlayNotificationSound = (notificationData) => {
    if (Array.isArray(notificationData)) return
    if (!notificationData.show) return

    this.notificationSound.play().catch(() => {
      // Catch error when sound can't be played because user has not interacted with page
    })
  }

  handleChannelReceive = (notificationData) => {
    this.maybePlayNotificationSound(notificationData)

    const newNotifications = Array.isArray(notificationData) ? notificationData : [notificationData]

    const joinCallNotifications = newNotifications.filter(notification => notification.type === 'VIDEO_CALL_STATUS')
    if(joinCallNotifications.length > 0) {
      const lastestJoinCallNotification = joinCallNotifications[joinCallNotifications.length - 1]
      this.setState({focusedNotification: { type: lastestJoinCallNotification.type, notifiable_id: lastestJoinCallNotification.notifiable_id }})
    }

    let existingNotifications = [...this.state.notifications]

    map(newNotifications, newNotif => {
      // Remove existing notifications which are being replaced / updated
      const prevNotificationIdx = findIndex(existingNotifications, prevNotif =>
        prevNotif.type === newNotif.type && prevNotif.notifiable_id === newNotif.notifiable_id
      )

      if (prevNotificationIdx >= 0) {
        existingNotifications.splice(prevNotificationIdx, 1)
      }
    })

    this.setState({ notifications: concat(existingNotifications, newNotifications) })
  }

  render() {
    return map(this.state.notifications, ({ type, show, ...notification }) => {
      if (!show) return null

      const notificationFocused = () => {
        const focusedNotification = this.state.focusedNotification
        if (focusedNotification === null) return false

        return type === focusedNotification.type && notification.notifiable_id === focusedNotification.notifiable_id
      }

      const notificationProps = {
        key: `${notification.type}_${notification.notifiable_id}`,
        isFocused: notificationFocused(),
        ...notification
      }

      switch (type) {
        case 'VIDEO_CALL_STATUS': return <VideoCallNotification {...notificationProps} minimizeNotification={() => this.setState({ focusedNotification: null })} />
        case 'ASAP_APPOINTMENT': return <AsapAppointmentNotification {...notificationProps} />
        default: return null
      }
    })
  }
}

export default Notifications
