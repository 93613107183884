import React, { Component } from 'react'
import { t } from 'i18n'
import { uniqueId } from 'lodash'

class FooterItemInput extends Component {
  constructor(props) {
    super(props)
    let item = props.item
    // Select page object for existing objects
    if(item.page_id) {
      item.selectedPage = props.staticPages.find( (p) => p.id == item.page_id)
    }

    this.state = {
      item: item,
      formId: uniqueId(),
      destroyed: false
    }
    this.changeSelect = this.changeSelect.bind(this)
    this.removeRow = this.removeRow.bind(this)
    this.setStaticPage = this.setStaticPage.bind(this)
  }

  inputName(name) {
    return `cms_category[footer_items_attributes][${this.state.formId}][${name}]`
  }

  inputFor(name) {
    return `cms_category_footer_items_attributes_${this.state.formId}_${name}`
  }

  changeSelect() {
    let { item } = this.state
    item.for_static_page = !item.for_static_page

    if(item.for_static_page) {
      item.selectedPage = this.props.staticPages[0]
    } else {
      item.selectedPage = null
    }
    this.setState({ item })
  }

  setStaticPage({ target: { value }}) {
    const pageId = parseInt(value)

    this.setState(({ item  }) => {
      return { item: { ...item, selectedPage: this.props.staticPages.find((p) => p.id === pageId) }}
    })
  }

  removeRow() {
    this.setState({ destroyed: true })
  }
  renderPageSelect() {
    const { item, destroyed } = this.state

    if(item.for_static_page) {
      return(
        <div>
          <label className='col-form-label' htmlFor={this.inputFor('page_id')}>
            {t('activerecord.attributes.cms/footer_item.url')}
          </label>
          <select className='form-control' name={this.inputName('page_id')} defaultValue={item.selectedPage.id} onChange={this.setStaticPage}>
            {this.props.staticPages.map((p) => <option value={p.id} key={p.id}>{p.title}</option> )}
          </select>
        </div>
      )
    } else {
      const dynamicOptions = this.props.dynamicSelect.map((o) => (<option value={o[1]} key={o[1]}>{o[0]}</option> ))
      // Add hidden input to nullify page id if dynamic page is selected
      return (
        <div>
          <label className='col-form-label' htmlFor={this.inputFor('url')}>{t('activerecord.attributes.cms/footer_item.url')}</label>
          <select className='form-control' name={this.inputName('url')} defaultValue={item.url}>
            {dynamicOptions}
          </select>
          <input type='hidden' name={this.inputName('page_id')} value='' />
        </div>
      )
    }
  }

  renderLanguageMismatchError() {
    const { selectedPage } = this.state.item
    if(selectedPage && selectedPage.language != this.props.categoryLanguage) {
      return (
        <div className="col">
          <div className="alert alert-danger" role="alert">
            {t('components.footer_item_input.language_mismatch')}
          </div>
        </div>
      )
    }
  }

  render() {
    const { item, destroyed } = this.state

    const styles = {
      display: destroyed ? 'none' : 'block'
    }
    let idField
    if(item.id) {
      idField = <input type='hidden' value={`${item.id}`} name={this.inputName('id')} />
    }
    return(
      <div className='footer-item form-row' style={styles}>
        { this.renderLanguageMismatchError() }
        <input type='hidden' value={`${destroyed}`} name={this.inputName('_destroy')} />
        {idField}
        <div className='form-group col'>
          <label htmlFor={this.inputFor('title')} className='col-form-label'>{t('activerecord.attributes.cms/footer_item.title')}</label>
          <input type='text' className='form-control' name={this.inputName('title')} id={this.inputFor('title')} defaultValue={this.state.item.title}/>
        </div>
        <div className='form-group col'>
          <div className="form-check">
            <label htmlFor={this.inputFor('for_static_page')} className='form-check-label'>
              <input name={this.inputFor('for_static_page')} type='checkbox' checked={item.for_static_page} onChange={this.changeSelect} className='form-check-input' />
              {t('activerecord.attributes.cms/footer_item.for_static_page')}
            </label>
          </div>
        </div>
        <div className='form-group col' className='page-select'>
          {this.renderPageSelect()}
        </div>

        <a href='#' onClick={() => this.removeRow()}>{t('delete')}</a>
      </div>
    )
  }
}

export default FooterItemInput
