import React, { Component } from 'react'
import { t } from 'i18n'

class PaymentProcessingStep extends Component {
  constructor(props) {
    super(props)
    this.state = {
      warrant_check: props.warrant_check
    }
  }

  renderSpinner() {
    return (
      <span className='loading-indicator large'></span>
    )
  }

  renderSearchFields() {
    const { warrant_check } = this.state

    return (
      <div className='warrant-info-container bg-light mt-4'>
        {warrant_check.search_criteria.map((field, index) =>
          <span key={index} className='info-field'>{field}</span>
        )}
      </div>
    )
  }

  render() {
    return(
      <div className='processing-warrant col-sm-8 m-auto py-5 text-center'>
        {this.renderSpinner()}
        <h4 className='text-center'>{t('components.warrant_check_container.processing_payment')}</h4>
        <p className='text-center small white-space-pre'>
          {t('components.warrant_check_container.processing_payment_info')}
        </p>
        {this.renderSearchFields()}
      </div>
    )
  }
}

export default PaymentProcessingStep
