
import { getMetaValue } from 'files/helpers'
import { init } from '@sentry/browser'

const environment = getMetaValue('environment')
const sentryEnvironments = ['demo', 'production']

if (sentryEnvironments.includes(environment)) {
  init({
    environment: environment,
    dsn: getMetaValue('sentry-dsn'),
    environments: ['demo', 'production']
  });
}
